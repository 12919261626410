.summary {
	background:rgba(#ececec, .48);
	border:2px solid #ececec;
	border-radius:4px;
	padding:12px 0;

	.purple-light { color:$purple-light; }

	&__car {
		margin-bottom:0 !important;
		padding:0 12px 10px;
		text-align:left;

		@include media-breakpoint-up(sm) { 
			> * {
				display:inline-block;
				vertical-align:top;
				width:100%;
			}
		}
	}

	&__car + div {
		/*border-top:2px solid #ececec;
		margin-top:0;
		padding-top:20px;
		*/
		/*@include media-breakpoint-up(sm) { 
			padding-left:30px;
			padding-right:30px;
		}

		@include media-breakpoint-up(md) { 
			margin-top:20px;
			padding-left:15px;
			padding-right:15px;
		}*/
	}

	&__car__figure {
		border-radius:4px;
		overflow:hidden;
		width:100%;

		@include media-breakpoint-up(md) { 
			width:140px;
		}
	}

	&__car__link--full-link {
		display:block;
		height:auto;
		width:100%;
	}

	&__car__img {
		display:block;
		height:auto;
		margin:0 auto;
		width:100%;
	}

	&__car__info {
		margin-top:15px;
		text-align:left;
		width: 100%;

		@include media-breakpoint-up(sm) { 
			padding-left:10px;
			padding-right:10px;
		}

		@include media-breakpoint-up(md) { 
			margin-top:0;
			padding-left:10px;
			padding-right:0;
			width:calc(100% - 145px);
		}

		a {
			@include vw-font-size(14px);
			display:block;
			margin-top:5px;
		}
	}

	&__info__title {
		color:$purple;
		display:block;
		font-family:$font-open-sans;
		font-weight:bold;
		letter-spacing:-.24px;
		margin-bottom:5px;

		@include media-breakpoint-up(lg) { 
			@include vw-font-size(18px);
			@include vw-line-height(25px);
		}

		@include media-breakpoint-up(xl) { 
			@include vw-font-size(22px);
			@include vw-line-height(30px);
		}
	}

	&__info__desc {
		@include vw-font-size(15px);
	}

	.form-label-text {
		@include vw-font-size(14px);
		text-align:left;
		vertical-align:top;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(15px);
		}
	}

	&__info__price {
		@include vw-font-size(14px);
		@include vw-line-height(22px);
		align-items:flex-end;
		display:flex;
		flex-wrap:nowrap;
		font-family:$font-open-sans;
		font-weight:bold;
		justify-content:space-between;
		margin-top:5px;
		margin-bottom:15px;
		//text-align:right;
		width:100%;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(15px);
			@include vw-line-height(27px);
			margin-bottom:10px;
			margin-top:0;
		}

		b {
			@include vw-font-size(18px);
			font-weight:700;
			margin-left:5px;
			//margin-right:3px;

			@include media-breakpoint-up(lg) { 
				@include vw-font-size(24px);
			}
		}

		span {
			@include vw-font-size(14px);

			@include media-breakpoint-up(md) { 
				@include vw-font-size(15px);
			}
		}
	}

	&__price {
		background:#ececfd;
		display:flex;
		flex-wrap:nowrap;
		justify-content: space-between;
		overflow:hidden;
		text-align:left;

		@include media-breakpoint-up(sm) { 
			padding-left:5px;
		}

		@include media-breakpoint-up(md) { 
			padding-left:0;
		}
	}

	&__price__block {
		font-family:$font-open-sans;
		font-weight:bold;
		padding:12px 15px;
	}

	&__price__block--price-text {
		align-items:center;
		display:flex;
		flex:1;
		padding-left:15px;

		@include media-breakpoint-up(sm) {
//			padding-left:30px;
		}

		@include media-breakpoint-up(lg) {
			padding-left:15px;
		}
	}

	&__price__block--price {
		@include vw-font-size(16px);
		background:$purple-light;
		color:$white;
		font-family:$font-open-sans;
		font-weight:500;
		padding-left:0;
		padding:12px 12px 12px 25px;
		position:relative;
		text-align:center;

		@include media-breakpoint-up(sm) { 
			padding-right:20px;
		}

		@include media-breakpoint-up(md) { 
			@include vw-font-size(20px);
			padding:12px 32px 12px 35px;
		}	

		&:after {
			border-top:10px solid transparent;
			border-bottom:10px solid transparent;
			border-left:10px solid #ececfd;
	        content:'';
	        display:inline-block;
			height: 0; 
	        left:0;
			position:absolute;
			top:50%;
			transform:translate(0,-50%);
			width:0;
		}

		> * {
			display:inline-block;
		    position:relative;
		    top:50%;
		    transform:translate(0,-50%);
		    vertical-align:middle;

		    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		    	top:0%;
		    	transform:translate(0,0%);
		    }
		}

		b {
			@include vw-font-size(24px);
			margin-right:3px;

			@include media-breakpoint-up(md) { 
				@include vw-font-size(28px);
			}	
		}

	}

	&__info {
		margin-top:15px;
		padding:12px;

		.text-small {
			@include vw-font-size(14px);
			margin:0 auto;
			max-width:255px;

			&.full-width {
				margin-bottom:20px;
				max-width:360px;
			}

			@include media-breakpoint-up(md) { 
				@include vw-font-size(15px);
			}
		}
	}

	&__info--full-width {
		padding-bottom:0;
		padding-left:20px;
		padding-top:0;

		@include media-breakpoint-up(sm) {
			//padding-left:30px;
		}

		@include media-breakpoint-up(lg) {
			padding-left:15px;
		}
		
		.text-small {
			display:inline-block;
			font-weight:600;
			max-width:100%;
			position:relative;
			text-align:left;
			width:100%;

			[data-tooltip] {
				position:relative;
				right:auto;
				top:auto;
			}
		}
	}

	&__info--full-width--m-bottom {
		margin-bottom:7px;
		margin-top:0;
	}

	&__info__ico {
		margin-bottom:5px;

		@include media-breakpoint-up(md) { 
			margin-bottom:15px;
		}

		> * {
			display:inline-block;
			vertical-align:middle;
		}

		img {
			height:auto;
			margin-right:5px;
			width:19px;
		}

		.text {
			@include vw-font-size(15px);
			display:inline-block;
			max-width:calc(100% - 30px);
			vertical-align:middle;

			@include media-breakpoint-up(md) { 
				@include vw-font-size(16px);
			}
		}
	}
	
	&__free-warranty {
		//margin-top:10px;

		.text {
			@include vw-font-size(14px);
			font-weight:600;
		}

		.summary__tasacion__discount {

			strong {
				font-weight:bold;
			}

			&.old-price {
				@include vw-font-size(13px);
				font-weight:normal;

				strong {
					font-weight:normal;
					position:relative;

					&:before {
						background: #f23a49;
					    content: '';
					    display: block;
					    left: 0;
					    height: 2px;
					    width: calc(100% + 8px);
					    position: absolute;
					    top:9px;
					    transform: rotate(-16deg);
					    z-index: 1;

					    @include media-breakpoint-up(xl) { 
					    	top: 8px;
					    }
					}
				}
			}
		}
	}

	&__booking {
		.summary__tasacion__discount {
			font-weight:bold;
		}
	}
	
	&__tasacion, &__warranty, &__financing {
		.text {
			@include vw-font-size(14px);
			font-weight:600;
		}
	}

	&__tasacion, &__warranty, &__financing, &__free-warranty, &__booking {
		display:flex;
		flex-wrap:nowrap;
		//margin-top:10px;
		padding:20px 40px 20px 15px;
		text-align:left;

		@include media-breakpoint-up(sm) { 
			padding:20px 40px 20px 30px;
		}

		@include media-breakpoint-up(lg) { 
			padding:20px 15px;
		}

		> * {
			//flex:1 1 0px;
			flex-grow:1;
		}

		&__text {
			padding-right:10px;
		}

		&__discount {
			@include vw-font-size(16px);
			font-family:$font-open-sans;
			font-weight:700;
			text-align:right;

			.discount {
				font-weight:700;
				margin-right:5px;
			}
		}
	}

	&__financing {
		flex-wrap:wrap;

		.text-small {
			//margin-top:10px;
			//width:100%;
		}

		.text-small--margin {
			margin-top:10px;
		}
	}

	&__tasacion, &__warranty, &__financing, &__booking { padding-top:0; }

	.border-bottom { border-bottom:2px solid #ececec; }

	.summary__tasacion:not(.hide),
	.summary__tasacion:not(.hide) + .summary__warranty:not(.hide),
	.summary__warranty:not(.hide) + .summary__financing:not(.hide),
	.summary__tasacion:not(.hide) + .summary__financing:not(.hide),
	.summary__warranty:not(.hide) + .summary__financing:not(.hide),
	.summary__tasacion:not(.hide) + .summary__warranty.hide + .summary__financing:not(.hide) {
		margin-top:0;
		padding-top:0;
	}

	.summary__free-warranty--full-width {
    	.summary__tasacion__text {
    		max-width: 100%;
    		width: 100%;
    	}
	}

	.summary__free-warranty--no-margin {
		margin-top: 0;
	    padding-top: 0;
	    border-top: 0;
	}


	.summary__free-warranty--border-bottom {
		border-top:2px solid #ececec;
		margin-top:20px;
	}

	&__tasacion__text, &__financing__text {
		max-width:250px;
	}

	&__tasacion__price {

	}

	&__financing {
		align-items:bottom;
	}

	&__financing__discount {
		/*@include vw-font-size(17px);
		@include vw-line-height(16px);*/
		position:relative;

		/*@include media-breakpoint-up(lg) {
			@include vw-font-size(18px);
			@include vw-line-height(22px);
		}*/

		.financing_quote {
			font-weight:bold;

			/*@include vw-font-size(20px);
			font-weight:700;

			@include media-breakpoint-up(lg) {
				@include vw-font-size(25px);
			}*/
		}
	}

	[data-tooltip] {
		position:absolute;
		right:-30px;
		top:-7px;
		vertical-align:top;
	}
}

.summary--big {
	float:right;
	//margin-top:12px;
	padding:12px 0;
	width:100%;

	@include media-breakpoint-up(md) { 
		border:0;
		padding:20px;
	}

	.summary__car__figure {
		border-radius:8px;
		padding:0 12px;
		width:100%;

		@include media-breakpoint-up(md) { 
			display:block;
			float:left;
			padding:0;
			width:250px;
		}

		@include media-breakpoint-up(xl) { 
			width:424px;
		}
	}

	.summary__car {
		padding:0;

		@include media-breakpoint-up(md) { 
			display:block;
			float:left;
			padding:0 60px 0 20px;
			width:calc(100% - 250px);
		}

		@include media-breakpoint-up(xl) { 
			padding-right:100px;
			width:calc(100% - 424px);
		}
	}

	.summary__car__info {
		padding:0 22px;
		width:100%;

		@include media-breakpoint-up(md) { 
			padding-left:0;
			padding-right:12px;
		}
	}

	.summary__car .summary-status {
		//border-top:2px solid #ececec;
		&.summary__free-warranty { border-top:2px solid #ececec; }

		&.summary__tasacion:not(.hide) + .summary__warranty:not(.hide),
		&.summary__warranty:not(.hide) + .summary__financing:not(.hide),
		&.summary__tasacion:not(.hide) + .summary__financing:not(.hide),
		&.summary__warranty:not(.hide) + .summary__financing:not(.hide),
		&.summary__tasacion:not(.hide) + .summary__warranty.hide + .summary__financing:not(.hide) {
			border-top:0;	
		}
	}

	.summary__padding {
		@include media-breakpoint-up(md) { 
			padding-left:30px;
			padding-right:50px;
			width:100%;
		}

		@include media-breakpoint-up(xl) { 
			padding-left:40px;
			padding-right:80px;
		}

		.summary__car__info {
			margin-top:0;

			@include media-breakpoint-up(md) { 
				margin-top:15px;
			}
		}
	}

	//.summary__tasacion, .summary__warranty { margin-top:20px; }

	.summary__info {
		text-align:center;
		width:100%;

		@include media-breakpoint-up(md) { 
			text-align:left;

			.text-small { max-width:100%; }
		}
	}

	.summary__price {
		//padding-left:25px !important;
		padding-right:0 !important;

		@include media-breakpoint-up(md) { 
			margin-top:10px;
			padding-left:0 !important;
		}
		@include media-breakpoint-up(xl) { 
			padding-left:30px;
		}
	}

	.summary__info__price { display:none; }
}


.summary--full {
	float:none;
	width:100%;

	@include media-breakpoint-up(md) {
		padding-left:0;
		padding-right:0;
	}

	.summary__car, .summary__info--full-width { 
		width:100%;

		+ div { border-top:0; }

		@include media-breakpoint-up(md) { 
			padding-left:20px;
			padding-right:20px;
		}

		@include media-breakpoint-up(xl) { 
			padding-right:80px;
		}
	}

	.summary__info .text-small, .summary__car__info {
		max-width:100%;
		padding-right:0;
		width:100%;
	}

	.summary__car .summary__info { padding:0; }

	.summary__price { 
		clear:both;
		padding-left:0;
		padding-top:0;

		@include media-breakpoint-up(md) { 
			padding-left:20px;
		}

		@include media-breakpoint-up(xl) { 
			padding-left:20px;
		}

		.summary__price__block--price {
			@include media-breakpoint-up(md) { 
				padding-right:20px;
			}

			@include media-breakpoint-up(xl) { 
				padding-right:80px;
			}
		}

		.summary__price__block--price-text {
			padding-left:20px;
		}
	}

	[data-tooltip] {
		@include media-breakpoint-up(md) { 
			right:-25px;
		}
	}

	.btn-help-agroup {
		clear:both;			
		width:100%;

		@include media-breakpoint-up(md) {
			float:left;
			margin:30px auto 15px;
		}
	}
}

.section--summary .call-us {
	align-items:center;
	background:$purple;
	border-radius:4px;
	color:$white;
	display:flex;
	flex-wrap:wrap;
	margin-bottom:15px;
	padding:9px 18px;

	&__text {
		@include vw-font-size(14px);
		@include vw-line-height(19px);
		padding-right:10px;

		@include media-breakpoint-up(md) {
			@include vw-font-size(15px);
		}
	}

	&__phone {
		@include vw-font-size(18px);
		color:$white;
		font-weight:500;

		@include media-breakpoint-up(md) {
			@include vw-font-size(24px);
		}
	}
}