$input-height-xs:40px;
$input-height:48px;
$input-border-color:#f7f7f7;
$input-bg-color:#f7f7f7;
$input-border-focus:#f7f7f7;
$input-color-text:$white;

.select2:focus,
.select2-container:focus,
.select2 .selection:focus,
.select2-container .select2-selection:focus { outline:none; }


.select2-container {
  background:$input-bg-color;
	border:2px solid $input-border-color;
  box-sizing: border-box;
  display: inline-block;
  font-family:$font-quicksand;
  margin: 0;
  position: relative;
  vertical-align: middle;

    .select2-selection--single {
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        height: $input-height-xs;
        line-height: $input-height-xs;

        @include media-breakpoint-up(md) {
          height: $input-height;
          line-height: $input-height;
        }

        .select2-selection__rendered {
          display: block;
          padding-left: 5px;
          padding-right: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; 
        }

        .select2-selection__clear {
          position: relative; 
        }
    }
}


.select2-dropdown {
  background-color: $input-bg-color;
  border: 1px solid $input-border-color;
  border-radius:0 0 8px 8px;
  box-sizing: border-box;
  display: block;
  padding:10px 0;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; 

  border-top:1px solid #fff !important;
}

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding:0;
  max-height:210px;
  overflow-y:auto;
}

.select2-results__option {
  padding: 6px 15px;
  user-select: none;
  -webkit-user-select: none; 

  &:hover {
    background:$purple;
  }
}
.select2-results__option[aria-selected] {
    cursor: pointer; 
}
.select2-container.select2-container--open {
  border: 2px solid $input-border-focus;
  border-radius:8px 8px 0 0;

  .select2-selection__arrow b {
    margin-top:0;
    transform: translate(0, -50%) rotate(-45deg) skew(5deg, 5deg);
  }
}
.select2-container--open .select2-dropdown {
  left: 0; 
  z-index:10000;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: none;
  padding: 4px; }
  .select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box; }
    .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none; }
  .select2-search--dropdown.select2-search--hide {
    display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.select2-container--default .select2-selection--single {
  background-color: $input-bg-color;
  border: 1px solid $input-border-color;
  transition:$transition-base;
  //border-radius: 4px; 


}
  .select2-container--default .select2-selection--single .select2-selection__rendered {

    line-height: $input-height-xs;
    padding-right: 30px;
    text-align: left; 

    @include media-breakpoint-up(md) {
      line-height: $input-height;
    }
  }


  .select2-container--focus.select2-container--default .select2-selection--single {
      border:1px solid $input-border-focus;

  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: $input-height-xs - 1px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 30px; 
    border-left:1px solid $input-border-color;

    @include media-breakpoint-up(md) {
      height: $input-height - 1px;
    }

  }
    .select2-container .select2-selection__arrow b {
      border-top: 2px solid $black;
      border-right: 2px solid $black;
      content: '';
      display: inline-block;
      height: 10px;
      margin-top: -5px;
      position: absolute;
      right: 20px;
      top: 50%;
      -webkit-transform: rotate(135deg) skew(5deg, 5deg);
      transform: rotate(135deg) skew(5deg, 5deg);
      -webkit-transition: border-color .2s ease-in-out;
      transition: border-color .2s ease-in-out;
      vertical-align: middle;
      width: 10px;
    }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }
  .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }



.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }



.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
 // -webkit-appearance: textfield; 
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: $gray; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $gray;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--default-big {
  @include vw-font-size(16px);
  @include vw-line-height(26px);
  background:$purple-light;
  border-radius:8px;
  color:$white;
  letter-spacing:0.6px;
  padding:0 15px;
  
  @include media-breakpoint-up(md) { 
    @include vw-font-size(16px);
    @include vw-line-height(27px);
  }

  .select2-selection__arrow b {
    border-top: 2px solid $white;
    border-right: 2px solid $white;
  }
}

.select2-container--classic {
  .select2-dropdown {
    background:$white;
    border:1px solid $input-border-color;
    margin-left:-2px;
    margin-top:-15px;
  }

  .select2-results__option {
    font-family:$font-open-sans;
    font-size:14px;
    
    &:hover {
      background:rgba($purple-light, .2);
    }
  }
}

.select2-results__option[aria-selected=true] {
   background:rgba($purple-light, .2);
}

.select2-search {
    .select2-search__field {
      border: 1px solid $gray-light;
      padding:6px 11px;
    }
}