.legal-table {
	margin:20px auto;
	text-align:left;
	width:100%;


	&__item {
		border-top:1px solid #d1d1d1;
		display:flex;
		flex-wrap:nowrap;
		padding:7px 0;

		@include media-breakpoint-up(md) { 
			padding:10px 0;
		}

		&:first-child {
			border:0;
			align-items:flex-end;

			.legal-table__block { font-weight:bold; }
		}
	}

	&__block {
		@include vw-font-size(13px);
		@include vw-line-height(18px);
		flex:1;
		padding-right:10px;
		text-align:left;
    	max-width: 25%;
    	word-wrap: break-word;

		&:before {
			color:#d1d1d1;
		}
	
		b {
			display:block;
			font-weight:600;
		}
	}
}