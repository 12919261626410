// ==========================================================================
// Highlight Block
// ==========================================================================

// 1.Config


// 2.Base
.highlight-block {
	@extend %clearfix;

	&__content {
		background:$purple-light;
		padding:20px 15px;
		overflow:hidden;
		text-align:center;
		position:relative;

		@include media-breakpoint-up(md) { 
			height: 510px;
			float:left;
			padding:0 40px;
			text-align:left;
			width: 52%;
		}

		@include media-breakpoint-up(xl) { 
			padding:0 60px;
		}

		p { margin-bottom:0; }
		p + p {
			margin-top:15px;
		}
	}

	&__caption {
		margin:0 auto;
		max-width: 610px;

		@include media-breakpoint-up(md) {
			margin:0;
			padding-bottom:60px;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__title {
		@include media-breakpoint-up(lg) { 
			margin-bottom:25px;
		}
	}	

	&__description {
		img { max-width:100%; }
	}

	&__image {
		margin:0 auto;
		overflow:hidden;
		position: relative;
		width: 100%;

		@include media-breakpoint-up(md) { 
			float:left;
			height: 510px;
			width: 48%;
		}
		
		img {
			display:block;
			height:auto;
			transition:$transition-base;
			width:100%;

			@include media-breakpoint-up(md) { 
				height:100%;
				left:0;
				object-fit:cover;
				position:absolute;
				top:0;
				width:100%; 
			}
	    }
	}

	&__cta {
		margin:20px auto 15px;

		@include media-breakpoint-up(md) {
			bottom:40px;
			margin:0;
			right:40px;
			position:absolute;
		}

		@include media-breakpoint-up(xl) { 
			bottom:60px;
			right:60px;
		}
	}

	&__img--full-width {
		display:block;
		width:100%;
	}
}