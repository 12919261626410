// ==========================================================================
// Product Accordeon
// ==========================================================================

// ==========================================================================
// Faqs
// ==========================================================================

// 1.Config


$productAccordeonTitleColor:     $black;
$productAccordeonTitleIco:       $black;
$productAccordeonTitleIcoHover:  $purple-light;
$productAccordeonTitleActiveColor:     $purple-light;
$productAccordeonTitleActiveIco:       $purple-light;
$productAccordeonTitleActiveIcoHover:  $purple-light;

// 2.Base

.list-accordeon {
    margin:20px auto;
    max-width:690px;
    text-align:left;

    @include media-breakpoint-up(md) { 
    	margin-top:35px;
    }

    &__block {
       margin-bottom:5px;
    }

    &__block--active {
        .list-accordeon__content {
            display:block;
        }
    }

    &__title {        
    	@include vw-font-size(18px);
		@include vw-line-height(24px);
        color:$black;
        display:block;
        font-family:$font-quicksand;
		font-weight:700;
        cursor:pointer;
        transition:$transition-base;
        position:relative;
        padding:10px 0;
        position:relative;
		
		@include media-breakpoint-up(md) { 
			@include vw-font-size(22px);
			@include vw-line-height(34px);
			//padding:20px 0;
		}

        &:before {
        	color:$purple-light;
        	display:inline-block;
        	margin-right:20px;
        	margin-top:5px;
        	vertical-align:top;
        }

        span {
        	display:inline-block;
        	width:calc(100% - 50px);
        	vertical-align:top;

        	@include media-breakpoint-up(md) { 
        		width:calc(100% - 60px);
        	}
        }

        &:hover {
            color:$purple-light;
            text-decoration:none;
        }

        &--active {            
        	color:$purple-light;
        }
    }

    &__content {
        display:none;
        padding:0px 15px 20px 42px;

        @include media-breakpoint-up(md) {
        	padding:0 50px 20px;
        }        
    }


    &__text {

        p {
            margin:0 0 20px;

            &:last-child {
                margin:0;
            }
        }
    }

}