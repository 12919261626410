.circle-list {
	margin:0 auto;
	padding:0 7px;
	text-align:left;

	&__item {
		display:block;
		margin:5px 0;
		width:100%;

		&:first-child { margin-top:0; }

		&:before {
			background:$purple-light;
			border-radius:50%;
			content:'';
			height:7px;
			display:inline-block;
			margin-top:10px;
			vertical-align:top;
			width:7px;
		}

		> * { 
			display:inline-block;
			vertical-align:middle;
		}
	}

	div {
		@include vw-font-size(14px);
		@include vw-line-height(21px);
		padding-left:10px;
		width:calc(100% - 15px;);
	}
}