.car-view {
	display:block;
	margin:20px auto;
	text-align:center;

	@include media-breakpoint-up(md) { 
		margin:20px auto 25px;
	}

	&__title {

	}

	&__img {
		clear:both;
		display:block;
		height:auto;
		margin:15px auto;
		max-height:100%;
		max-width:100%;
		width:auto;

		@include media-breakpoint-up(md) { 
			margin:35px auto;
		}
	}

	.btn {
		@include media-breakpoint-up(md) { 
            @include vw-font-size(18px);
        }
	}
}