/* Welcome to The RSB SASS Bloilerplate
 * BEM + SASS + SMACSS starter 
 *
 * This is a SASS starter structure, with the focus in use
 * the SMACSS archtecture and BEM class naming rules. 
 *
 *
 */


/* ---------------------------------
 * Config
  ----------------------------------*/

@import "utilities/config";
@import "framework/bootstrap";

 
/* ---------------------------------
 * Utils
 ----------------------------------*/
@import "utilities/utils";
@import "utilities/custom-placeholders";
@import "utilities/ie";

/* ---------------------------------
 * Components
 ----------------------------------*/
@import "components/buttons";
@import "components/fonts";
@import "components/font-awesome";
@import "components/hamburguers";
@import "components/forms";
@import "components/switch";
@import "components/section";
@import "components/section-columns";
@import "components/ico-list";
@import "components/highlight-block";
@import "components/img-square-list";
@import "components/car-list";
@import "components/car-list-horizontal";
@import "components/animate";
//@import "components/preloader";
@import "components/fancybox";
@import "components/fancybox-content";
@import "components/swiper";
@import "components/slide";
@import "components/testimonials-slide";
@import "components/rate-slide";
@import "components/share";
@import "components/cookies-bar";
@import "components/select2";
@import "components/rating";
@import "components/tagify";
@import "components/breadcrumb";
@import "components/confirmation";
@import "components/list-accordeon";
@import "components/horizontal-block-list";
@import "components/pagination";
@import "components/car-detail";
@import "components/guaranteed";
@import "components/selection";
@import "components/equipement";
@import "components/booking-bar";
@import "components/technical-details";
@import "components/item-large";
@import "components/result-box";
@import "components/booking";
@import "components/car-view";
@import "components/booking-list";
@import "components/datepicker";
@import "components/pricing";
@import "components/customscrollbar";
@import "components/nouislider";
@import "components/scroll-to-top";
@import "components/tooltip";
@import "components/summary";
@import "components/ico-booking-list-info";
@import "components/horizontal-info-block";
@import "components/testimonials-list";
@import "components/circle-list";
@import "components/dni-scan";
@import "components/legal-table";
@import "components/chat";
@import "components/big-call-us";
@import "components/maps-google";
@import "components/renting-banner";
@import "components/article-column";
@import "components/box-renting";
@import "components/numeric-book-steps-renting";
@import "components/renting-booking-summary";
@import "components/fileuploader-min";
@import "components/dropzone";
@import "components/dropzone-custom";
@import "components/block-steps-assessment";

/* ---------------------------------
 * Layout
 ----------------------------------*/
@import "layout/header";
@import "layout/header-purple";
@import "layout/navbar";
@import "layout/menu";
@import "layout/submenu";
@import "layout/bar-menu";
@import "layout/title";
@import "layout/title-medium";
@import "layout/title-small";
@import "layout/text";
@import "layout/text-small";
@import "layout/text-medium";
@import "layout/highlight";
@import "layout/highlight-medium";
//@import "layout/main";
@import "layout/footer";
//@import "layout/section";
@import "layout/top-header";
@import "layout/top-header-min";
@import "layout/contact-topbar";
@import "layout/discount-header-topbar";
@import "layout/chat-button";
@import "layout/advice";

@import "layout/filters";
@import "layout/search";
@import "layout/search-no-results";
@import "layout/book-steps";
@import "layout/numeric-book-steps";
@import "layout/payment-method";
@import "layout/error";
@import "layout/free-home-delivery";
@import "layout/renting-booking";
@import "layout/renting-help";
@import "layout/blog";
@import "layout/post";
@import "layout/comment";
@import "layout/post-navigation";

@import "layout/landing";
@import "layout/car-assessment";



/* ---------------------------------
 * Modules
 ----------------------------------*/



/* ---------------------------------
 * Pages
 ----------------------------------*/

.hide { display:none; }

.text-align-center {
	text-align:center;
}

.text-align-left {
	text-align:left;
}


a {
	color:$black;
	transition:$transition-base;
}

.small-container {
	margin:0 auto;
	max-width:850px;
	padding:0 15px;
	width:100%;
}

.xs-container {
	margin:0 auto;
	max-width:600px;
	padding:0 15px;
	width:100%;
}

.no-margin-bottom { margin-bottom:0; }
.no-margin-top { margin-top:0; }

button {
	&:focus {
		box-shadow:none;
		outline:none;
	}
}

body.overflow {
	overflow:hidden;
}


.border-bottom {
	border-bottom:2px solid $gray-light;
	margin-bottom:15px;
	padding-bottom:15px;
}