.ico-booking-list-info {

	&__initial {
		margin-bottom:15px;

		@include media-breakpoint-up(md) { 
			margin-bottom:40px;
		}
	}

	&__block {
		margin-bottom:20px;
		text-align:left;

		@include media-breakpoint-up(md) { 
			padding:0 35px;
		}

		
		img {
			display:inline-block;
			vertical-align:top;
			width:40px;

			@include media-breakpoint-up(md) { 
				width:65px;
			}
		}

	}

	&__block--garantia {
		img {
			width:52px;

			@include media-breakpoint-up(lg) { 
				width:104px;
			}
		}

		.ico-booking-list-info__text {
			width:calc(100% - 60px);

			@include media-breakpoint-up(lg) { 
				width:calc(100% - 110px);
			}
		}
	}

	&__block--protege {
		img {
			padding-left:7px;
			width:52px;

			@include media-breakpoint-up(lg) { 
				padding-left:14px;
				width:104px;
			}
		}

		.ico-booking-list-info__text {
			width:calc(100% - 60px);

			@include media-breakpoint-up(lg) { 
				width:calc(100% - 110px);
			}
		}
	}

	&__block--middle {
		> * {
			vertical-align:middle !important;
		}
	}

	&__text {
		display:inline-block;
		padding-left:10px;
		vertical-align:top;
		width:calc(100% - 50px);

		@include media-breakpoint-up(md) {
			padding-left:20px;
			width:calc(100% - 75px);
		}

		strong {
			color:$purple;
			display:block;
			font-family:$font-quicksand;
		}

		p {

		}

	}
}