.chat-button {
	background:url(#{$url-statics}/svg/button-help-mini.svg) center no-repeat transparent;
	background-size:contain;
	display:block;
	border-radius:50%;
	height:60px;
	width:60px;

	@include media-breakpoint-up(lg) { 
		height:100px;
		width:100px;

		&:hover {
			background:url(#{$url-statics}/svg/button-help-mini-hover.svg) center no-repeat transparent;
		}
	}
}