// ==========================================================================
// Navbar
// ==========================================================================

// 1.Config


// 2.Base
.navbar {
    background:$purple;
    bottom:0;
    display:none;
    overflow-y:auto;
    padding:10px 0 0 0;
    position:fixed;
    //top:$headerHeight;
    top:90px;
    width:100%;
    z-index: z('navbar');

    @include media-breakpoint-up(md) {
        background:none;
        //bottom:auto;
        display:block !important;
        height:$headerHeightSm;
        overflow-y:visible;
        padding:0;
        position:absolute;
        right:0;
        top:auto;
        //top:30px;
    }

    .share, .share-list {
        display:block;
        padding:10px 0 20px;
        text-align:center;

        @include media-breakpoint-up(md) {
            display:none;
        }
    }

    &__v-align {
        @media screen and (orientation:portrait) and (max-width:991px) {
            position:relative;
            top:50%;
            transform:translate(0,-50%);
        }

        @media (min-height:700px) and (max-width:991px){
            position:relative;
            top:50%;
            transform:translate(0,-50%);
        }
        
    }
}

.navbar--scroll {
    @include media-breakpoint-up(md) { 
        background:rgba($white, .95);
    }

    @include media-breakpoint-up(md) {
        .menu__link {
            color:$indigo;

            &:after {
                border-color:$indigo !important;
            }

            &:hover {
                color:$purple-light !important;
                &:after { border-color:$purple-light !important; }
            }

            &:focus {
                color:$purple-light;
            }
        }

        .show-submenu .menu__link:after { border-color:$white !important; }

        .submenu__link {
            color:$white;

            &:focus {
                color:$white;
            }
        }
    }
}

.header--has-topbar {
    .navbar {
        top:115px;

        @include media-breakpoint-up(md) {
            top:auto;
        }
    }
}