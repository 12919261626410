// ==========================================================================
// Car list
// ==========================================================================


// 1.Config


// 2.Base
.booking-list {
	display: flex;
	margin-top:27px;
	flex-wrap:wrap;
	justify-content:center;
	text-align:center;

	@include media-breakpoint-up(sm) {
		margin-left:-10px;
		margin-right:-10px;
		justify-content:flex-start;
	}

	@include media-breakpoint-up(md) {
		margin-top:40px;
	}

	&__item {
		border:1px solid #eee;
		margin-bottom:20px;
		position:relative;
		vertical-align:top;
		width:100%;

		@include media-breakpoint-up(sm) {
			margin:0 1% 2%;
			width:48%;
			padding-bottom:50px;
		}

		@include media-breakpoint-up(lg) {
			width:31.3%;
		}
		
		@include media-breakpoint-up(xl) {
			width:23%;
		}
	}

	&__box {
		text-align:left;
	}

	&__figure {
		background:#f7f7f7;
		display:block;
		margin:0;
		overflow:hidden;
		position:relative;
	}

	&__image {
		display:block;
		height:auto;
		transition: transform 0.35s;
		width:100%;
	}

	&__date {
		display:block;
		margin:0 auto 10px;

		strong {
			@include vw-font-size(16px);
			background:#f7f7f7;
			color:$purple-light;
			display:block;
			font-family:$font-quicksand;
			font-weight:bold;
			padding:8px 15px;
			text-align:center;

			@include media-breakpoint-up(sm) {
				@include vw-font-size(17px);
			}
		}
	}

	&__status {
		left:0;
		position:absolute;
		top:5px;
		width:100%;
		z-index:2;

		> div {
			@include vw-font-size(12px);
			clear:both;
			color:$white;
			display:block;
			float:left;
			font-family:$font-quicksand;
			font-weight:bold;
			margin-bottom:5px;
			padding:2px 7px;
			text-transform:uppercase;

			@include media-breakpoint-up(md) {
				@include vw-font-size(14px);
			}

			&.pastel-blue { background:$pastel-blue; }
			&.green { background:$green; }
			&.red { background:$pastel-red; }
			&.purple { background:$purple; }
			&.purple-light { background:$purple-light; }
		}

		&--big {

			> div {
				@include vw-font-size(12px);

				@include media-breakpoint-up(md) {
					@include vw-font-size(14px);
				}

				@include media-breakpoint-up(xl) {
					@include vw-font-size(16px);
				}
			}
		}
	}

	&__actions {
		margin:15px auto;

		.btn {
			@include vw-font-size(14px);
			display: block;
			font-weight:500;
			margin-bottom:10px;

			@include media-breakpoint-up(lg) {
				@include vw-font-size(15px);
			}


			/*@include media-breakpoint-up(xl) {
				@include vw-font-size(17px);
			}*/
		}
	}

	&__help {
		display:block;
		margin-top:20px;
		text-align:center;
		width:100%;

		@include media-breakpoint-up(sm) {
			bottom:20px;
			left:0;
			margin-top:0;
			position:absolute;
		}

		a {
			display:block;
			&:hover { color:$purple; }
			&:last-child { margin-top:3px; }
		}
	}

	&__title {
		@include vw-font-size(20px);
		@include vw-line-height(27px);
		color:$black;
		display:block;
		font-family:$font-open-sans;
		font-weight:800;
		letter-spacing:-0.2px;
		margin-bottom:7px;
	}

	&__link {
		&:hover {
			color:$purple-light;
			text-decoration:none;
		}
	}

	&__link--full-link {
		height:100%;
		position:absolute;
		right:0;
		top:0;
		width:100%;
		z-index:1;

		&:before {
			background:linear-gradient(to bottom, rgba(72,76,97,0) 0%, rgba($purple-light,.3) 75%);
			content:'';
			display:block;
			height:100%;
			position:absolute;
			transform: translate3d(0,50%,0);
			transition: opacity 0.35s, transform 0.35s;
			width:100%;	
		}

		&:hover {
			&:before {
				opacity: 1;
				transform: translate3d(0,0,0);
			}
		}
	}

	&__info {
		overflow:hidden;
		padding:20px;
	}

	&__details {
		margin-bottom:40px;

		@include media-breakpoint-up(md) {
			margin-bottom:60px;
		}

		.text-small {

			span {
				display:inline-block;
				vertical-align:middle;

				&:after {
					content:'|';
					display:inline-block;
					margin:0 5px;
				}

				&:last-child:after { display:none; }
			}
		}
	}

	.car-list__block--full-item {

		> span { display:block; }
		
		.flex {
			align-items:flex-end;
			display:flex;
		}

		span {
			display:inline-block;
			margin-bottom: 3px;
			padding-right:5px;

			@include media-breakpoint-up(md) {
				margin-bottom:0;
			}
		}

		.price-renting {
			display:inline-block;
			font-weight:bold;
			vertical-align:text-bottom;

			@include media-breakpoint-up(md) {
				@include vw-font-size(23px);
			}

			b {
				@include media-breakpoint-up(md) {
					@include vw-font-size(30px);
				}
			}
		}
	}
}