// ==========================================================================
// Filters
// ==========================================================================


// 1.Config


// 2.Base

.filters {
	background:#f7f7f7;
	margin-bottom:10px;
	min-height:110px;
	padding:10px 15px 14px;
	text-align:left;

	@include media-breakpoint-up(md) { 
		min-height:135px;
		padding:20px 15px 27px;
	}

	@include media-breakpoint-up(lg) { 
		margin-bottom:0;
	}

	&__content {
		top:0;		

		> * {
			margin:0 auto;

			@include media-breakpoint-up(sm) { 
				max-width:510px;
			}

			@include media-breakpoint-up(md) { 
				max-width:790px;
			}
		}
	}

	&__title {
		@include vw-font-size(18px);
		@include vw-line-height(24px);
		color:$black;
		display:block;
		font-weight:700;
		text-align:left;

		@include media-breakpoint-up(md) { 
			margin-bottom:10px;
		}
	}

	&__title__img {
		display:block;
		display:none;
		height:auto;
		margin:0 auto 10px;
		width:66px;
	}

	&__button {
		background:$white;
		color:$black;
		display:block;
		font-family:$font-quicksand;
		font-weight:normal;
		line-height:35px;
		margin-top:10px;
		text-align:left;
		vertical-align:top;

		&:hover {
			background:$white !important;
			color:$black !important;

			&:before { color:$black !important; }
		}
		
		@include media-breakpoint-up(md) {
			display:none;
		}

		&.filters__button--close {
			background:$purple !important;
			color:$white !important;

			&:before {
				color:$white !important;
				content: '\e80a';
			}
		}

		&.filters__button--closed {
			background:$purple !important;
			color:$white !important;
			text-align:center;
		}
	}

	.form {
		//display: block;
		display:none;

		@include media-breakpoint-up(md) { 
			display:block;
		}

		.form-label-text {
			@include vw-font-size(15px);
		}
	}

	&__box {
		background:$white;
		border-radius:8px;
		display:block;
		margin-top:10px;
		padding:10px 20px;

		&__title {
			@include vw-font-size(17px);
			color:$purple-light;
			display:block;
			font-family:$font-open-sans;
			font-weight:bold;
			margin-bottom:6px;

			@include media-breakpoint-up(md) {
				@include vw-font-size(16px);
				@include vw-line-height(24px);
			}

			> * {
				display:inline-block;
				vertical-align:middle;
			}

			i {
				opacity:.35;
			}

			> span {
				width:calc(100% - 40px);
			}

			.ico-less {
				display:none;
			}
		}

		&__title--no-margin {
			margin-bottom:0;
		}


		&__range {

		}

		&__rangerslide {

		}

		&__range__text {
			@include vw-font-size(15px);
			@include vw-line-height(19px);
			display:block;
			margin:10px auto 18px;
			
			/*@include media-breakpoint-up(md) { 
				@include vw-font-size(21px);
				@include vw-line-height(27px);
			}*/
		}

		&__init {

		}

		&__range__min {
			
		}
		&__range__max {

			&:before {
				content:'-';
				display:inline-block;
				margin:0 5px 0 0;
				vertical-align:text-top;
			}
		}

		&.filters__box--no-margin-top { 
			border-top-left-radius: 0;
    		border-top-right-radius: 0;
			margin-top:0;
			padding-top:0;			
		}

		&.filters__box--no-border-bottom {
			border-bottom-left-radius: 0;
    		border-bottom-right-radius: 0;
    		padding-bottom:1px;
		}
	}

	&__box--inline {
		.filters__box__range__text { display:none; }

		@include media-breakpoint-up(lg) { 
			.filters__text--inline, .filters__box__range {
				display:inline-block;
				vertical-align:top;
				width:140px;
			}

			.filters__box__range {
				margin:10px 0 30px;
				width:calc(100% - 150px);
			}
		}

		.filters__box__range {
			margin:20px 0 60px;

			@include media-breakpoint-up(lg) { 
				margin:10px 0 30px;
				width:calc(100% - 150px);
			}
		}

		.noUi-handle {
			/*margin-left:25px;*/
			margin-left:0;
		}
	}

	&__environmental-badge {
		margin-bottom:6px;

		&:last-child { margin-bottom:0; }

		img {
			display:inline-block;
			margin-right:5px;
			vertical-align:middle;
			width:38px;
		}

		.info {
			display:inline-block;
			position:relative;
			width: calc(100% - 50px);
		}

		.filters__accordeon__option {
			margin-bottom:4px;
		}
	}

	&__box__padding {
		position:relative;

		.form-label--checkbox { margin-bottom:0; }
	}

	&__accordeon {
		
		&__button {
			cursor:pointer;

			&:hover {
				color:$purple;
			}
		}

		&__content {
			display:none;
			margin-bottom:15px;
			padding:0 10px;
		}

		&--opened {
			.filters__accordeon__button {
				color:$purple;
				
				.ico-plus {
					display:none;
				}

				.ico-less {
					display:inline-block;
				}
			}
		}

		&__option {
			position:relative;
		}
	}

	&__link-options {

	}

	&__link {

	}
}

body:not(.desktop) {
	@media (max-width:991px) {
		.filters--fixed {
			.filters__content {
				background:#f7f7f7;
				left:0;
				max-height: calc(100% - 85px);
		    	overflow-y: auto;
				padding:5px 15px 15px 15px;
				position:fixed;
				right:0;
				//top:60px;
				top:89px;
				transition:$transition-base;
				z-index:1;

				@include media-breakpoint-up(md) { 
					top:$headerHeight;
				}

				&.anim {
					top:-150px;
				}

				.filters__title { display:none; }
			}
		}

		.filters:not(.filters--fixed) {
			.filters__form {
				background:#f7f7f7;
				left:0;
				max-width:100%;
				padding:5px 15px 15px 15px;
				position:absolute;
				width:100%;
				z-index:1;

				> * {
				    margin-left: auto;
				    margin-right: auto;
				    max-width: 510px;
				}
			}
		}
	}
}

.filters__box--type-search {
	display:block !important;
	padding:4px 10px 0 10px;

	@include media-breakpoint-up(md) { 
		padding:10px 20px;
	}
		
	.form-control--checkbox {
	    border: 1px solid #7e7d7d;
	    border-radius: 13px;

	    @include media-breakpoint-up(md) { 
	    	border-width: 3px;
		    height: 28px;
		    width: 28px;
		}

	    &:before { color:#000; }
	}

	.form-label-text {
		max-width:calc(100% - 45px);

		@include media-breakpoint-up(md) { 
			font-size:21px !important;
			@include vw-line-height(27px);
		}
	}

	input:checked + label .form-label-text { font-weight:bold; }

	.filters__accordeon__option {
		margin-bottom:0;
		width:50%;

		@include media-breakpoint-up(md) { 
			margin-bottom:10px;
			margin-top:5px;
			width:100%;

			&:last-child {
				margin-bottom:0;
				margin-top:0;
			}
		}
	}

	.filters__environmental-badge {
		display:flex !important;
		flex-wrap:nowrap;

		@include media-breakpoint-up(md) { 
			flex-wrap:wrap;
		}
	}

	.info { width:100%; }
}