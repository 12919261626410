.item-large {
	display: flex;
	flex-wrap:wrap;
	justify-content:flex-start;
	text-align:left;	

	&__figure {
		background:#000;
		overflow:hidden;
		position:relative;

		@include media-breakpoint-up(md) { 
			height:inherit;
			width:49%;
		}

		img {
			display:block;
			height:auto;
			transition: 0.35s;
			width:100%;

			@include media-breakpoint-up(md) { 
				height:100%;
				left:0;
				object-fit:cover;
				position:absolute;
				top:0;
			}
		}

		&:hover {
			img {
				opacity:.85;
			}
		}
	}

	&__content {
		margin:0 auto;
		padding:20px;

		@include media-breakpoint-up(md) { 
			box-sizing:border-box;
			display:inline-block;
			margin:0;
			padding:20px 25px 30px;
			width:49%;
		}
	}

	&__title {
		font-size:17px !important;
		line-height:24px !important;
		color:$black !important;
		display:block;
		font-weight:700;
		margin:0 auto !important;

		@include media-breakpoint-up(lg) { 
			font-size:24px !important;
			line-height:36px !important;
		}
		
		@include media-breakpoint-up(xl) { 
			font-size:30px !important;
			line-height:40px !important;
		}
	}

	&__description {
		margin-bottom:5px;
	}

	&__attributes {
		span {
			display:inline-block;

			&:after {
				content:'|';
				display:inline-block;
				margin:-2px 0 0 5px;
				vertical-align:top;
			}

			&:last-child:after { display:none; }
		}
	}

	&__price {
		.car-detail__price__info {
			margin:20px auto;
			text-align:left;

			@include media-breakpoint-up(md) { 
				margin:25px auto;
			}

			.car-detail__price__block {
				text-align:right;

				&:first-child {
					border-color:$black;
					text-align:left;
				}

				b {
					@include vw-font-size(25px);
					@include vw-line-height(24px);
					display:inline-block;
					font-weight:bold;
					margin-right:5px;

					@include media-breakpoint-up(sm) { 
						@include vw-font-size(21px);
					}

					@include media-breakpoint-up(xl) { 
						@include vw-font-size(42px);
						@include vw-line-height(32px);
					}
				}

				div {
					@include vw-font-size(18px);
		
					@include media-breakpoint-up(md) { 
						@include vw-font-size(15px);
					}

					@include media-breakpoint-up(xl) { 
						@include vw-font-size(26px);
					}
				}
			}
		}
	}

	&__return-guarantee {

		&__ico {
			display:inline-block;
			vertical-align:top;
			width:45px;

			img {
				display:block;
				height:auto;
				width:100%;
			}
		}

		&__info {
			display:inline-block;
			padding-left:15px;
			vertical-align:top;
			width:calc(100% - 55px);
		}
	}

	&__cta {
		margin-top:25px;

		@include media-breakpoint-up(md) { 
			margin-top:30px;
		}
	}

	&__link--full-link {
		display:block;
		height:100%;
		left:0;
		position:absolute;
		top:0;
		width:100%;
		z-index:1;

	}

	&__price-detail {
		margin-top:25px;

		&__title {
			border-bottom:2px solid #979797;
			display:block;
			margin-bottom:15px;
			padding-bottom:10px;
			text-transform:uppercase;
		}

		&__specification {
			align-items:stretch;
			display:flex;
		    flex-wrap:nowrap;
		    flex-direction:row;
		    justify-content:space-between;
		    margin-bottom:10px;

			> * {
				@include vw-font-size(16px);
				@include vw-line-height(26px);
				color:$color-base;
				flex-basis:1;
				font-family:$font-open-sans;
				
				@include media-breakpoint-up(xl) { 
					@include vw-font-size(18px);
					@include vw-line-height(27px);
				}
			}
		}

		&__final-price {
			background:$pastel-blue;
			color:$black;
			display:flex;
		    flex-wrap:wrap;
		    flex-direction:row;
		    font-family:$font-open-sans;
		    font-size:17px!important;
			line-height:24px!important;
		    margin:20px 0 0;
		    justify-content:space-between;
		    padding:15px;

			
			@include media-breakpoint-up(xl) { 
				font-size:20px!important;
				line-height:27px!important;
			}

		    	
		    > * {
		    	align-self: flex-end;
		    	color:$black;
		    	flex-basis:1;
		    	font-weight:bold;
		    	margin-bottom:0;
		    }

		    .title {
		    	font-size:27px !important;
				line-height:37px !important;
				color:$purple;
				display:inline;
				font-family:$font-quicksand;
				font-weight:bold;
				margin:0 auto 20px;
				
				@include media-breakpoint-up(xl) { 
					font-size:28px !important;
					line-height:34px !important;
				}

		    }

		    .highlight-medium { margin-bottom:0; }

		    small {
				font-size: 16px !important;
				display:block;
				margin-bottom:-5px;
			}
		}

		small {
			@include vw-font-size(14px);
			@include vw-line-height(19px);
			margin-top:5px;
		}
	}

	a {
		&:hover {
			color:$purple-light;
			text-decoration:none;
		}
	}
}

.item-large--column {
	margin:0 auto;

	@include media-breakpoint-up(lg) { 
		max-width:575px;
	}

	.item-large__figure, .item-large__content { width:100%; }

	.item-large__figure {
		img {
			display:block;
			height:auto;
			object-fit:contain;
			position:relative;
		}
	}
}