.comment {
	margin:0 auto;
	max-width:716px;

	&__content {
		@include media-breakpoint-up(md) { 
			margin-bottom:30px;
		}
	}

	&__title {
		@include vw-font-size(16px);
		@include vw-line-height(20px);
		color:#232323;
		display:block;
		font-family:$font-quicksand;
		font-weight:bold;
		margin:0 auto 20px;
		text-align:left;
		
		@include media-breakpoint-up(md) { 
			margin-bottom:25px;
		}		
	}

	&__box {
		background:rgba(247, 247,247,.5);
		margin-bottom:20px;
		padding:17px;
		text-align:left;

		.comment__box {
			background:none;
			padding-left:30px;
			padding-right:0;
			margin-top:20px;

			@include media-breakpoint-up(md) { 
				padding:0 0 0 45px;
			}

			&:last-child {
				margin-bottom:0;
			}

			.form {
				margin-top:20px;

				.form-textarea {
					@include vw-line-height(24px);
					padding-top:5px;
				}
			}

			.comment__box { padding-left:0; }
		}
	}

	&__box--reply {

	}

	&__name {
		@include vw-font-size(13px);
		@include vw-line-height(24px);
		text-transform:uppercase;

		&:after {
			content:'·';
			display:inline-block;
			margin: 0 5px 0 9px;
    		line-height: 8px;			
		}
	}

	&__when {
		@include vw-font-size(13px);
		@include vw-line-height(24px);
	}

	&__text {
		@include vw-font-size(14px);
		@include vw-line-height(23px);
		color:#232323;
		display:inline-block;
		padding-right: 22px;
		width: calc(100% - 85px);
	}

	&__button {
		display:inline-block;

		.btn {
			@include vw-font-size(12px);
			@include vw-line-height(30px);
			background:#f7f7f7;
			color:$purple-light;
			min-height:30px;

			&:hover {
				background:$purple;
				color:$white;
			}
		}
	}
}

.comment__form {
	margin:0 auto;
	max-width:716px;

	.form {
		max-width:100%;

		.form-submit {
			@include media-breakpoint-up(md) {
				margin-top:30px;
				min-width:250px;
			}
		}

		.form-textarea {
			@include vw-line-height(24px);
			padding-top:5px;
		}
	}
}