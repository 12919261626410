.article-column {
	margin:30px auto;

	.container-fluid {
		padding-left:0;
		padding-right:0;

		@include media-breakpoint-up(sm) {
			align-items:flex-start;
			display:flex;
			flex-wrap:nowrap;
			margin:0 auto;
			max-width:1440px;
			width:100%;;
		}

		@include media-breakpoint-up(md) {
			align-items:center;
		}
	}

	&.article-column--reverse {
		.container-fluid {
			@include media-breakpoint-up(sm) {
				flex-direction:row-reverse;

				.article-column__col--info {
					padding-right:29px;
					padding-left:15px;
				}
			}
		}
	}

	&__col {
		@include media-breakpoint-up(sm) { 
			//width:40%;
			width:auto;
		}
	}

	&__col--info {
		padding-left:15px;
		padding-right:15px;
		text-align:left;

		.title-small {
			margin-top:0;
		}

		@include media-breakpoint-up(sm) { 
			padding-left:29px;
			padding-right:15px;
			width:60%;
		}

		@include media-breakpoint-up(lg) { 
			padding-left:40px;
		}
	}

	&__col--figure {
		margin-bottom:20px;

		@include media-breakpoint-up(md) { 
			margin-bottom:0;
		}
	}

	&__img {
		display:block;
		height:auto;
		margin:0 auto;
		max-width:525px;
		width:100%;

		@include media-breakpoint-up(sm) { 
			max-width:325px;
		}

		@include media-breakpoint-up(md) { 
			max-width:525px;
		}
	}

	&__highlight {
		@include vw-font-size(17px);
		@include vw-line-height(24px);
		display:block;
		font-family:$font-open-sans;
		
		@include media-breakpoint-up(md) { 
			@include vw-font-size(18px);
			@include vw-line-height(27px);
			margin-bottom:35px;
			max-width:580px;
		}
	}

	&__list {
		display:flex;
		flex-wrap:wrap;
		list-style-type:none;
		margin:0;
		padding:0;

		@include media-breakpoint-up(md) { 
			margin:35px 0 0;
		}

		li {
			@include media-breakpoint-up(md) { 
				max-width:370px;
				padding-right:20px;
				width:50%;
			}

			@include media-breakpoint-up(lg) { 
				margin-bottom:70px;
				padding-right:35px;
			}

			h3 {
				color:$color-base;
				@include vw-font-size(17px);
				@include vw-line-height(24px);
				display:block;
				font-family:$font-quicksand;
				font-weight:bold;
				margin-bottom:7px;
				
				@include media-breakpoint-up(md) { 
					@include vw-font-size(18px);
					@include vw-line-height(27px);
				}
			}

			.text-small {
				@include media-breakpoint-up(md) { 
					margin-top:0;
				}
			}
		}
	}
}