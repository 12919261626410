.section.car-assessment {
	padding-top:90px;
	position:relative;

	.hero {
		max-height:550px;
		overflow:hidden;
		position:absolute;
		width:100%;

		&:before {
            background-blend-mode: multiply;
            background-image:-webkit-gradient(linear, left top, left bottom, from(rgba(95, 88, 88, 0.25)), to(rgba(0, 0, 0, 0)));
            background-image:linear-gradient(to bottom, rgba(95, 88, 88, 0.25), rgba(0, 0, 0, 0));
            content:'';
            display:block;
            height:100%;
            left:0;
            position:absolute;
            top:0;
            width:100%;
            z-index:1;
        }

		img {
			height: 100%;
			object-fit: cover;
			object-position: center;
			width: 100%;
		}
	}

	.top-header__caption {
		width:100%;
	}

	h1 {
		@include vw-font-size(23px);
		@include vw-line-height(26px);
		color:$white;
		display:block;
		font-family:$font-quicksand;
		font-weight:bold;
		margin:40px auto 40px;
		text-shadow: 2px 2px 6px rgba($black, 0.3);
		text-align:center;
		
		@include media-breakpoint-up(md) { 
			@include vw-font-size(28px);
			@include vw-line-height(38px);
		}
	}

	.select2 {
		background:#f7f7f7;
		border:2px solid #f7f7f7;
		border-radius: 8px;
		padding: 1px;

		&.select2-container--open {
			border: 2px solid #e6e6e6;
			padding: 0;
		}

		&.select2-container--disabled {
			background: #eee;

			.select2-selection--single { 
				cursor: auto;
			}
		}

		/*.selection {
			text-align:left;
			padding-left:15px;
			padding-right:15px;
		}*/
	}

	.select2-container .select2-selection--single .select2-selection__rendered {
		padding-left: 15px;
		text-align: left;
		@include vw-font-size(14px);
	}

	.select2-container .select2-selection__arrow b {
		border-top: 2px solid $purple-light;
		border-right: 2px solid $purple-light;
	}

	.g-recaptcha > div {
		margin-left: auto;
		margin-right: auto;
	}

	.form-group--legals {
		@include media-breakpoint-up(sm) {
			max-width: 80%;
		}
	}

	.box-form {
		background:$white;
		border-radius:10px;
		box-shadow:0 2px 9px 0 rgba(218, 218, 218, 0.5);
		display:inline-block;
		margin-bottom:30px;
		max-width:540px;
		padding:20px;
		position:relative;
		width:100%;
		z-index:1;

		@include media-breakpoint-up(md) {
			padding:40px;
			margin-bottom:50px;
		}

		h2 {
			@include vw-font-size(16px);
			@include vw-line-height(24px);
			display:block;
			font-family:$font-quicksand;
			font-weight:bold;
			
			@include media-breakpoint-up(md) { 
				@include vw-font-size(19px);
				@include vw-line-height(28px);
			}
		}

		.step-1 label, .step-2 label {
			font-size:14px;
			font-weight:600;
			padding-right:0;

			@include media-breakpoint-up(sm) { 
				text-align:right;
			}

			&[for=transmission_1], &[for=transmission_2],
			&[for=when_to_sell_1], &[for=when_to_sell_2],&[for=when_to_sell_3],
			&[for=buying_car_1], &[for=buying_car_2], &[for=acceptance] {
				text-align:left;
				font-weight:400;
			}
		}

		.form .form-control--radio {
			background:#f7f7f7;
		}

		.form .form-control, .form .form-textarea {
			background:#f7f7f7;
			border-color:#f7f7f7;
			font-size:14px;

			&::placeholder { color: $black; }
			&:-ms-input-placeholder { color: $black; }
			&::-ms-input-placeholder { color: $black; }
		}

		.form .form-group.error .form-control, .form .form-group.form-group--error .form-control {
			border-color:$bgErrorForm;
		}

		.form-group .row {
			align-items:center;
		}


		.form-label--checkbox {
			.form-control--checkbox {
				background:#f7f7f7;
				border-color:#452466;
				height:24px;
				width:24px;
			}

			.form-label-text {
				font-size:13px;

				a {
					color:$purple-light;
					font-weight:bold;
				}
			}
		}

		.legals-block {
			@include media-breakpoint-up(sm) { 
				margin-left:19%;
			}
		}
	}
}