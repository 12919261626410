.blog {
	margin:0 auto;
	max-width:1260px;

	&__intro {
		border-bottom:1px solid #979797;
		padding-bottom:16px;
		margin-bottom:20px;
		text-align:left;

		@include media-breakpoint-up(md) { 
			margin-bottom:36px;
		}

		h1 {
			@include vw-font-size(23px);
			@include vw-line-height(26px);
			display:block;
			font-family:$font-quicksand;
			font-weight:bold;
			margin:0 auto 15px;
			
			@include media-breakpoint-up(md) { 
				@include vw-font-size(40px);
				@include vw-line-height(48px);
			}
		}

		p {
			@include vw-font-size(16px);
			@include vw-line-height(24px);
			display:block;
			font-family:$font-open-sans;
			margin:0;
			max-width:650px;
			
			@include media-breakpoint-up(md) { 
				@include vw-font-size(18px);
				@include vw-line-height(27px);
			}

		}
	}

	&__list {
		align-items:flex-start;
		display:flex;
		flex-wrap:wrap;
		margin-left: -7px;
    	margin-right: -7px;

    	@media (min-width: 1290px) {
			margin-left: -24px;
    		margin-right: -24px;
    	}

		@include media-breakpoint-up(xl) {
			margin-left: -24px;
    		margin-right: -24px;
    	}

		&__item {
			margin-bottom:20px;
			padding:0 7px;
			text-align:left;
			width:100%;
			
			@include media-breakpoint-up(sm) { 
				width:50%;
			}

			@include media-breakpoint-up(md) { 
				margin-bottom:60px;
				padding:0 15px;
				width:33%;
			}

			@include media-breakpoint-up(xl) { 
				padding:0 24px;
			}

			&--first {
				width:100%;

				@include media-breakpoint-up(md) { 
					align-item:flex-start;
					display:flex;
					flex-wrap:nowrap;
				}

				.blog__list__text { display:block; }

				.blog__list__image {
					@include media-breakpoint-up(md) { 
						padding-right:15px;
						width:66%;
					}

					@include media-breakpoint-up(xl) { 
						padding-right:16px;
					}
				}

				.blog__list__info {
					@include media-breakpoint-up(md) { 
						padding:20px 0 20px 20px;
						width:34%;
					}

					@include media-breakpoint-up(lg) { 
						padding:25px 0px 25px 50px;
					}
				}

				.blog__list__title {
					@include media-breakpoint-up(md) { 
						@include vw-font-size(32px);
						@include vw-line-height(38px);
					}
				}

				.blog__list__text {
					@include media-breakpoint-up(md) { 
						@include vw-font-size(18px);
						@include vw-line-height(24px);
					}
				}
			}
		}

		&__image {

			img {
				display:block;
				height:auto;
				width:100%;
			}
		}

		&__info {
			padding:20px 15px;
		}

		&__title {
			@include vw-font-size(16px);
			@include vw-line-height(21px);
			font-family:$font-quicksand;
			font-weight:bold;

			@include media-breakpoint-up(md) { 
				@include vw-font-size(18px);				
			}
		}

		&__text {
			@include vw-font-size(15px);
			@include vw-line-height(24px);
			font-family:$font-open-sans;
			display:none;
			margin-bottom:0;

			@include media-breakpoint-up(md) { 
				@include vw-font-size(18px);				
			}
		}


		.btn {
			@include vw-font-size(14px);
    		line-height: 30px;
			margin-top:19px;
			min-height: 30px;
		}

		&__item--no-image {
			.blog__list__image {
				background:url(#{$url-statics}/svg/logo.svg) center no-repeat #f5f5f5;
				padding-top:60.5%;
			}
		}
	}
}