.numeric-book-steps {
	border:2px solid #ababf6;
	border-radius:8px;
	display:inline-block;
	margin:20px auto;
	overflow:hidden;
	text-align:left;
	width:auto;

	@include media-breakpoint-up(md) { 
		margin:30px auto 50px;
	}

	@media (min-width:980px) {
		display:flex;
		min-height:50px;
		width:100%;
	}

	&__step {
		display:inline-block;
		text-align:left;
		vertical-align:top;
		width:30px;

		@media (min-width:980px) {
			flex: 1 1 0px;
			flex-grow:1;
			min-width:50px;
			width:auto;
		}

		@include media-breakpoint-up(lg) { 
			width:205px;
		}

		&:first-child {
			.numeric-book-steps__i-step {
				@media (min-width:980px) {
					padding-left:0;
					width:14px;
				}

				@include media-breakpoint-up(lg) { 
					width:24px;
				}

				&:before, span:before { display:none; }
			}
		}

		&:last-child {
			.numeric-book-steps__i-step {
				&:after {
					display:none; 

					@media (min-width:980px) {
						display:inline-block;		
					}
				}
			}
		}

		.numeric-book-steps__name {
			a {
				pointer-events: none;
			}
		}
	}

	&__step--done {
		.numeric-book-steps__name {
			@include media-breakpoint-up(lg) { 
				&:before { display:inline-block; }
			}

			a {
				pointer-events: auto;
			}
		}
	}

	&__step--selected {
		
		.numeric-book-steps__name span {
			opacity:1;
		}

		.numeric-book-steps__i-step {
			background:$purple-light;
			color:$white;
			opacity:1;

			&:after, span:before {
				border-left-color:$purple-light;
			}
		}
	}

	&__i-step {
		@include vw-font-size(16px);
		background:rgba($purple-light,.23);
		color:$purple;
		display:inline-block;
		font-family:$font-quicksand;
		font-weight:bold;
		line-height:40px;
		opacity:.5;
		position:relative;
		text-align:center;
		vertical-align:middle;
		width:30px;

		@media (min-width:980px) {
			line-height:50px;
			padding-left:12px;
		}

		@include media-breakpoint-up(lg) { 
			@include vw-font-size(20px);
			padding-left:10px;
		}

		@media (min-width:980px) {
			&:after, &:before {
				border-top: 25px solid transparent;
				border-bottom: 25px solid transparent;
				border-left: 8px solid rgba($purple-light,.23);
				bottom:0;
				content:'';
				display:block;
				height: 0;
				position:absolute;
				top:0;
				width: 0;
			}

			&:before {
				border-left-color:$white;
				left:0;
				z-index:1;
			}

			&:after {
				right:-8px;
			}
		}

		span {
			@media (min-width:980px) {
				&:before {
					border-top: 25px solid transparent;
					border-bottom: 25px solid transparent;
					border-left: 8px solid #ababf6;
					bottom:0;
					content:'';
					display:block;
					height: 0;
					left:2px;
					position:absolute;
					top:0;
					width: 0;
				}
			}
		}
	}

	&__name {
		color:$purple;
		display:none;
		//padding:0 0 0 7px;
		padding:0 0 0 10px;
		vertical-align:middle;
		width:calc(100% - 40px);

		@media (min-width:980px) {
			display:inline-block;
		}

		@include media-breakpoint-up(lg) { 
			padding-left:15px;
		}

		span {
			@include vw-font-size(13px);
			display:none;
			font-family:$font-quicksand;
			font-weight:bold;
			opacity:.5;
			vertical-align:middle;

			@include media-breakpoint-up(sm) { 
				@include vw-font-size(12px);
			}

			@media (min-width:980px) {
				display:inline-block;
			}

			@include media-breakpoint-up(lg) { 
				@include vw-font-size(14px);
				width:calc(100% - 45px);
			}

			@include media-breakpoint-up(xl) { 
				@include vw-font-size(15px);
			}
		}

		> * {
			display:inline-block;

			@include media-breakpoint-up(lg) { 
				@include vw-line-height(15px);
			}

			@include media-breakpoint-up(xl) { 
				@include vw-line-height(17px);
			}
		}

		&:before {
			@include vw-font-size(15px);
			color:$purple-light;
			display:none;
			margin:0 9px 0 0;

			@include media-breakpoint-up(lg) { 
				@include vw-font-size(20px);
			}
		}
	}
}