%logo {
	background-image:url(#{$url-statics}/svg/logo-negative.svg);
    background-position:left center;
    background-repeat:no-repeat;
    background-size:contain;
}

%logo-color {
	background-image:url(#{$url-statics}/svg/logo.svg);
    background-position:left center;
    background-repeat:no-repeat;
    background-size:contain;
}

%logo-white {
    background-image:url(#{$url-statics}/svg/logo-white.svg);
    background-position:left center;
    background-repeat:no-repeat;
    background-size:contain;
}

%logo-footer {
    background-image:url(#{$url-statics}/svg/logo-footer.svg);
    background-position:left center;
    background-repeat:no-repeat;
    background-size:contain;
}