.custom-fancy-content {
	background:$white;
	border-radius:8px;
	max-width:600px;
	//overflow:hidden;
	overflow:visible;
	padding:30px 20px 16px;
	text-align:center;
	width:calc(100% - 30px);

	@include media-breakpoint-up(sm) { 
		padding-left:30px;
		padding-right:30px;
		width:95%;
	}

	@include media-breakpoint-up(md) { 
		padding:30px 30px;
	}

	&.custom-fancy-content--large { max-width:980px; }

	.form--call-you {
		margin:0 auto;
		//max-width:420px;

		.form-group--center { text-align:left; }
	}

	&.login {
		max-width:500px;

		&.login-section {
			max-width:400px;
		}

		.custom-fancy-content__title {
			margin-bottom:10px;
		}
	}

	&.custom-fancy-content--no-padding-laterals {
		max-width:700px;
		padding:50px 0 35px;

		@include media-breakpoint-up(md) { 
			padding:50px 0 35px;
		}		
	}

	.title-medium {
		margin-bottom:20px;
	}

	&__info {
		margin-bottom:20px;

		@include media-breakpoint-up(md) { 
			margin-bottom:30px;
		}

		p {
			margin:0 auto;
		}

		p + p {
			margin-top:15px;
		}

		a:hover {
			color:$purple;
		}

		.text {
			@include vw-font-size(14px);
			@include vw-line-height(21px);
			
			@include media-breakpoint-up(md) { 
				@include vw-font-size(16px);
				@include vw-line-height(24px);
			}
		}

		&.text-medium {
			@include vw-font-size(16px);
			@include vw-line-height(24px);
			margin-left:auto;
			margin-right:auto;
			max-width:665px;
				
			@include media-breakpoint-up(md) { 
				@include vw-font-size(18px);
				@include vw-line-height(27px);
			}
		}
	}

	&__info--small {
		@include vw-font-size(13px);
		margin-top:40px;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(16px);
		}
	}

	&__info--small-margin-bottom {
		margin-bottom:10px;
		margin-top:10px;
	}

	&__info--no-margin-bottom {
		margin-bottom:0;
	}

	&__info--no-margin-top {
		margin-top:0;
	}

	&__info--small-margin-top { margin-top:20px; }

	&__separator {
		display:block;
		//margin:15px auto;
		margin:6px auto 10px;
		position:relative;

		&:before {
			background:$gray-light;
			content:'';
			display:block;
			height:1px;
			left:0;
			margin-top:1px;
			position:absolute;
			top:50%;
			width:100%;
		}

		span {
			@include vw-font-size(18px);
			background:$white;
			color:$gray-light;
			display:inline-block;
			padding:0 15px;
			position:relative;
			vertical-align:top;
		}
	}

	&__actions {
		
		.btn-block {

		}
	}

	.content-register--init {
		display:none;
	}

	.content-register--mail {
		//display:none;

		.form-control, .form-textarea {
			@include media-breakpoint-up(md) {
				@include vw-height(45px);
				@include vw-line-height(43px);
			}
		}
	}

	.content-feedback {
		display:none;

		.feedback {
			display:none;
		}
	}

	.small {
		@include vw-font-size(15px);
	}

	.custom-content-more-padding {
		@include media-breakpoint-up(md) { 
			padding:0 30px;
		}
	}

	.content-info--margin-top {
		margin-top:25px;

		@include media-breakpoint-up(md) { 
			margin-top:50px;
		}
	}

	&__slide {

		&__content {
			margin:0 auto 20px;
			width:calc(100% - 60px);
			
			@include media-breakpoint-up(md) { 
				width:calc(100% - 130px);
			}
		}

		&__img {
			display:block;
			height:57px;
			margin:20px auto;
			width:auto;

			@include media-breakpoint-up(md) { 
				height:106px;
			}

			&.height {
				height:57px;

				@include media-breakpoint-up(md) { 
					height:107px;
				}
			}
		}

		&__img--ico {

		}

		&__content-img--border {
			border-radius:6px;
			border:2px solid $purple-light;
			margin:20px auto 0;
			padding:7px;
			width:45px;

			@include media-breakpoint-up(md) { 
				border-width:3px;
				padding:7px 12px;
				width:63px;
			}
		}

		&__img--chat {
			display:block;
			height:auto;
			width:100%;			
		}

		.swiper-pagination-bullet-active { background:$purple; }

		&:after, &:before {
			width:30px !important;

			@include media-breakpoint-up(md) { 
				width:70px !important;
			}
		}

	}

	.bg-gray {
		background:$gray-light;
	}

	.border-top {
		border-top: 1px solid #000 !important;
		margin-top: 20px;
		padding-top: 20px;
	}


	&.box-gray-inside {
		padding-left:0;
		padding-right:0;

		.content-info {
			padding:0 20px;

			@include media-breakpoint-up(sm) { 
				padding-left:30px;
				padding-right:30px;
			}

			@include media-breakpoint-up(md) { 
				padding:0 30px;
			}
		}

		.bg-gray {
			padding:10px 20px;

			@include media-breakpoint-up(sm) { 
				padding-left:30px;
				padding-right:30px;
			}

			@include media-breakpoint-up(md) { 
				padding:10px 30px;
			}

			p:last-child {
				margin-bottom:0;
			}
		}
	}

	.text-align-left {
		.custom-fancy-content__info {
			margin-left:0;
		}
	}
}

body:not(.IE) {
	.fancybox-inner .custom-scrollbar {
		//max-height:79vh;
		max-height:75vh;

		&.mCustomScrollbar {
			margin-right:-30px;
		}

		&.mCS_no_scrollbar {
			margin-right:0;
		}
	}
}

.login-section.custom-fancy-content.login {
	padding:$headerHeight 0 0;
	margin:0 auto;

	@include media-breakpoint-up(md) { 
		margin:30px auto;
	}
}