.selection {
	text-align:center;


	@include media-breakpoint-up(md) { 
		padding:60px 0;
	}

	.container {
		max-width:450px;
		width:100%;

		@include media-breakpoint-up(md) { 
			max-width:775px;
		}
	}

	&__figure {
		height:auto;
		margin:0 auto;
		width:75px;

		@include media-breakpoint-up(md) { 
			display:inline-block;
			vertical-align:middle;
			width:139px;
		}

		img {
			display:block;
			height:auto;
			width:100%;
		}
	}


	&__info {
		margin-top:20px;
		width:100%;

		@include media-breakpoint-up(md) { 
			display:inline-block;
			vertical-align:middle;
			margin-top:0;
			padding-left:60px;
			text-align:left;
			width:calc(100% - 150px);
		}
	}

	&__title {

	}

	&__description {
		@include vw-font-size(15px);
		@include vw-line-height(22px);
		color:$color-base;
		display:block;
		font-family:$font-open-sans;
		
		@include media-breakpoint-up(md) { 
			@include vw-font-size(18px);
			@include vw-line-height(27px);
		}
	}

	.btn {
		@include vw-font-size(17px);
		margin-top:20px;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(18px);
		}
	}
}