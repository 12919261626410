.big-call-us  {
	align-items:center;
	background:$purple-light;
	border-radius:4px;
	color:$white;
	display:block;
	font-family:$font-quicksand;
	margin-bottom:15px;
	margin:0 auto 20px;
	max-width:580px;
	padding:13px 15px;

	&__text {
		@include vw-font-size(18px);
		@include vw-line-height(26px);
		display:block;
		font-weight:bold;

		@include media-breakpoint-up(md) {
			@include vw-font-size(21px);
		}
	}

	&__phone {
		@include vw-font-size(24px);
		color:$white;
		font-weight:bold;

		@include media-breakpoint-up(md) {
			@include vw-font-size(32px);
		}

		&:hover {
			color:$white;
			text-decoration:none;
		}
	}

	.text-small { color:$white; }
}