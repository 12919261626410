.discount-header-topbar {
    background:#f8e71c;
    width:100%;

    @include media-breakpoint-up(sm) { 
        min-height:30px;
    }

    .container-fluid {
        align-items:center;
        color:$black;
        display:flex;
        flex-wrap:nowrap;
        width:100%;
    }

    &__info {
        @include vw-font-size(11px);
        display:inline-block;
        font-family:$font-quicksand;
        font-weight:bold;
        margin:0 auto;
        padding:5px 0px;
        text-align:center;

        &:after, &:before {
            background:url(#{$url-statics}/svg/exclamation.svg) center no-repeat;
            background-size:contain;
            content:'';
            display:inline-block;
            height:11px;
            vertical-align:middle;
            width:20px;

            @include media-breakpoint-up(md) { 
                height:20px;
                width:11px;
            }
        }

        &:after {
            transform: scale(1,-1);
        }

        @include media-breakpoint-up(sm) { 
            @include vw-font-size(13px);
        }

        span {
            @include media-breakpoint-up(md) { 
                padding:0 10px;
            }
        }

        > * {
            display:inline-block;
            vertical-align:middle;
        }
    }
}