.contact-topbar {
    background:$indigo;
    width:100%;

    .container-fluid {
        align-items:center;
        color:$white;
        display:flex;
        flex-wrap:nowrap;
        width:100%;
    }

    &__info {
        @include vw-font-size(13px);
        display:none;
        font-family:$font-open-sans;
        padding-right:15px;

        @include media-breakpoint-up(sm) { 
            display:block;
        }
    }

    &__actions {
        align-items:center;
        display:flex;
        flex-wrap:nowrap;

        a {
            @include vw-font-size(13px);
            color:$white;
            font-family:$font-open-sans;

            &:hover {
                color:$purple-light;
                text-decoration:none;
            }

            &:before {
                @include vw-font-size(18px);
                display:inline-block;
                margin:0 auto;
                padding-right:7px;
                width:auto;
            }
        }
    }

    &__actions__phone {
        margin-right:30px;
    }

    &__actions__box {
        background:rgba($white, .2);
        height:30px;
        padding:0;
        text-align:center;
        width:40px;

        &:hover {
            background:rgba($white, 1);
        }

        &:last-child { margin-left:3px; }

        &:before {
            @include vw-line-height(30px);
            padding:0 !important;
            vertical-align:middle;
        }

        &.ico-mail-v2:before {
            @include vw-font-size(16px);
        }
    }
}