.scroll-to-top {
	background:$purple-light;
	border-radius:50%;
	bottom:15px;
	color:$white;
	display:none;
	font-size:16px;
	height:30px;
	line-height:26px;
	margin:0 auto;
	position:fixed;
	text-align:center;
	transform:translate(0,100px);
	transition:$transition-base;
	right:15px;
	width:30px;
	opacity:.5;
	z-index:2;

	&:before {
		transform: rotate(-90deg);
	}

	&:hover {
		background:$purple;
		color:$white;
		opacity:1;
		text-decoration:none;
	}

	@include media-breakpoint-up(md) { 
		bottom:20px;
		font-size:21px;
		height:40px;
		right:20px;
		line-height:40px;
		width:40px;
	}

	&.unpinned {
		transform:translate(0,0);
	}
}