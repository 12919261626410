// ==========================================================================
// Top Section - home
// ==========================================================================


// 1.Config


// 2.Base

.top-header {
	height:500px;
	position:relative;

	@include media-breakpoint-up(md) { 
		height:680px;
	}

    .container-fluid {
        align-items: center;
        display: flex;
        height:inherit;
        justify-content: center;
    }

	&__bg {
        z-index:-1;

        &:before {
            background-blend-mode: multiply;
            background-image:-webkit-gradient(linear, left top, left bottom, from(rgba(95, 88, 88, 0.25)), to(rgba(0, 0, 0, 0)));
            background-image:linear-gradient(to bottom, rgba(95, 88, 88, 0.25), rgba(0, 0, 0, 0));
            content:'';
            display:block;
            height:100%;
            left:0;
            position:absolute;
            top:0;
            width:100%;
            z-index:1;
        }

        img {
            position:absolute;
            height:100%;
            object-fit: cover;
            top: 0;     
            right: 0;
            width:100%;
        }
    }

    &__caption {
        margin-top:20px;
        padding:0 15px;
        position:relative;
        text-align:center;
        width:95%;
        z-index:1;

        @include media-breakpoint-up(sm) { 
            margin-top:0;
        }
    }

    .chat-button {
        bottom:-30px;
        position:absolute;
        right:20px;

        @include media-breakpoint-up(lg) { 
            bottom:-65px;
        }
    }

    &__cta {
        align-items:center;
        //display:flex;
        display:inline-block;
        font-family:$font-quicksand;
        font-weight:normal;
        flex-wrap:nowrap;
        margin:15px auto 0;
        //max-width:232px;

        @include media-breakpoint-up(sm) { 
            margin:25px auto 0;
        }

        /*@include media-breakpoint-up(lg) { 
            margin:50px auto 0;
        }*/

        /*&:before {
            background:url(#{$url-statics}/svg/selection.svg) center no-repeat;
            background-size:contain;
            content:'';
            height:26px;
            margin:0 10px 0 0;
            width:26px;
        }*/

        &.big {
            white-space:normal;

            @include media-breakpoint-up(md) { 
                @include vw-font-size(18px);
                padding-left:20px;
                padding-right:20px;
            }
        }

        &.uppercase { text-transform:uppercase; }
    }

    &__content-cta {
        margin:0 auto;
        max-width:409px;

        .btn {
            @include vw-line-height(20px);
            padding-top: 7px;
            padding-bottom: 7px;

            @include media-breakpoint-up(md) { 
                @include vw-line-height(50px);
                padding-top: 0;
                padding-bottom: 0
            }
        }

        .big {
            display:block;
            width:100%;
        }

        .half {
            //display:none;
            width:100%;

            @include media-breakpoint-up(md) { 
                display:inline-block;
                margin-left:0;
                margin-right:10px;
                max-width:47%;
                width:100%;

                &:last-child {
                    margin-left:10px;
                    margin-right:0;   
                }
            }
        }
    }
}