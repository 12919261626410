// ==========================================================================
// Cookies Bar
// ==========================================================================

// 1.Config
$cookies-bg:$white;
$cookies-border-color:$purple;
$cookies-button-bg:$purple;
$cookies-button-bg-hover:$black;

// 2.Base

.cookies-bar {
    @extend %clearfix;
    @include background-opacity($cookies-bg,.95);
    display:none;
    padding:10px 15px;
    position:fixed;
    text-align:center;
    width:100%;
    z-index:998;    
  
    @include media-breakpoint-up(md) {
        text-align:left;
    }

    @include m('top') {
        border-bottom:1px solid $cookies-border-color;
        top:0;
    }

    @include m('bottom') {
        bottom:0;
        border-top:1px solid $cookies-border-color;
    }


    @include e('text') {
        @include vw-font-size(14px);
        overflow-y:hidden;
        margin:10px auto 15px;
        text-align:left;
        
        @include media-breakpoint-up(md) {
            float:left;
            margin:10px auto;
            width:calc(100% - 140px);
        }

        @include media-breakpoint-up(lg) {
          @include vw-font-size(16px);
        }
    }

    @include e('link') {
        color:#000;
        text-decoration:underline;

        &:hover {
            color:$purple;
            opacity:.85;
            text-decoration:underline;
        }
    }

    @include e('button') {
        @include vw-font-size(14px);
        background:$cookies-button-bg;
        color:$white;
        cursor:pointer;
        display:inline-block;
        font-weight:bold;
        margin:0 auto;
        position:relative;
        //text-transform:uppercase;

        &:after { display:none; }

        &:hover {
            background:$cookies-button-bg-hover;
            color:$white;
            text-decoration:none;
        }


        @include media-breakpoint-up(md) {
          @include vw-font-size(17px);
            float:right;
        }

        @include media-breakpoint-up(lg) {
          @include vw-font-size(19px);
        }
    }
}