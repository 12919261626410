// ==========================================================================
// Testimonials slide
// ==========================================================================


// 1.Config


// 2.Base
.testimonials-slide {
	margin:30px auto 0;
	max-width:1215px;

	&:after, &:before { display:none; }

	&__content {
		background:rgba($purple-light, 0.05);
		border-radius:8px;
		padding:20px 11px 32px 19px;
		margin-bottom:40px;
		
		.ico-star {
			color:$purple-light;
			display:inline-block;
		}
	}

	&__rating {
		display:block;
		margin:0 auto;
	}

	&__comment {
		@include vw-font-size(15px);
		@include vw-line-height(23px);
		display:block;
		font-style:italic;
		margin:15px auto 0;
		text-align:left;
	}

	&__who {
		display:block;
		font-weight:bold;
		margin-bottom:0;
	}

	&__name {
		@include vw-font-size(15px);
		@include vw-line-height(24px);
		letter-spacing:.38px;
		margin-bottom:13px;
	}

	&__top {
		display:flex;
		flex-wrap:nowrap;
		text-align:left;

		.rating { margin-left:-5px; }
	}

	&__avatar {
		background:$white;
		border-radius:50%;
		margin-right:26px;
		overflow:hidden;
		width:54px;

		img {
			display:block;
			height:auto;
			width:100%;
		}
	}
}
