// ==========================================================================
// Menu
// ==========================================================================


// 1.Config



// 2.Base
.menu {
    margin:0;
    padding:0;
    transition: height .2s ease-in-out .25s;

    @include media-breakpoint-up(md) { 
        padding:0 15px;
    }

    @media (max-width:991px) { 
        height:auto !important;
    }
    
    &.delay {
        @include media-breakpoint-up(md) { 
            &:before {
                transition:$transition-base;
            }
        }
    }

    @include media-breakpoint-up(md) {
        align-items: right;
        display:flex;
        height:$headerHeightSm;
        padding-left: 135px;
        position:relative;
        justify-content: flex-end;

        /*&:before {
            background:rgba($purple-light, .95);
            bottom:0;
            content:'';
            display:block;
            left:0;
            position:absolute;
            top:$headerHeightSm;
            transition:$transition-base;
            width:100%;
        }*/
    }

    @include media-breakpoint-up(lg) { 
        padding-left: 215px;
        padding-right: 20px;
    }

    @include media-breakpoint-up(xl) { 
        padding-left: 265px;
    }

    .show-submenu {
        @include media-breakpoint-up(md) {
            .submenu {        
                opacity:1;
                //transition: opacity .15s linear .3s;
                transition:opacity .15s linear .4s;
                visibility:visible;
            }
        }
    }

    &__item {
        display:block;
        position:relative;
        text-align:center;

        @include media-breakpoint-up(md) { 
            display:inline-block;
            line-height:$headerHeightSm;
            margin:0;
            padding:0 7px;

            &:last-child {
                padding-right:0;
            }
        }

        @include media-breakpoint-up(lg) {
            padding:0 15px;
        }

        &.hover {
            .menu__link {
                color:$white;
                text-decoration:none;

                @include media-breakpoint-up(md) { 
                    color:$white;

                    &:focus, &:hover {
                        color:$white;
                    }
                }
            }

            &.menu__item--submenu .menu__link:not(.menu__link--avatar) {
                &:after {
                    border-color:$white;
                    margin-top:2px;
                    transform:translate(0, -50%) rotate(-45deg) skew(5deg, 5deg);

                    @include media-breakpoint-up(md) { 
                        border-color:$white;
                    }
                }
            }

            .submenu {
                @include media-breakpoint-up(md) { 
                    display:block;
                }
            }
        }
    }

    &__item--covid {
        @include media-breakpoint-up(md) { 
            background:$purple-light;
            transition:$transition-base;
            border-radius:0 0 6px 6px;
            max-height:80px;
        }

        @include media-breakpoint-up(lg) {
            margin-right:20px;
        }

        .menu__link {
            background:$purple-light;
            border-radius:6px;
            color:$white !important;
            display:inline-block;
            height: 100%;  
            justify-content:center;
            padding-left:15px;
            padding-right:15px;

            @include media-breakpoint-up(md) { 
                background:none;
                border-radius:0;
                display: flex;
                padding:0;

                span { max-width:75px; }
            }
        }

        &:hover {
            background:$purple;

            .menu__link { color:$white !important; }
        }
    }

    &__item--active {
       > a {
            color:$purple;
       }
    }

    &__item--submenu {
        background:rgba($purple-light,0);
        padding:0;
        position:relative;
        transition:$transition-base;

        @include media-breakpoint-up(md) { 
            background:none;
            padding:0 6px;
        }

        @include media-breakpoint-up(lg) { 
            padding:0 15px;
        }

        &.hover {
            background:rgba($purple-light, .7);

            @include media-breakpoint-up(md) { 
                border-radius:0 0 7px 7px;
            }
        }

        .menu__link {
            padding-left:20px;
            padding-right:20px;
            position:relative;
            width:auto;

            @include media-breakpoint-up(md) { 
                padding:0 15px;
                padding-left:0;
            }

            @include media-breakpoint-up(lg) { 
                padding:0 20px;
                padding-left:0;
            }

            &:not(.menu__link--avatar):after {
                border-top:2px solid $white;
                border-right:2px solid $white;
                content:'';
                display:inline-block;
                height:7px;
                margin-top:-3px;
                position:absolute;
                right:5px;
                top:50%;
                transform:rotate(135deg) skew(5deg, 5deg);
                transition:border-color .2s ease-in-out;
                vertical-align:middle;
                width:7px;
            }
        }
    }

    &__link {
        @include vw-font-size(16px);
        @include vw-line-height(24px);
        align-items: center;
        color:$white;
        cursor:pointer;
        display:inline-block;
        font-family:$font-quicksand;
        font-weight:bold;
        padding:15px 0;
        text-align:center;
        transition:$transition-base;
        //vertical-align:middle;
        width:auto;

        &:focus {
            color:$white;
            text-decoration:none;
        }

        &:hover {
            text-decoration:none;
        }

        @include media-breakpoint-up(md) {
            @include vw-font-size(12px);
            padding:0;
        }

        @media (min-width:1140px) {
            @include vw-font-size(14px);
        }

        @include media-breakpoint-up(lg) {
            @include vw-font-size(15px);
        }

        @include media-breakpoint-up(xl) {
            @include vw-font-size(16px);
        }
    }

    &__link--active {
        color:$purple-light !important;
        &:after { border-color:$purple-light !important; }

        @include media-breakpoint-up(md) { 
            //color:$purple !important;

           // &:after { border-color:$purple !important; }
        }
    }

    &__link--notification {
        padding-left:10px;
        padding-right:10px;
        position:relative;

        &.menu__link--avatar {
            padding:0;

            @include media-breakpoint-up(md) { 
                padding-left:10px;
                padding-right:10px;
            }
        }

        &:after {
            background:#f23a49;
            border-radius:50%;
            content:'';
            display:inline-block;
            height:8px;
            position:absolute;
            right:0;
            //top:20px;
            transform:none;
            width:8px;

            @include media-breakpoint-up(md) {
                top:0;
            }
        }
    }

    &__link--avatar {
        cursor:pointer;
        display:inline-block;
        margin:20px auto;
        overflow:hidden;
        vertical-align:middle;

        @include media-breakpoint-up(md) {
            margin:0 auto;
        }

        &.menu__link--notification {
            &:after {
                height: 10px;
                top: 2px;
                width: 10px;
            }

            &:hover {
                &:after {
                    transform:none;
                }
            }
        }

        @include media-breakpoint-up(md) {
            margin-right:0;
            padding:0 !important;
        }
    }

    &__avatar {
        border-radius:50%;
        display:block;
        height:auto;
        overflow:hidden;
        width:35px;
    }

    &__avatar--bg {
        //background:$white;
        //border:1px solid #ddd;
        height:100%;

        &.icon-white { display:block; }
        &.icon-default { display:none; }
    }

    .menu__item--submenu-avatar {
        @include media-breakpoint-up(md) { 
            
            .submenu {           
                left: auto;
                margin-top:13px;
                min-width: 120px;
                padding-right:15px;
                right:-15px;

                .submenu__link { text-align:right; }            
            }

            &.hover {
                background:none;

                .submenu {
                    background:rgba($purple-light, 0.7);
                    border-radius:0 0 7px 7px;
                }
            }
        }
    }
}

body.desktop {
    .menu .menu__item {
        .menu__link:hover {
            //.menu__link {
                color:$purple-light;

                &:after { border-color:$purple-light; }
            //}
        }

        &.hover.show-submenu {
            .menu__link {
                color:$white;
                &:after { border-color:$white; }

                &:hover {
                    color:$white !important;
                    &:after { border-color:$white; }                    
                }
            }
        }
    }
}