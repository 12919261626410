.equipement {
	border-top:1px solid #eee;
	margin:0 auto;
	padding:20px 0;
	text-align:center;

	@include media-breakpoint-up(md) {
		padding:60px 0;
	}		

	&__list {
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
		margin:20px 0 0;
		max-width:100%;
		padding:0;
		text-align:left;

		@include media-breakpoint-up(md) {
			margin:50px 0 0;
		}
	}

	&__list__item {
		list-style:none;
		padding:0 20px;
		width:100%;

		@include media-breakpoint-up(sm) {
			margin-bottom:20px;
			width:49%;
		}

		&:last-child { margin-bottom:0; }

		@include media-breakpoint-up(md) {
			margin-bottom:30px;
			width:24%;
		}
		
		&:before {
			@include vw-font-size(20px);
			color:$purple-light;
			display:inline-block;
			margin:3px 0 0 0;
			vertical-align:top;
		}
	}

	&__list__info {
		display:inline-block;
		padding-left:10px;
		vertical-align:top;
		width:calc(100% - 30px);

		strong {
			@include vw-font-size(18px);
			@include vw-line-height(24px);
			display:block;
			font-family:$font-quicksand;
			font-weight:bold;
			margin-bottom:10px;

			@include media-breakpoint-up(md) {
				@include vw-font-size(20px);
				@include vw-line-height(27px);
			}
		}

		p {
			@include vw-font-size(14px);
			@include vw-line-height(22px);
			font-family:$font-open-sans;

			@include media-breakpoint-up(md) {
				@include vw-font-size(16px);
				@include vw-line-height(25px);
			}
		}


	}
}