// ==========================================================================
// Chat
// ==========================================================================


// ==========================================================================
// Messages
// ==========================================================================

// 1.Config
$messagesWidth: calc(100% - 300px);

$messagesHeaderBg:#edf0f0;
$messagesContentBg:#fff;
$messagesFooterBg:#fff;

$messagesHeaderHeight:  30px;

$messagesFooterHeight:  45px;


// 2.Base

.chat-module {
	position:fixed;
	right:0;
	bottom:0;
	z-index:10;
    &.chat-module--landing { display:none !important; }
}


.chat-toggler {
	width: 60px;
	height: 60px;
	display: block;
	border-radius: 50%;
	background-color: #8f61ff;
	line-height:60px;
	text-align:center;
	color:#fff;
	//box-shadow: 1px 1px 19px 1px rgba(0,0,0,0.11);
	margin:0 15px 15px 0;
	font-size: 27px;
	padding-right: 3px;
  position:relative;
  z-index: 10001;

  @include media-breakpoint-up(md) { 
    height:85px;
    line-height:85px;
    width:85px;
  }

	&:hover {
		text-decoration:none;
		background-color:$purple;
		color:#fff;
	}

  .ico-close, .ico-spin3 {
    display:none;
  }

  .ico-chat-alt {
    background-image:url(#{$url-statics}/svg/chat-2.svg);
    background-size:contain;
    background-repeat:no-repeat;
    display: block;
    height: inherit;
    width: inherit;

    &:before { display:none; }

  }

  &.opened {
    width: 60px;
    height: 60px;
    line-height:60px;

    .ico-chat-alt { display:none; }
    .ico-close {
      display:inline-block;
    }
  }

  &.processing {
    width: 60px;
    height: 60px;
    line-height:60px;

    .ico-chat-alt, .ico-close { display:none; }
    .ico-spin3 {
      display:inline-block;
      animation: spin 2s infinite linear;
    }
  }
}



.chat__content {
	z-index: 10000;
  width:300px;
	position: absolute;
	bottom: 80px;
	height: 300px;
	right:10px;

  @media (max-height:400px) {
    height: 220px;
  }

  @include media-breakpoint-up(md) { 
    bottom: 100px;
    height: 550px;
    width:500px;
  }
	
}


@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}





.-chat .footer {
    display: none;
}

.button-messages{
    display:block;

    height:60px;
    width:60px;
    border-radius:50%;
    bottom:25px;
    position:fixed;
    right:20px;
    background:$primary;
    z-index:10000;
    line-height:60px;
    text-align:center;
    color:#fff;
    font-size:30px;
    transition:all 0.3s ease-out;
    cursor:pointer;
    opacity:0.75;
    color:#fff;

   span.unread {
        display:block;
        top:-10px;
        right:0;
        position:absolute;
        background:$danger;
        height:25px;
        width:25px;
        border-radius:50%;
        font-size:12px;
        line-height:25px;
        text-align:center;
        //   @include bold();
        color:#fff;
        opacity:0;
    }

    i {
        color:#fff!important;
    }
        

    &:focus {
        color:#fff;
    }

    &:hover {
        opacity:0.6;
        i {
            color:#fff;
        }

       
    }


    .fa-comment-o {

    }

    .fa-close{
        display:none;
    }

    .fa-circle-o-notch {
        display:none;
    }

    &.new span.unread {
        opacity:1;
        animation: blink 1s steps(1) 2;
    }

    @keyframes blink { 50% { opacity: 0; } }

    &--processing, &.processing {

        color:#fff;

        .fa-comment-o {
            display:none;
        }

        .fa-close{
            display:none;
        }

        .fa-circle-o-notch {
            display:inline-block;
        }


    }

    &--opened {

        .fa-comment-o {
            display:none;
        }

        .fa-close{
            display:inline-block;
        }

        .fa-circle-o-notch {
            display:none;
        }


    }

}
 
.m-messages {
    box-shadow:1px 1px 10px rgba(0,0,0,.4);
  	position:absolute;
  	border-radius:5px;
    transition: all 0.5s ease 0.2s;
    background:#fff;
    border:1px solid #ccc;    
    overflow:hidden;
    width:100%;
    //right:- $messagesWidth;
    height:100%;
    top:0;
    //box-shadow: 4px -2px 17px 1px rgba(0,0,0,0.2);

    /*&--opened {
        right:0;     
    }*/

    a {
        text-decoration:underline;
        font-weight:bold;
    }

    &__container {
        position:absolute;
        width:100%;
        height:100%;
        background:#fff;
    }

    &__header {
        height:$messagesHeaderHeight;
        color:#fff;
        background:$messagesHeaderBg;
        position:absolute;
        top:0;
        width:100%;
        text-align:center;
        font-size:20px;
        line-height:$messagesHeaderHeight;
    }

    &__content {
        position: absolute;
        width:100%;
        left:0;
        height:calc(100% - 54px);
        top:0;
        background: #f7f7f7;
       	// border-bottom:1px solid  #edf0f0;
        border-radius:.25rem;
        padding: 20px;    
        overflow-y:auto;
    }


    /*&__content-scroll {
        width:100%;
        padding:20px;
    }*/

    &__footer {
        height:54px;
        background:#f7f7f7;
        color:#fff;
        position:absolute;
        bottom:0;
        width:100%;
        @extend %clearfix;
        padding:0 20px;
        border-top:1px solid #ccc;
        //overflow:hidden;


        
    }


    &__btn-wrapper {
        width:50px;
        height: 100%;
        position:absolute;
        bottom:0;
 
        &--left {
            left: 0;
            border-right:1px solid rgba(0,0,0,.125);
        }

        &--right {
            right: 0;
            border-left:1px solid rgba(0,0,0,.125);
        }
    }

    &__textarea {
        //border:none;
        width:100%;
        resize:none;
        border:none;
        font-size:14px;
        color:#000;
        height:30px;
        background:none;
        //padding: 10px 0;
        padding:5px 0 10px;
        margin-top: 0;
       
    }

    &__input-wrapper {
        position:absolute;
        width: 100%;
        left:0;
        overflow-y:hidden;
        bottom:0;
        
          background:$messagesFooterBg;
        border:1px solid rgba(0,0,0,.125);
        border-radius:.25rem;

        padding:0 10px;



        .redactor-box {
            margin-left:40px;
            width: calc(100% - 80px);
        }
    }

    

    &__btn {
       cursor: pointer;
        border:none;
        color:#8f61ff;
        background:transparent;
        font-size:24px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        padding-right:3px;
 
        &:hover {
          color:$purple; 
        }

        &--secondary {
            color:#666;
            width:50px;
            display: block;

            &:after {
                content:none!important;
            }
        }

    }

    .fix-bottom {
        width: 100%;
        position:absolute;
        //bottom: 0;
        padding:0;
        top:50%;
        transform:translate(0, -50%);

        i.ico-paper-plane-1 {
          display: inline-block;
          margin: 0 auto;
          vertical-align: middle;
        }
    }


    form {
         margin:10px 0 0;
         //margin:0;
    }


    
    &__line {
        display:block;
        font-size:12px;
        height:20px;
        line-height:20px;
        margin-top:15px;
        text-align:right;
        position:relative;
        color:#fff;
        text-align:center;

        &:after {
            content:'';
            position:absolute;
            height:1px;
            width:100%;
            background:$warning;
            @extend %v-align;
            left:0;
        }

        span {
            background: $warning;
            padding: 0 5px;
            position: relative;
            z-index: 1;
        }
    }

}



/************* 9.Chat / Todo **********/

.conversation-list {
  list-style: none;
  padding: 0px 10px;
}

.conversation-list li {
  margin-bottom: 24px;
}

.conversation-list .chat-avatar {
  background-color:#fff;
  width: 30px;
  height:30px;
  border:1px solid #e0e0e0;
  border-radius:50%;
  display: inline-block;
  text-align: center;
  float: left;
  overflow: hidden;
  background-image:url(../svg/avatar.svg);
  background-size:cover;
}

.conversation-list .chat-avatar img {
  width: 100%;
  border-radius: 100%;
  margin-top: -3px;
}

.conversation-list .chat-avatar span {
  font-size: 12px;
  font-style: normal;
}

.conversation-list .ctext-wrap span {
  display: block;
  font-style: normal;
  font-weight: bold;
  position: relative;
  font-size: 12px;
  color: #333!important;

  & + span {
    color: #acacac!important;
  }
}

.conversation-list .conversation-text {
  display: inline-block;
  font-size: 12px;
  float: left;
  margin-left: 12px;
  width: 70%;
  max-width: 600px;
}

.conversation-list .ctext-wrap {
  padding: 10px;
  background: #e8e9ec ;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
}

.conversation-list .ctext-wrap p {
  margin: 0px;
  padding-top: 3px;
}

.conversation-list .ctext-wrap:after {
  right: 100%;
  top: 20px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(213,242,239,0);
  border-right-color: #e8e9ec ;
  border-width: 5px;
  margin-top: -5px;
}

.conversation-list .odd .chat-avatar {
  float: right !important;
}

.conversation-list .odd .conversation-text {
  width: 70% !important;
  margin-right: 12px;
  text-align: right;
  float: right !important;
}

.conversation-list .odd .ctext-wrap {
  background: #d8f2f3!important;
}

.conversation-list .media-item {
  margin-left: 0;
  margin-right: 0;
}

.conversation-list .odd .ctext-wrap span {
  color: #acacac;
}

.conversation-list .odd .ctext-wrap:after {
  left: 100% !important;
  top: 20px !important;
  border-color: rgba(238,238,242,0)!important;
  border-left-color: #d8f2f3 !important;
}
