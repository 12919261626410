.payment-method {
	margin:20px auto;
	text-align:center;

	@include media-breakpoint-up(md) { 
		margin:30px auto;
	}

	&__img {
		display:inline-block;
		height:auto;
		max-width:384px;
		width:100%;
	}
}