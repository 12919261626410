.post-navigation {
	display:none;

	@include media-breakpoint-up(md) { 
		align-content:center;
		display:flex;
		justify-content:space-between;
		margin:30px auto 0;
		max-width:900px;
		width:100%;

		&.post-navigation--align-right {
			justify-content:flex-end;
		}
	}

	&__link {
		@include vw-font-size(13px);
		@include vw-line-height(18px);
		display:block;
		font-family:$font-quicksand;
		margin:0;
		max-width:49%;
		width:100%;

		> * {
			display:inline-block;
			max-width:calc(100% - 60px);
			vertical-align:middle;
		}

		span { box-sizing:border-box; }

		i { color:$purple-light; }
	}

	&__link--prev {
		padding-right:15px;
		text-align:left;

		span { padding-left:10px; }
		i { transform:scale(-1,1); }
	}

	&__link--next {
		padding-left:15px;
		text-align:right;

		span { padding-right:10px; }
	}
}