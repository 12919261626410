.section-columns {
	margin:0 auto;
	max-width:540px;

	@include media-breakpoint-up(md) { 
		display:flex;
		flex-direction:row-reverse;
		flex-wrap:nowrap;
		max-width:1240px;
		padding-top:20px;
	}

	&__column {
		padding:0 15px;

		@include media-breakpoint-up(md) { 
			padding:0 30px;
			width:50%;
		}

		@include media-breakpoint-up(lg) { 
			padding:0 50px;
		}

		.text-medium { text-align:left; }
	}

	&__car-block {
		margin-bottom:20px;
		text-align:left;

		@include media-breakpoint-up(md) { 
			margin-bottom:30px;
		}

		&__link {
			border-radius:8px;
			display:block;
			margin-top:10px;
			overflow:hidden;
			position:relative;

			@include media-breakpoint-up(md) { 
				margin-top:15px;
			}

			img {
				display:block;
				height:auto;
				width:100%;
			}
		}

		&__cta {
			@include vw-font-size(21px);
			background:$purple-light;
			bottom:0;
			color:$white;
			display:block;
			font-family:$font-quicksand;
			left:0;
			padding:5px 12px;
			position:absolute;
			text-align:left;
			width:100%;
			
			@include media-breakpoint-up(md) { 
				@include vw-font-size(27px);
				padding:7px 20px;
			}

			@include media-breakpoint-up(lg) { 
				padding:15px 20px;
			}
		}

		&__eco {
			bottom:12px;
			display:inline-block;
			position:absolute;
			right:12px;
			width:60px !important;
			z-index:1;

			@include media-breakpoint-up(md) { 
				bottom:22px;
				width:80px !important;
			}

			@include media-breakpoint-up(lg) { 
				bottom:33px;
			}
		}

		&__small-note {
			@include vw-font-size(10px);
			@include vw-line-height(16px);
			color:$color-base;
			display:block;
			font-family:$font-open-sans;
			margin:8px 0;
			text-align:left;

			@include media-breakpoint-up(md) { 
				@include vw-font-size(12px);
				@include vw-line-height(18px);
				margin:13px 0;
			}
		}

	}
}