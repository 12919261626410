.map {
	background:#f7f7f7;

	.maps-google {
		display:block;
		height:280px;
		width:100%;

		@include media-breakpoint-up(md) { 
			height:400px;
		}

		@include media-breakpoint-up(lg) { 
			height:600px;
		}

		.gm-style-cc, .gm-svpc, .gmnoprint .gm-style-mtc, .gm-fullscreen-control { display:none; }

		.gm-style .gm-style-iw-c {
			border-radius:5px;
			padding:10px 15px !important;
			max-width:250px !important;

			@include media-breakpoint-up(sm) { 
				max-width:390px !important;
			}

			@include media-breakpoint-up(md) { 
				padding:15px 20px !important;
			}
		}

		.info-window-content {
			color:$black;
			text-align:left;

			> * {
				@include vw-font-size(13px);
				@include vw-line-height(18px);

				@include media-breakpoint-up(sm) { 
					@include vw-font-size(14px);
					@include vw-line-height(19px);
				}
				
				@include media-breakpoint-up(md) { 
					@include vw-font-size(15px);
				}
			}

			.name {
				@include vw-font-size(14px);
				@include vw-line-height(22px);
				color:$black;
				display:block;
				font-weight:bold;
				text-transform:uppercase;

				@include media-breakpoint-up(sm) { 
					@include vw-font-size(15px);
					@include vw-line-height(24px);
					margin-bottom:5px;
				}
			}
			
			.mail {
				display:block;
			}

			a { color:$black; }
		}

		.gm-style .gm-style-iw-d { overflow:auto !important; }
	}
}
