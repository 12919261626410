// ==========================================================================
// Highlight
// ==========================================================================


// 1.Config


// 2.Base
.highlight {
	@include vw-font-size(18px);
	@include vw-line-height(24px);
	color:$color-base;
	display:block;
	font-family:$font-quicksand;
	margin:0 auto 8px;
	
	@include media-breakpoint-up(md) { 
		@include vw-font-size(24px);
		@include vw-line-height(34px);
	}

	&.white {
		color:$white;
	}

	&.black {
		color:$black;
	}


	&.text-shadow {
		text-shadow: 2px 2px 6px rgba($black, 0.3);
	}

	&.bold {
		font-weight:800;
	}

	&.semibold {
		font-weight:bold;
	}

	&.open-sans {
		font-family:$font-open-sans;
	}
}