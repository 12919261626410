	// ==========================================================================
// Car list
// ==========================================================================


// 1.Config


// 2.Base
.car-list {
	display: flex;
	margin-top:27px;
	flex-wrap:wrap;
	justify-content:center;
	text-align:center;

	@include media-breakpoint-up(sm) {
		margin-left:-10px;
		margin-right:-10px;
		justify-content:flex-start;
	}

	@include media-breakpoint-up(md) {
		//margin-top:0;
	}

	&__item {
		border:1px solid #eee;
		margin-bottom:20px;
		position:relative;
		vertical-align:top;
		width:100%;

		@include media-breakpoint-up(sm) {
			margin:0 1% 2%;
			width:48%;
			padding-bottom:90px; // revisar
		}

		@include media-breakpoint-up(md) {
			//padding-bottom:70px;
			padding-bottom:80px;
			min-height:423px;
		}

		@include media-breakpoint-up(lg) {
			width:31.3%;
		}
		
		@include media-breakpoint-up(xl) {
			width:23%;
		}

		.offer {
			background:#f8e71c;
			align-items:center;
			// top: -1px;
			bottom: 0;
			display:flex;
			flex-wrap:nowrap;
			left: 0;
			padding:7px 12px;
			position:absolute;
			width:100%;
			z-index:1;

			&__name {
				@include vw-font-size(15px);
				color:$black;
				font-weight:bold;
				letter-spacing:-0.2px;
				overflow: hidden;
				// padding-right:25px;
				position:relative;
				width:100%;
				//text-overflow: ellipsis;
				//white-space: nowrap;
				text-align:center;

				@include media-breakpoint-up(md) {
					@include vw-font-size(16px);
				}

				@include media-breakpoint-up(xl) {
					@include vw-font-size(18px);
				}
			}

			&__discount {
				@include vw-font-size(20px);
				background:#000;
				border-radius:50%;
				box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
				color:#f8e71c;
				height:56px;
				font-weight:bold;
				letter-spacing:-0.2px;
				line-height:56px;
				margin-left:20px;
				position:relative;
				text-align:center;
				width:56px;
			}
		}

		.delivery {
			height: 100%;
			padding-top: 2%;
			padding-left: 4%;
			padding-right: 10%;

			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;

			background-color: transparent;
			background-image: url("#{$url-statics}/svg/badge.svg");
			background-repeat: no-repeat;
			background-size: contain;
			background-position: top left;

			&__name {
				@include vw-font-size(15px);
				color: $white;
				font-weight: bold;
				overflow: hidden;
				white-space: nowrap;
				position: relative;
				text-align: left;
				text-transform: uppercase;

				@media #{$wide-screen} {
					min-width: 150px;
				}
			}
		}
	}

	.discount__icon {
		position: absolute;
		bottom: -18px;
		right: 7px;
		z-index: 1;
		text-align: center;

		background-color: transparent;
		background-image: url("#{$url-statics}/svg/discount-label.svg");
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;

		display: block;
		width: 36px;
		height: 36px;

		// @media #{$wide-screen} {
		// 	bottom: -24px;
		// }
	}

	&__item--highlight {
		background:#f7f7f7;

		@include media-breakpoint-up(lg) {
			width:48%;

			/*.car-list__title {
				@include vw-font-size(25px);
				@include vw-line-height(32px);
				left:20px;
				letter-spacing:-0.25px;
				position:absolute;
				top:20px;
			}*/

			.car-list__prices {
				width:100%;
			}

			.car-list__like {
				padding-right:15px;
			}

			.car-list__share {
				right:54px;
			}

			&.car-list__item--discount {
				
				.border-left {
				    align-items: center;
				    display: flex;
				    width: auto;
				}

				.discount {
					@include vw-font-size(16px);
					display: block;
				    margin-top: -50px;
				    margin-left: -65px;
				    padding:3px 8px;
				    position: relative;
				    right: 0;
				    top: 0;
				}

				.new-price b, .price b {
					@include vw-font-size(18px);
				}

				.price:before {
					border-width:3px;
					transform:rotate(-16deg);
				}
			}

			.car-list__block {
				&:last-child {
		    		float: right;
		    		width: auto !important;
				}
			}
		}


		/*@include media-breakpoint-up(xl) {
			padding-bottom:0;

			.car-list__details {
				float:left;
				width:50%;
			}

			.car-list__prices {
				bottom:auto;
				float:right;
				position:relative;
				width:auto;

				.car-list__block {
					float:left;
					margin-top:0;
					width:auto;
				}
			}
		}*/

		.car-list__figure--reserved .booked, .car-list__figure--sold .sold {
			@include media-breakpoint-up(lg) {
				bottom:30px;
				right:-41px;
			}
		}

		@include media-breakpoint-up(lg) {
			.car-list__badge {
				//bottom:8px;
				//left:8px;
				//top:auto;
			}
		}
	}

	&__item--discount {
		.price {
			display:inline-block;
			font-weight:normal;
			position:relative;
			width:auto;

			b {
				@include vw-font-size(13px);
				@include vw-line-height(18px);
				font-weight:normal;
				position:relative;

				@include media-breakpoint-up(md) { 
					@include vw-font-size(14px);
					@include vw-line-height(19px);
				}
			}

			&:before {
				background: #f23a49;
			    content: '';
			    display: block;
			    left: -2px;
			    height: 2px;
			    width: calc(100% - 7px);
			    position: absolute;
			    top:11px;
			    transform: rotate(-11deg);
			    z-index: 1;

			    @include media-breakpoint-up(xl) { 
			    	top: 8px;
			    }
			}
		}

		.border-left { position:relative; }

		.discount {
			@include vw-font-size(12px);
			@include vw-line-height(14px);
			border:1px solid $purple-light;
			border-radius:4px;
			color:$purple-light;
			display:inline-block;
			font-family:$font-open-sans;
			padding:2px 8px;
			position:absolute;
			right:0;
			text-align:center;
			top:-22px;
			width:auto;


			@include media-breakpoint-up(sm) {
				right:-10px;
			}

			@include media-breakpoint-up(md) {
				@include vw-font-size(13px);
				@include vw-line-height(15px);
				right:20px;
				top:0;
			}

			@include media-breakpoint-up(lg) {
				right:-10px;
			}

			@include media-breakpoint-up(xl) {
				right:-13px;
				//top:-20px;
			}

			@media (min-width:1590px) {
				top:0;
			}
		}
	}

	&__item--offer {
		//@include media-breakpoint-up(lg) {
			.car-list__badge, .car-list__selection {
				//bottom:44px;
			}
		//}
	}

	&__item--reserved, &__item--sold {
		.car-list__badge, .car-list__selection { bottom:8px; }

		.offer { display:none !important; }
		.delivery { display:none !important; }
	}

	&__box {
		text-align:left;
	}

	&__figure {
		background:#f7f7f7;
		display:block;
		margin:0;
		//overflow:hidden;
		position:relative;
	}

	&__figure--reserved, &__figure--sold {
		//overflow:hidden;

		.booked, .sold {
			background:$green;
			bottom: 30px;
			color:$white;
			display:inline-block;
			font-family:$font-quicksand;
			font-size:15px;
			font-weight:bold;
			line-height:24px;
			min-width:170px;
			padding: 3px 45px;
			position: absolute;
			right: -41px;
			text-align:center;
			transform:rotate(-45deg);
			z-index:1;
		}

		.sold {
			background:#000;
		}
	}

	&__see-more {
		@include vw-font-size(18px);
		color:$white;
		font-family:$font-quicksand;
		font-weight:bold;
		left:0;
		margin:0 auto;
		opacity:0;
		position:absolute;
		text-align:center;
		text-transform:uppercase;
		top:50%;
		transform: scale(1.5);
		transform-origin:center;
		transition: opacity 0.35s .1s, transform 0.35s;
		width:100%;
	}

	&__image {
		display:block;
		height:auto;
		transition: transform 0.35s;
		width:100%;
	}

	&__title {
		@include vw-font-size(20px);
		@include vw-line-height(27px);
		color:$black;
		display:block;
		font-family:$font-open-sans;
		font-weight:700;
		letter-spacing:-0.2px;
		margin-bottom:7px;
	}

	&__link {
		&:hover {
			color:$purple-light;
			text-decoration:none;
		}
	}

	&__link--full-link {
		background:#000;
		display:block;
		//height:100%;
		overflow:hidden;
		position:relative;
		width:100%;
		z-index:1;

		.car-list__image {
			opacity:1;
			//transform:scale(1.12);
			transform:scale(1);
			transition: opacity 0.35s, -webkit-transform 0.35s;
		}

		&:hover {
			&:before, .car-list__see-more {
				opacity: 1;
				transform: translate3d(0,0,0);
			}

			.car-list__see-more {
				transform: scale(1);
				transform-origin:center;
			}

			.car-list__image {
				opacity:.85;
				//transform:scale(1);
				//transform:scale(1.12);
			}

			& + .car-list__info {
				.car-list__link.car-list__title { color:$purple-light; }
			}
		}
	}

	&__like {
		background:rgba($purple-light,0);
		color:$white;
		font-size:23px;
		//padding:10px 7px;
		padding:7px 7px 10px;
		position:absolute;
		right:0;
		text-align:right;
		text-shadow: 2px 2px 6px rgba($black, 0.3);
		top:0;
		z-index:1;

		&.active {
			color:#f23a49;

			&:hover {
				color:$white;
				text-decoration:none;
			}
		}

		&:not(.active):hover {
			color:#f23a49;
			text-decoration:none;
			transform: scale(1.2);
		}
	}

	&__badge {
		bottom:8px;
		display:block;
		position:absolute;
		left:8px;
		//top:8px;
		width:40px;
		z-index:1;

		&[data-tooltip]:before {
			margin-left:40px;

			@include media-breakpoint-up(sm) {
				margin-left:0;
			}
		}

		img {
			display:block;
			height:auto;
			width:100%;	
		}
	}

	&__selection {
		bottom:8px;
		display:block;
		position:absolute;
		left:54px;
		//top:8px;
		width:39px;
		z-index:1;

		img {
			display:block;
			height:auto;
			width:100%;	
		}
	}

	&__info {
		overflow:hidden;
		padding:15px 20px;
	}

	&__details {

		.text {

		}

		.text-small {

			span {
				display:inline-block;
				vertical-align:middle;

				&:after {
					content:'|';
					display:inline-block;
					margin:0 5px;
				}

				&:last-child:after { display:none; }
			}
		}
	}

	&__attributes {
		@extend .car-detail__attributes;
		margin-top: 10px !important;
		margin-bottom: 10px !important;

		&__item {
			@extend .car-detail__attributes__item;
			width: auto !important;
			position: relative;

			// > * {
			// 	display:none;
			//  }

			picture {
				display:block;

				img {
					width: 24px;
					margin-right: 10px;

					@media (min-width:576px) and (max-width:991px) {
						width: 20px;
						margin-right: 8px;
					}
				}
			}

			picture.bw img {
				-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
				filter: grayscale(100%);
			}

		}
	}

	&__prices {
		overflow:hidden;

		@include media-breakpoint-up(sm) {
			bottom:20px;
			left:0;
			padding:0 20px;
			position:absolute;
			width:100%;
		}
	}

	&__block {
		@include vw-font-size(15px);
		@include vw-line-height(24px);
		display:inline-block;
		font-family:$font-open-sans;
		font-weight:bold;
		margin-top:20px;
		//vertical-align:bottom;
		vertical-align:top;
		width:48%;		

		@include media-breakpoint-up(sm) { 
			//@include vw-font-size(15px);
		}

		@include media-breakpoint-up(xl) { 
			@include vw-font-size(14px);
			@include vw-line-height(15px);
		}

		div {
			padding-right:10px;
		}

		&:last-child {

			span {
				padding-left:15px;
				padding-right:0;
			}

			div.border-left {
				border-left:2px solid #d8d8d8;
				padding-left:15px;
				padding-right:0;
			}
		}

		b {
			@include vw-font-size(18px);
			@include vw-line-height(24px);
			display:inline-block;
			font-weight:bold;
			margin-right:5px;

			@include media-breakpoint-up(sm) { 
				@include vw-font-size(17px);
			}

			@include media-breakpoint-up(xl) { 
				@include vw-font-size(18px);
				@include vw-line-height(29px);
			}
		}

		span {
			padding-right:7px;
			@include vw-font-size(13px);
			@include vw-line-height(18px);
			color:#7e7d7d;
			display:block;
			font-weight:normal;
			margin-bottom:5px;

			@include media-breakpoint-up(sm) { 
				@include vw-font-size(11px);
				@include vw-line-height(15px);
			}

			@include media-breakpoint-up(md) { 
				@include vw-font-size(13px);
				@include vw-line-height(18px);
				margin-bottom:0px;
			}
		}
	}

	&__block--full-item {
		float:none !important;
		width:100% !important;

		span { padding-left:0 !important; }
	}

	&__share {
		//background:url(#{$url-statics}/svg/share.svg) center no-repeat;
		//background-size:25px auto;
		color:$white;
		display:block;
		//height:54px;
		padding:10px 7px;
		position:absolute;
		right:41px;
		text-shadow: 2px 2px 6px rgba($black, 0.3);
		top:0;
		//width:46px;
		z-index:1;

		&:hover {
			/*background:url(#{$url-statics}/svg/share-purple.svg) center no-repeat;
			background-size:25px auto;*/
			color:$purple;
			text-decoration:none;
		}

		> * {
			display:inline-block;
			vertical-align:middle;
		}

		i {
			font-size:23px;
		}

		&__text {
			@include vw-font-size(16px);
			display:none;
			font-weight:600;
			letter-spacing:-.5px;

			@include media-breakpoint-up(sm) {
				display:inline-block;
			}

			@include media-breakpoint-up(xl) {
				@include vw-font-size(18px);
			}
		}
	}

	&__item--renting,
	&__item--highlight-renting {
		.car-list__selection { display:none !important; }
	}
}


.car-list--search {
	margin-top:15px;

	.car-list__item {
		/*@include media-breakpoint-up(sm) {
			width:48%;
		}

		@include media-breakpoint-up(sm) {
			width:31.3%;
		}

		@include media-breakpoint-up(xxl) {
			width:23%;
		}


		
		width:100%;

		@include media-breakpoint-up(sm) {
			margin:0 1% 2%;
			width:48%;
			padding-bottom:90px; // revisar
		}


		@include media-breakpoint-up(lg) {
			width:31.3%;
		}
		
		@include media-breakpoint-up(xl) {
			width:23%;
		}*/
	}
}

.car-list--home {
	.car-list__item:not(.car-list__item--highlight):last-child {
		@include media-breakpoint-up(lg) {
			display:none;
		}
		
		@include media-breakpoint-up(xl) {
			display:inline-block;
		}
	}
}

.car-list--avantages {
	.car-list__item--highlight--renting {
	
		@include media-breakpoint-up(lg) {
			padding-bottom:0;
			width:48%;

			.car-list__title {
				position: relative;
	    		left: auto;
	    		bottom: auto;
	    		top: auto;
			}

			.car-list__info {
				display: flex;
			    flex-wrap: nowrap;
			    align-items: flex-start;
			    justify-content: space-between;
			}

			.car-list__prices {
				position: relative;
			    bottom: auto;
			    left: auto;
			    width: auto;
			    padding-right: 0;
			}

			.car-list__item--highlight .car-list__block:last-child { margin-top:0; }

		}

		.car-list__block--full-item {
			.flex > * { display:inline-block; }
		}
	}

	.car-list__item__item {
		border:1px solid #eee;
		margin-bottom:20px;
		position:relative;
		vertical-align:top;
		width:100%;

		@include media-breakpoint-up(sm) {
			margin:0 1% 2%;
			width:48%;
			padding-bottom:90px; // revisar
		}

		@include media-breakpoint-up(md) {
			//padding-bottom:70px;
			padding-bottom:80px;
		}

		@include media-breakpoint-up(lg) {
			width:31.3%;
		}
		
		@include media-breakpoint-up(xl) {
			width:23%;
		}
	}

	.car-list__item:not(.car-list__item--highlight):last-child {
		@include media-breakpoint-up(lg) {
			display:none;
		}
		
		@include media-breakpoint-up(xl) {
			display:inline-block;
		}
	}
}

body:not(.desktop) {
	.car-list__link--full-link {
		&:before, .car-list__see-more {
			opacity: 1;
			transform: translate3d(0,0,0);
		}

		.car-list__see-more { position:relative; }
	}
}


.car-list__item--renting {
	.car-list__block--full-item {

		> span { display:block; }
		
		.flex {
			align-items:flex-end;
			display:flex;
		}

		span {
			display:inline-block;
			margin-bottom: 3px;
			padding-right:5px;

			@include media-breakpoint-up(md) {
				margin-bottom:0;
			}
		}

		.price-renting {
			display:inline-block;
			font-weight:bold;
			vertical-align:text-bottom;

			@include media-breakpoint-up(md) {
				@include vw-font-size(23px);
			}

			b {
				@include media-breakpoint-up(md) {
					@include vw-font-size(30px);
				}
			}
		}
	}
}


.ad-banner-default.car-list__item {
	padding: 40px 0;

	.car-list__icon, .car-list__info {
		text-align: center;
	}
}


.ad-banner-horizontal.car-list__item {
	position: relative;
	width: 100% !important;
	min-height: 140px;
	padding: 25px 0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-color: $color-base;

	.car-list__title, .text {
		color: $white;
	}

	.car-list__title {
		@include vw-font-size(28px);
	}

	@include media-breakpoint-up(md) {
		.text p {
			margin-bottom: 0;
		}
	}

	.car-list__link--absolute {
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 100;
	}
}
