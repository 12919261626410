.booking-bar {
	background:#f7f7f7;
	box-shadow:0px 1px 6px 0 rgba(0, 0, 0, 0.2);
	left:0;
	padding:10px 0;
	position:fixed;
	right:0;
	//top:60px;
	top:89px;
	transition:all .5s ease-in-out;
	z-index:2;

	@include media-breakpoint-up(md) { 
		padding:7px 0;
		//top:$headerHeight;
		top:89px;
	}

	&--header-has-topbar {
		top:120px;

	}

	&.anim {
		top:-150px;
	}

	.container > * {
		vertical-align:middle;
	}

	&__info {
		display:none;
		width:auto;

		@include media-breakpoint-up(md) {
			display:inline-block;
			margin-bottom:0;
			width:140px;
		}

		@include media-breakpoint-up(lg) {
			width:39%;
		}

		> * {
			display:inline-block;
			vertical-align:middle;
		}
	}

	&__image {
		display:none;
		position:relative;
		width:100px;

		@include media-breakpoint-up(md) {
			display:inline-block;
			width:140px;
		}

		img {
			display:block;
			height:auto;
			width:100%;
		}
	}

	.ico-like {
		font-size:15px;
		padding:2px 5px;
	}

	&__text {
		display:block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__title {
		display:block;
	 	margin:0 auto !important;
	 	overflow: hidden;
	    white-space: nowrap;
	    text-overflow: ellipsis;
	}

	&__description {
		display:none;
		padding-right:15px;
		width:49%;

		@include media-breakpoint-up(md) {
			padding-left:15px;
			padding-right:0;
			width:calc(100% - 170px);
		}

		@include media-breakpoint-up(lg) { 
			display:inline-block;
		}
	}

	&__prices {
		display:none;
		margin-bottom:15px;
		width:100%;

		@include media-breakpoint-up(sm) { 
			display:inline-block;
			margin-bottom:0;
			text-align:left;
			width:49%;
		}

		@include media-breakpoint-up(md) { 
			padding-left:15px;
			width:calc(49% - 70px);
		}

		@include media-breakpoint-up(lg) { 
			padding-left:0;
			padding-right:15px;
			text-align:right;
			width:28%;
		}

		@include media-breakpoint-up(xl) { 
			padding-right:30px;
		}

		&__info {

		}

		&__block {
			color:$black;
			display:inline-block;
			font-family:$font-open-sans;
			vertical-align:top;
			width:auto;

			&:first-child {
				border-right:1px solid $black;
				margin-right:15px;
				padding-right:20px;
			}

			span {
				@include vw-font-size(14px);
				display:block;			
				font-weight:300;

				/*@include media-breakpoint-up(xl) { 
					@include vw-font-size(16px);
				}*/

			}

			div {
				@include vw-font-size(18px);
				display:block;
				font-weight:bold;

				/*@include media-breakpoint-up(xl) { 
					@include vw-font-size(26px);
				}*/

				b {
					@include vw-font-size(21px);
					@include vw-line-height(24px);
					font-weight:600;

					@include media-breakpoint-up(xl) { 
						@include vw-font-size(24px);
						@include vw-line-height(30px);
					}
				}
			}
		}

		&__block--no-border {
			border-right:0 !important;
			margin-right:0 !important;
			padding-right:0 !important;
		}
	}

	&__actions {
		display:inline-block;
		text-align:left;
		width:100%;

		@include media-breakpoint-up(sm) {
			text-align:right;
			width:49%;
		}

		@include media-breakpoint-up(md) { 
			width:calc(49% - 70px);
		}

		@include media-breakpoint-up(lg) { 
			width:32%;
		}

		.btn {
			@include vw-font-size(15px);
			@include vw-line-height(30px);
	        font-weight:500;
	        margin-left:5px;
	        min-height: 40px;
	        min-width:115px;
	        width:48%;

	        &:first-child {
	        	margin-left:0;
	        }

	        @media (min-width:650px) {
	        	width:auto;
	        }

	        @include media-breakpoint-up(md) { 
	        	@include vw-font-size(16px);
	        	@include vw-line-height(30px);
	        	min-width:145px;
	        }

	        @include media-breakpoint-up(xl) { 
	           /* @include vw-font-size(18px);
	         	@include vw-line-height(30px);*/
	         	min-height: 40px;
	            min-width:200px;
	        }
		}
	}
}