.renting-booking__summary {
	border:1px solid #cacaca;
	border-radius:8px;
	overflow:hidden;
	width:100%;

	&__top {
		align-items:center;
		background:#f7f7f7;
		display:flex;
		flex-wrap:nowrap;
	}

	&__left {
		background:#f7f7f7;

		&__block {
			
		}

		.renting-booking__summary__img {
			width: 100%;
		}

		.renting-booking__summary__info {
			max-width: none;
			padding: 13px 33px;
			text-align: left;
		}
	}

	&__img {
		height:auto;
		width:100px;

		@include media-breakpoint-up(md) { 
			width:173px;
		}
	}

	&__info {
		padding-left:20px;
		padding-right:15px;
		max-width:calc(100% - 100px);
		text-align:right;
		width:100%;

		@include media-breakpoint-up(md) { 
			max-width:calc(100% - 173px);
			padding-right:33px;
		}
	}

	&__title {
		@include vw-font-size(18px);
		@include vw-line-height(24px);
		color:$indigo;
		display:block;
		font-family:$font-open-sans;
		font-weight:bold;
		
		@include media-breakpoint-up(md) { 
			@include vw-font-size(24px);
			@include vw-line-height(33px);
		}

		&:hover {
			color:$purple-light;
			text-decoration:none;
		}
	}

	&__desc {
		@include vw-font-size(14px);
		@include vw-line-height(27px);
		color:$black;
		display:block;
		font-family:$font-open-sans;		
		
		@include media-breakpoint-up(md) { 
			@include vw-font-size(15px);
		}
	}

	&__details {
		padding:15px 15px;
		width:100%;

		@include media-breakpoint-up(md) { 
			padding:24px 33px;
		}

		&__block {
			text-align:left;
		}

		&__title {
			@include vw-font-size(16px);
			@include vw-line-height(24px);
			color:$black;
			display:block;
			font-family:$font-quicksand;
			font-weight:bold;
			text-align:left;
		}

		&__list {
			border-bottom:1px solid #cacaca;
			border-top:1px solid #cacaca;
			margin:0 auto;
			padding:20px 0;
			list-style-type:none;

			li {
				@include vw-font-size(14px);
				align-items:flex-start;
				display:flex;
				flex-wrap:nowrap;
				justify-content:space-between;
				margin-bottom:15px;
				width:100%;

				&:last-child { margin-bottom:0; }

				span {

				}

				b {

				}

				.red {
					color:#ef024e;
				}
			}
		}

		&__price {
			font-family:$font-open-sans;
			margin-top:20px;

			span {
				@include vw-font-size(15px);
				display:block;
				font-weight:600;
				margin-bottom:10px;
			}

			.final-price {
				@include vw-font-size(26px);
				display:block;
				font-weight:bold;

				b > span {
					@include vw-font-size(40px);
					@include vw-line-height(30px);
					display:inline-block;
					font-weight:700;

					@include media-breakpoint-up(md) { 
						@include vw-font-size(54px);
						@include vw-line-height(40px);
					}
				}
			}

			small {
				@include vw-font-size(14px);
				display:block;
			}
		}
	}

	&__agroup {
		align-items:flex-start;
		display:flex;
		flex-wrap:nowrap;
		margin:15px 0;
		text-align:left;

		@include media-breakpoint-up(md) { 
			margin:25px 0;
		}
	}

	&__months-km {
		align-items:flex-start;
		display:flex;
		flex-wrap:nowrap;

		&:last-child {
			margin-left:15px;

			@include media-breakpoint-up(md) { 
				margin-left:40px;
			}
		}

		b {
			@include vw-font-size(26px);
			@include vw-line-height(30px);
			font-weight:bold;
			padding-right:8px;

			@include media-breakpoint-up(sm) {
				@include vw-font-size(36px);
			}
		}

		span {
			@include vw-font-size(12px);
			@include vw-line-height(15px);
		}
	}
}