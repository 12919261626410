.free-home-delivery {
	border-radius:15px;
	max-width:700px;
	overflow:hidden;
	padding:0;
	text-align:center;

	&__top {
		padding:0;
		position:relative;
	}

	&__image {
		display:block;
		height:auto;
		width:100%;
	}

	&__top-title {
		@include vw-font-size(17px);
		color:$white;
		display:block;
		font-family:$font-title;
		font-weight:bold;
		left:50%;
		max-width:500px;
		padding:0 15px;
		position:absolute;
		top:50%;
		transform:translate(-50%, -50%);
		width:100%;
	
		@include media-breakpoint-up(md) { 
			@include vw-font-size(24px);
		}
	}

	&__info {
		padding:0 25px 35px 25px;
		position:relative;

		@include media-breakpoint-up(md) { 
			padding:0 44px 65px 44px;
		}
	}

	&__ico {
		align-items:center;
		background:$white;
		border-radius:50%;
		display:flex;
		height:80px;
		justify-content:center;
		margin:-25px auto 0;
		overflow:hidden;
		width:80px;

		@include media-breakpoint-up(sm) { 
			height:120px;
			margin:-56px auto 0;
			width:120px;
		}

		@include media-breakpoint-up(md) { 
			height:201px;
			margin:-56px auto 0;
			width:201px;
		}
	}

	&__ico-image {
		height:auto;
		width:50px;

		@include media-breakpoint-up(sm) { 
			width:70px;
		}

		@include media-breakpoint-up(md) { 
			width:124px;

			&.notif {
				margin-top:-77px;
				width:77px;
			}
		}
	}

	&__text {
		@include vw-font-size(17px);
		display:block;
		margin:0 auto 10px;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(24px);
			@include vw-line-height(35px);
		}
	}

	&__highlight {
		@include vw-font-size(24px);
		@include vw-line-height(33px);
		display:block;
		font-family:$font-title;
		font-weight:bold;
		margin:0 auto;
		max-width:533px;

		&.m-b { margin-bottom:8px; }

		@include media-breakpoint-up(md) { 
			&.neg-m-t {
				margin-top:-77px;
			}
		}

		@include media-breakpoint-up(md) { 
			@include vw-font-size(32px);
			@include vw-line-height(41px);
		}
	}

	&__cta { margin-top:10px; }
}