.horizontal-info-block {
	margin:0 auto 30px;
	max-width:1110px;

	@include media-breakpoint-up(md) { 
		margin-bottom:80px;
		overflow:hidden;

		&:first-child {
			margin-top:40px;
		}
	}

	&:nth-child(odd) {

		@include media-breakpoint-up(md) { 
			
			.horizontal-info-block__figure {
				float:right;

				img {
					float:right;
				}
			}

			.horizontal-info-block__info {
				margin-right:-7%;
				width:57%;
			}
		}
	}

	&__figure {
		@include media-breakpoint-up(md) { 
			display:inline-block;
			vertical-align:top;
			width:49%;
		}

		img {
			display:block;
			height:auto;
			width:100%;

			@include media-breakpoint-up(md) { 
				height:450px;
				width:auto;
			}
		}
	}

	&__info {
		border-left:3px solid $purple-light;
		margin:-25px auto 0;
		position:relative;
		width:95%;

		@include media-breakpoint-up(md) { 
			border-width:5px;
			display:inline-block;
			margin-top:20px;
			padding:0;
			vertical-align:top;
			width:49%;
		}
	}

	&__box {
		@include vw-font-size(16px);
		@include vw-line-height(25px);
		background:$white;
		color:$color-base;
		display:block;
		font-family:$font-open-sans;
		margin-left:7px;
		padding:25px 20px;
		text-align:left;
		width:calc(100% - 7px);

		@include media-breakpoint-up(md) { 
			margin-left:10px;
			padding:40px 35px;
			width:calc(100% - 15px);
		}

		p {
			display:block;
			margin:0 auto;
		}

		p + p { margin-top:20px; }
	}
}