.booking-section {
	@include media-breakpoint-up(lg) { 
		text-align:right;
	}

	.container-padding-summary {
		@media (min-width:980px) {
			padding:0;
		}
	}

	#payment-form {
		iframe {
			border:0;
			height:1000px;
			width:100%;

			@include media-breakpoint-up(xl) {
				height:700px;
			}
		}
	}
}

.tasacion, .financiacion, .financing, .warranty-extra {
	
	.rangerslide-container {
		margin:0 auto;
		max-width:280px;
	}

	.form--border {
		border-top:2px solid #e6e6e6;
		margin-top:15px;
		padding-top:15px;
	}

	.filters__box {
		padding:0;
		text-align:center;
	}

	.form-group--inline {
		margin-bottom:15px;
		margin-top:10px;
	}

	.disabled:not(.btn) { color:#777; }

	.car-eval {
		margin:0 auto;
		width:95%;
	}

	.agroup-buttons {

		display:flex;
		margin-top:15px;

		.btn {
			@include vw-font-size(15px);
			flex: 1 1 0px;
			min-width:0;

			@include media-breakpoint-up(md) { 
				@include vw-font-size(17px);
			}

			@include media-breakpoint-up(lg) { 
				@include vw-font-size(21px);
			}
		}
	}
}

.financiacion, .reserva, .section--booking-summary/*, .section--payment */ { display:none; }

.tasacion {
	.result { display:none; }

	small {
		margin-bottom:20px;
	}
}

.financiacion, .section--booking-summary {

	.result, small {
		color:$gray;
	}

	.result {
	    clear: both;
	    display: block;
	    float: left;
	    margin-top:20px;
	    width: 100%;
	}

	.financing-form {
		display:none;
	}
}

.reserva {
	
	.form {
		margin:0 auto;
		max-width:420px;

		.highlight-medium { 
			clear: both;
		    display: block;
		    float: left;
		    margin: 15px auto;
		    width: 100%;

		    @include media-breakpoint-up(md) { 
		    	margin: 30px auto;
		    }
		}
	}
}

.section--booking-summary {
	.btn {
		margin:20px auto;
	}

	small {
		clear:both;
		display:block;
		float:left;
		//margin-top:10px;
		margin-top:0;
		text-align:left;
		width:100%;
	}
}

.section--payment {
	@include media-breakpoint-up(md) {
		text-align:right;
	}

	.xs-container .text-medium { text-align:center; }

	.payment-content, .summary {
		@include media-breakpoint-up(md) {
			display:inline-block;
			vertical-align:top;
			width:49%;
		}

		@include media-breakpoint-up(xl) {
			width:40%;
		}
	}

	.payment-content {
		text-align:center;

		@include media-breakpoint-up(md) {
			margin-top:10px;
			padding-right:20px;
		}

		@include media-breakpoint-up(xl) {
			width:58%;
		}
	}
}