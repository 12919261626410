.technical-details {
	margin-bottom:20px;
	text-align:center;

	@include media-breakpoint-up(md) { 
		margin-bottom:60px;
	}

	&__navigation {
		background:#eee;
		border-radius:40px;
		display:inline-block;
		margin:15px auto 30px;
		padding:0;
		position:relative;
		max-width:calc(100% - 75px);

		@include media-breakpoint-up(md) { 
			margin:30px auto 60px;
		}

		&__item {
			display:none;
			vertical-align:middle;

			@include media-breakpoint-up(md) {
				display:inline-block;
			}
		}

		&__link {
			border-radius:40px;
			color:$black;
			display:block;
			padding:15px;
			position:relative;
			z-index:1;

			@include media-breakpoint-up(lg) {
				padding:15px 25px;
			}

			&:hover {
				text-decoration:none;
			}
		}

		&__item--current {
			display:inline-block;

			a {
				@include media-breakpoint-up(md) {
					//background:$purple-light;
					color:$white;
					transition-delay:.1s;
				}
			}
		}

		&__item--init {
			a {
				@include media-breakpoint-up(md) {
					background:$purple-light;
					color:$white;
				}	
			}
		}

		.magic-line {
			border-radius: 40px;
			background: $purple-light;
			display:none;
			margin:0;
			left: 0;
			list-style-type:none;
			padding:0;
			position: absolute;
		    top: 0;		    
		    transition: all .4s ease-in-out;
		    width: 100px;
		    //z-index: 100;

		    @include media-breakpoint-up(md) { 
		    	display:block;
		    }
		}
	}
	
	&__slide {
		margin:0 auto;
		max-width:1080px;
		position:relative;
	}

	&__slide__item {
		display:none;
		opacity:0;
		transition:opacity .5s ease-in-out;

		&.active {
			display:block;
			opacity:1;
		}
	}

	&__figure {
		box-sizing:border-box;
		margin-bottom:10px;

		@include media-breakpoint-up(md) {
			display:inline-block;
			margin-bottom:0;
			margin-top:10px;
			padding:0 0 0 30px;
			vertical-align:middle;
			width:45%;
		}

		img {
			display:block;
			height:auto;
			width:100%;
		}
	}

	&__info {
		box-sizing:border-box;
		padding:0 30px;
		text-align:left;

		@include media-breakpoint-up(md) {
			display:inline-block;
			padding-left:30px;
			padding-right:60px;
			vertical-align:middle;
			width:53%;
		}

		@include media-breakpoint-up(xl) {
			padding-left:60px;
		}
	}

	&__info--full-width {
		width:100%;
	}

	&__list {
		margin:0 auto;
		padding:0;
	}

	&__list--margin {
		margin:0px auto 25px;
	}

	&__separator {
		@include vw-font-size(18px);
		@include vw-line-height(24px);
		background:#f0f0f0;
		display:block;
		font-family:$font-quicksand;
		padding:10px 15px;
		width:100%;
		
		@include media-breakpoint-up(md) { 
			@include vw-font-size(24px);
			@include vw-line-height(34px);
		}
	}

	&__list__item {
		@include vw-font-size(14px);
		@include vw-line-height(22px);
		border-bottom:1px solid #d8d8d8;
		display:block;
	    padding:10px 15px;
	    width:100%;

	    &:not(.technical-details__list__item--block) {
	    	align-items:stretch;
			display:flex;
		    flex-wrap:wrap;
		    flex-direction:row;
		    justify-content:space-between;
		}

	    @include media-breakpoint-up(md) {
			@include vw-font-size(15px);
			@include vw-line-height(25px);
			flex-wrap:nowrap;
		}

		span {
			flex-basis:1;
		}

		strong {
			flex-basis:1;
		}
	}

	.swiper-container {
		overflow:visible;
	}

	.swiper-button-next, .swiper-button-prev {
		display:block;
		top:-55px;

		&:focus { outline:none; }

		@include media-breakpoint-up(md) {
			display:none;
		}
	}
}