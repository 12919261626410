// ==========================================================================
// Medium Title
// ==========================================================================


// 1.Config


// 2.Base
.title-medium {
	@include vw-font-size(27px);
	@include vw-line-height(34px);
	color:$black;
	display:block;
	font-family:$font-quicksand;
	font-weight:bold;
	margin:10px auto 10px;
	
	@include media-breakpoint-up(md) { 
		@include vw-font-size(34px);
		@include vw-line-height(40px);
	}

	&.white {
		color:$white;
	}

	&.purple-light {
		color:$purple-light;
	}

	&.no-margin-bottom {
		margin-bottom:0;
	}

	&.margin-bottom {
		margin-bottom:30px;
	}
}