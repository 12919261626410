// ==========================================================================
// Top Section - min
// ==========================================================================


// 1.Config


// 2.Base

.top-header-min {
	margin-top:90px;
	position:relative;

	@include media-breakpoint-up(md) { 
		height:440px;
	}

	&__content {
		display:flex;
	    height: inherit;
	    margin:0 auto;
	    max-width:1240px;
	    padding:0 15px;
	    position: relative;
	    z-index: 1;
	}
    
	&__bg {
        z-index:-1;

        &:before {
            background-blend-mode: multiply;
            background-image:-webkit-gradient(linear, left top, left bottom, from(rgba(95, 88, 88, 0.25)), to(rgba(0, 0, 0, 0)));
            background-image:linear-gradient(to bottom, rgba(95, 88, 88, 0.25), rgba(0, 0, 0, 0));
            content:'';
            display:block;
            height:100%;
            left:0;
            position:absolute;
            top:0;
            width:100%;
            z-index:1;
        }

        img {
        	display:block;
        	height:auto;
        	width:100%;

        	@include media-breakpoint-up(md) { 
	            position:absolute;
	            height:100%;
	            object-fit: cover;
	            top: 0;     
	            right: 0;
	        }
        }
    }

    &__box {
    	align-self:flex-end;
    	background:rgba($white, .9);
    	border-radius:10px 10px 0 0;
        bottom:0;
        left:10px;
    	margin:0 auto;
    	padding: 20px 15px;
        position:absolute;
        right:10px;
    	text-align:center;
    	

    	@include media-breakpoint-up(md) { 
            left:auto;
    		padding: 45px 20px;
            position:relative;
            right:auto;
            width:100%;
    	}

    	> * {
    		max-width:640px;
    	}
    }
}