// ==========================================================================
// Footer
// ==========================================================================

// 1.Config




// 2.Base

.footer {
	position:relative;
	//z-index: z('footer');
	width:100%;

	.container, .container-fluid {
		position:relative;
	}

	&__purple-light {
		background:$purple-light;
		position:relative;
		padding:30px 0;

		@include media-breakpoint-up(md) { 
			padding:50px 0 20px;
		}

		&:before {
			background:$white;
			content:'';
			display:block;
			height:10px;
			left:0;
			opacity:.5;
			position:absolute;
			top:0;
			width:100%;
		}
	}

	&__white {
		background:$white;
		padding:10px 0;

		@include media-breakpoint-up(md) { 
			padding:20px 0;
		}
	}

	&__info {
		color:$white;
		text-align:center;

		@include media-breakpoint-up(md) { 
			display:inline-block;
			padding-right:25px;
			text-align:left;
			vertical-align:top;
		}

		@include media-breakpoint-up(lg) { 
			max-width:395px;
		}
	}

	&__logo {
		@extend %logo-footer;
        display:block;
        height:48px;
        margin:0 auto 20px;
        width:125px;

        @include media-breakpoint-up(md) { 
        	margin:0 0 20px;
        }
	}

	.share, .share-list {
		text-align:center;

		@include media-breakpoint-up(md) { 
			text-align:left;
		}

		.share__link--white {
			color:$white;

			&:hover {
				color:$white;
				opacity:.5;
			}
		}
	}

	&__content-menu {
		@include media-breakpoint-up(md) { 
			display:inline-block;
			overflow:hidden;
			vertical-align:top;
			width:100%;
		}

		@include media-breakpoint-up(lg) {
			max-width:calc(100% - 405px);
		}
	}

	&__menu {
		margin-top:10px;

		@include media-breakpoint-up(md) { 
			margin-top:20px;			
			width:100%;
		}

		@include media-breakpoint-up(lg) { 
			float:right;
			margin-top:0;
			max-width:600px;
		}
	
		&__block {
			@include media-breakpoint-up(sm) { 
				display:block;
				float:left;
				padding:0 30px 0 0;
				width:33.3%;

				&:last-child { padding:0; }
			}
		}

		&__link {
			@include vw-font-size(13px);
			@include vw-line-height(20px);
			color:$white;
			display:block;
			font-family:$font-open-sans;
			margin-bottom:10px;
			text-align:center;

			&:hover {
				color:$white;
				opacity:.5;
				text-decoration:none;
			}

			@include media-breakpoint-up(md) { 
				margin-bottom:12px;
				text-align:left;
			}
		}

		&__link--active {
			opacity:.5;
		}
	}

	&__auto88 {
		@include vw-font-size(11px);
		color:$white;
		display:block;
		clear:both;
		text-align:center;

		@include media-breakpoint-up(md) { 
			bottom:20px;
			position:absolute;
			right:20px;
		}

		img {
			display:inline-block;
			height:auto;
			margin-left:15px;
			vertical-align:bottom;
			width:60px;

			@include media-breakpoint-up(md) { 
				//width:92px;
				width:77px;
			}
		}
	}

	&__copyright {
		@include vw-font-size(13px);
		@include vw-line-height(18px);
		color:#7e7d7d;
		font-family:$font-open-sans;
		text-align:center;

		@include media-breakpoint-up(md) { 
			text-align:left;
		}
	}

	.chat-button {
		position:absolute;
		right:20px;
		top:-35px;
		z-index:1;

		@include media-breakpoint-up(lg) { 
			top:-65px;
		}
	}
}