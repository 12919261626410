.numeric-book-steps-renting {
	align-items:center;
	display:flex;
	flex-wrap:nowrap;
	margin-bottom:30px;
	margin-left:0;
	padding-left:0;

	@include media-breakpoint-up(md) { 
		margin-bottom:57px;
	}

	li {
		align-items:center;
		display:flex;
		flex-wrap:nowrap;
		cursor:pointer;

		span {
			@include vw-font-size(19px);
			border:3px solid rgba(151, 151, 151, 0.35);
			border-radius:50%;
			color:$black;
			height:35px;
			line-height:29px;
			opacity:.35;
			text-align:center;
			width:35px;
		}

		&.active {
			span {
				border-color:$purple-light;
				opacity:1;
			}
		}

		&:after {
			background-color: rgba(151, 151, 151, 0.35);
			content:'';
			display:inline-block;
			height:1px;
			width:82px;
		}

		&:last-child:after { display:none; }

		span { display:block; }
		a {
			&.no-hover {
				cursor:default;
			}

			&:hover {
				text-decoration:none;
			}
		}

	}
}