.post {
	text-align:center;

	&__content-top {
		margin:0 auto;
		max-width:660px;
	}

	&__title {
		@include vw-font-size(27px);
		@include vw-line-height(37px);
		display:block;
		font-family:$font-quicksand;
		font-weight:bold;
		margin:0 auto 20px;
		
		@include media-breakpoint-up(md) { 
			@include vw-font-size(40px);
			@include vw-line-height(46px);
		}
	}

	&__short-description {
		@include vw-font-size(18px);
		@include vw-line-height(34px);
		display:block;
		font-family:$font-quicksand;
		margin:0 auto;
		
		@include media-breakpoint-up(md) { 
			@include vw-font-size(27px);
			@include vw-line-height(37px);
		}
	}

	&__img-full-width {
		display:block;
		height:auto;
		margin:20px auto;
		max-width:1030px;
		width:100%;

		@include media-breakpoint-up(md) { 
			margin:40px auto;
		}
	}

	&__padding-content {
		@include vw-font-size(15px);
		@include vw-line-height(22px);
		color:$color-base;
		display:block;
		font-family:$font-open-sans;
		margin:0 auto;
		max-width:660px;
		text-align:left;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(16px);
			@include vw-line-height(24px);
		}

		a {
			color:$purple-light;
			text-decoration:underline;

			&:hover {
				color:$purple;
			}
		}


		> * {
			max-width:100%;
		}


		h1,h2,h3,h4,h5,h6 { font-weight:bold; }

		h1 {
			@include vw-font-size(24px);
			@include vw-line-height(27px);

			@include media-breakpoint-up(md) { 
				@include vw-font-size(40px);
				@include vw-line-height(48px);
			}
		}

		h2 {
			@include vw-font-size(20px);
			@include vw-line-height(26px);

			@include media-breakpoint-up(md) { 
				@include vw-font-size(32px);
				@include vw-line-height(36px);
			}
		}

		h3 {
			@include vw-font-size(18px);
			@include vw-line-height(24px);

			@include media-breakpoint-up(md) { 
				@include vw-font-size(24px);
				@include vw-line-height(32px);
			}
		}

		h4 {
			@include vw-font-size(17px);
			@include vw-line-height(24px);

			@include media-breakpoint-up(md) { 
				@include vw-font-size(18px);
				@include vw-line-height(24px);
			}
		}

		h5 {
			@include vw-font-size(16px);
			@include vw-line-height(24px);

			@include media-breakpoint-up(md) { 
				@include vw-font-size(16px);
				@include vw-line-height(24px);
			}
		}

		h6 {
			@include vw-font-size(15px);
			@include vw-line-height(22px);
			text-transform:uppercase;

			@include media-breakpoint-up(md) { 
				@include vw-font-size(14px);
				@include vw-line-height(24px);
			}
		}

		blockquote {
			display: block;
			font-style:italic;
		    margin: 20px;
		}

		pre {
			background:rgba($red, .2);
			display:block;
			padding:15px;
			margin:20px 0;
		}

		img {
			display:block;
			height:auto;
			margin:20px auto;
			max-height:100%;
			max-width:100%;
			width:auto;
		}

		iframe {
			display:block;
			max-height:100%;
			margin:0 auto 15px;
			padding:0 20px;	
			width:100%;

			@media (max-width:420px) {
				height:auto !important;
			}
		}
	}

	.share {
		border-bottom:1px dashed rgba($black, .2);
		margin-bottom:20px;
		max-width:716px;
    	padding: 0 20px 15px;

    	@include media-breakpoint-up(sm) {
    		margin-bottom:40px;
    	}

		&__item {
			border:0;
			color:$black;
			margin-bottom:0;
			opacity:.75;
			padding:0;

			&:hover { opacity:1; }

			&:before {
				display:inline-block;
				line-height:15px;
				vertical-align:middle;
			}

			&.ico-linkedin:before {
				@include vw-font-size(14px);
				min-width:20px;
			}

		}
	}

}