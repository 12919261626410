.search {

	&__top {
		@include media-breakpoint-up(xl) {
			padding:0 5px;
		}
	}

	&__search-bar {
		font-family:$font-quicksand;

		.form-control-ico-search {
			margin:0;
		}

		@include media-breakpoint-up(md) { 
			display:inline-block;
			width:calc(100% - 250px);
		}
	}

	&__order {
		@include media-breakpoint-up(md) { 
			float:right;
			min-width:150px;
		}
	}

	&__info {
		margin:20px auto 40px;
		max-width:780px;
		padding: 0 15px;
		text-align:center;

		@include media-breakpoint-up(md) { 
			margin: 80px auto;
			padding: 0;
	    }	    
	}

	.form-control-ico-search {
		max-width:100%;
	}
}