.block-steps-assessment {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	padding: 0;
	margin: 0 0 30px;
	list-style: none;

	@include media-breakpoint-up(md) { 
		margin-bottom: 60px;
	}

	li {
		display: block;
		width: 20%;
		height: 5px;
		margin: 0 3px;
		background: #f7f7f7;
		cursor: pointer;

		&.active {
			background: $purple-light;
		}
	}
}
