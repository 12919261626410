.search-no-results {
	display:block;
	text-align:center;
	width:100%;

	&__content {
		margin:0 auto;
		max-width:720px;
		padding:0 15px;
	}

	&__title {
		margin-bottom:20px;
		margin-top:20px;
	}

	&__description {
		margin:0 auto 40px;
		max-width:520px;
	}

	.btn {
		margin-bottom:45px;
	}

	&__highlight {
		margin-bottom:30px;
	}

	&__highlight--big {
		@include vw-font-size(19px);
		@include vw-line-height(24px);
		color:$black;
		display:block;
		font-family:$font-quicksand;
		font-weight:bold;
		
		@include media-breakpoint-up(md) { 
			@include vw-font-size(28px);
			@include vw-line-height(35px);
		}
	}

	.car-list {
		@include media-breakpoint-up(sm) {
			justify-content:center;
		}

		.car-list__item {
			width:100%;

			@include media-breakpoint-up(sm) {
				display:inline-block;
				margin-bottom:30px;
				max-width:318px;
				width:47%;
			}
		}
	}

	.car-list-other {
		@include media-breakpoint-up(sm) {
			justify-content:center;
		}

		.car-list__item {
			width:100%;

			@include media-breakpoint-up(sm) {
				display:inline-block;
				margin-bottom:30px;
				max-width:318px;
				width:47%;
			}
		}
	}
}