// ==========================================================================
// Header
// ==========================================================================

// 1.Config

// 2.Base

.header--purple {
    background:rgba($white, .95);

    @include media-breakpoint-up(md) {
        background:none;
    }

    .header__logo {
        @extend %logo-color;
        height:25px;
    }

    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        background-color:$purple-light;
    }

    > .container-fluid {
        height:$headerHeight;
    }

    .menu__avatar--bg {
        &.icon-white { display:block; }
        &.icon-default { display:none; }

        @include media-breakpoint-up(md) {
            &.icon-white { display:none; }
            &.icon-default { display:block; }
        }
    }
}

.navbar.navbar--purple {
    @include media-breakpoint-up(md) {
        background:rgba($white, .95);
        height:$headerHeight;

        .menu {
            height:$headerHeight;
            &:before {
                top:$headerHeight;
            }
        }

        .menu__item {
            line-height:$headerHeight;   
        }

        .menu__item--covid {
            max-height:60px;
        }

        .menu__link {
            @include vw-font-size(15px);
            //color:$purple-light;
            color:$indigo;

            @include media-breakpoint-up(md) {
                @include vw-font-size(12px);
            }
    
            @media (min-width:1140px) {
                @include vw-font-size(14px);
            }

            @include media-breakpoint-up(lg) {
                @include vw-font-size(15px);
            }

            &:after {
                border-color:$indigo !important;
            }

            &:hover {
                &:after { border-color:$purple-light !important; }
            }

            &:focus {
                color:$purple-light;
            }

            &.menu__link--active:after { border-color:$purple-light !important; }
        }

        .show-submenu .menu__link {
            color:$white !important;

            &:after { border-color:$white !important; }
        }

        .submenu {
        //    top:$headerHeight;
            top:35px;
        }

        .submenu__link {
            padding-bottom:10px;
            /*@include vw-font-size(14px);
            color:$white;

            &:focus {
                color:$white;
            }*/
        }
    }
}