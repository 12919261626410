// ==========================================================================
// Forms
// ==========================================================================


// 1.Config
$bgInput: #f7f7f7;
$bgErrorForm: #DB0000;
$borderFormColor: #f7f7f7;

// 2.Base
.form {
	overflow:hidden;
	text-align:left;

	.scanner-link {
		display:inline-block;
		margin:20px auto;

		img {
			border:1px solid $bgInput;
			border-radius:10px;
		}
	}

	&.form--box, .form--box {
		background:#f7f7f7;
		border-radius:8px;
		margin-bottom:20px;
		padding:30px 15px 10px;

		@include media-breakpoint-up(xl) { 
			padding:40px 70px 10px;
		}
	}

	&.form--margin {
		margin-top:20px;

		@include media-breakpoint-up(lg) { 
			margin-top:40px;
		}
	}

	&.form--center { text-align:center; }

	&.form--contact {
		margin:0 auto;
		max-width:580px;
	}

	.form-control {
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		text-align:left !important;
	}

	.purple-light { color:$purple-light; }

	.form-control, .form-textarea {
		@include vw-font-size(16px);
		@include vw-line-height(39px);
		@include vw-height(40px);
		background:$bgInput;
		border:1px solid $bgInput;
		border-radius:0;
		color:$color-base;
		display:block;
		padding:0 15px;
		width:100%;

		&::placeholder { color: $color-base; }
		&:-ms-input-placeholder { color: $color-base; }
		&::-ms-input-placeholder { color: $color-base; }

		&:focus:not(:read-only), &:active:not(:read-only) { outline:0;border-color:rgba($purple-light, .5); }

		&:read-only { color:#b0b0b0; }

		@include media-breakpoint-up(md) {
			@include vw-height(50px);
		}

		@include media-breakpoint-up(lg) {
			border-width:2px;
		}

		@include media-breakpoint-up(xl) {
			@include vw-font-size(18px);
			@include vw-line-height(48px);
		}

		&.error { border-color:$bgErrorForm; }
	}

	.form-control--tagify {
		@include vw-font-size(13px);
		background-color:$bgInput;
		background-image:url(../svg/search.svg);
		background-position:center right 20px;
		background-repeat:no-repeat;
		background-size:24px 24px;
		border:1px solid #dbdbdb;
		border-radius:25px;
		color:$color-base;
		font-family:$font-open-sans;
		font-weight:300;
		min-height:42px;
		padding:3px 33px 3px 15px;
		width:100%;

		@include media-breakpoint-up(lg) {
			@include vw-font-size(18px);
			background-image:none;
			min-height:50px;
			padding:5px 33px 5px 15px;
		}

		&.tagify--focus {
			//background-image:url(../svg/search-color.svg);
		}

		@include media-breakpoint-up(lg) {
			&:after {
			    content: 'Buscar';
			    color: #8f61ff;
			    background: #cdcdef;
			    position: absolute;
			    right: 7px;
			    top: 7px;
			    border-radius: 25px;
			    min-width: 113px;
			    text-align: right;
			    font-weight: bold;
			    padding: 8px 15px 8px 8px;
			    font-size: 16px;
			    z-index: 0;
			}

			&:before {
			    background-image:url(../svg/search.svg);
			    background-position:center;
			    background-repeat:no-repeat;
			    background-size:contain;
			    content: '';
			    height:22px;
			    position:absolute;
			    right:85px;
			    top:12px;
			    width:22px;
			    z-index:1;
			}
		}
	}

	&.form--center {
		text-align:center;
	}

	.form-textarea {
		box-sizing:border-box;
		height:110px;
		resize:none;
		text-shadow:none;
		width:100%;
		-webkit-appearance: none;
		
		&.purple-light {
			border-color:$purple-light;
			border-width:3px;
		}
	}

	.form-control-inline {
		display:inline-block;
		overflow:hidden;
		text-overflow:ellipsis;
		vertical-align:middle;
		white-space:nowrap;
		width:100%;

		&.filename {
			color: $gray;
			max-width:calc(100% - 55px);

			@include media-breakpoint-up(md) {
				max-width:calc(100% - 245px);
			}
		}

		@include media-breakpoint-up(md) {
			max-width:calc(100% - 245px);
		}
	}

	.form-group__checkbox-block {
		@include media-breakpoint-up(md) {
			align-items:baseline;
			display:flex;
			flex-wrap:nowrap;

			> .form-label--legal {
				max-width:280px;
			}
		}

		&.margin-bottom {
			margin-bottom:20px;

			@include media-breakpoint-up(sm) {
				margin-bottom:60px;
			}
		}

		.form-label--legal, .form-label-text {
			@include vw-font-size(13px);
		}

		.form-group { margin-bottom:5px; }
	}

	.form-group__checkbox-block--wrap {
		@include media-breakpoint-up(md) {
			flex-wrap:wrap;
		}

		> * {
			max-width:100% !important;
			width:100%;
		}
	}

	.small-checkbox-text {
		@include vw-font-size(13px);
		margin-bottom:20px !important;

		p { margin:0 auto; }
	}

	.form-group--tagify {
		margin:15px auto 0;
		max-width:550px;
		min-height:50px;
		text-align:left;

		.form-control--tagify {
			display:inline-flex;
			font-weight:400;
			max-width:calc(100% - 65px);

			@include media-breakpoint-up(sm) {
				max-width:calc(100% - 85px);
			}
		}

		.form-control-submit {
			@include vw-line-height(32px);
			cursor:pointer;
			display:inline-block !important;
			font-family:$font-quicksand;
			font-weight:500;
			margin-left:5px;
			min-height:42px;
			padding:0 10px 4px;
			vertical-align:top;

			@include media-breakpoint-up(sm) {
				padding:0 20px 4px;
			}

			@include media-breakpoint-up(lg) {
				@include vw-font-size(21px);
				@include vw-line-height(40px);
				min-height:50px;
			}
		}
	}

	.form-group {
		clear:both;
		display:block;
		margin-bottom:10px;
		position:relative;
		width:100%;

		@include media-breakpoint-up(md) {
			margin-bottom:20px;

			&.form-group--small-margin { margin-bottom:7px; }
		}

		&.error,
		&.form-group--error {
	    	input[type="checkbox"] + .form-label--checkbox .form-control--checkbox,
	    	input[type="radio"] + .form-label--radio .form-control--radio {
	    		background:rgba($bgErrorForm,.15);
	    	}

	    	.form-control {
	    		border-color:#DB0000;
	    	}

	    	.g-recaptcha > div {
				border:1px solid #DB0000;
			}
	    }

	    input[type="checkbox"].error + .form-label--checkbox .form-control--checkbox,
	    input[type="radio"].error + .form-label--radio .form-control--radio {
    		background:rgba($bgErrorForm,.15);
    	}

    	&__img {
    		display:block;
    		height:auto;
    		margin:25px auto;
    		width:100%;
    	}
	}

	.form-group--half {
		@include media-breakpoint-up(md) {
			clear:none;
			float:left;
			padding:0 7px;
			width:50%;

			&:nth-child(even) {
				padding-right:0;
			}

			&:nth-child(odd) {
				padding-left:0;
			}
		}
	}

	.form-group--half-left {
		@include media-breakpoint-up(md) {
			padding-right:7px !important;
			padding-left:0;
		}
	}

	.form-group--half-right {
		@include media-breakpoint-up(md) {
			padding-left:7px !important;
			padding-right:0;
		}
	}

	.form-group--half-xl {
		@include media-breakpoint-up(md) {
			clear:none;
			float:left;
			padding:0 7px 0 0;
			width:60%;
		}
	}

	.form-group--half-xs {
		@include media-breakpoint-up(md) {
			clear:none;
			float:left;
			padding:0 0 0 7px;
			width:40%;
		}
	}

	.form-group--full {
		width:100%;
	}

	.form-group--three {
		clear:none;
		float:left;
		padding:0 7px 0 0;
		width:33.3%;

		&.last { padding-right:0; }
	}

	.form-group--center {
		text-align:center;
	}

	.form-group--submit {
		margin-top:20px;
	}

	.form-group--multi-btn {
		.btn {
	        margin:5px 3px 5px;
	        min-width:0;

	        @include media-breakpoint-up(xl) { 
	            min-width:220px;
	        }
		}
	}

	.form-group--left { text-align:left; }

	.form-group--clear {
		clear:both;
		float:left;
		width:100%;
	}

	.form-group--separator {
		border-top:1px solid $bgInput;
		margin-top:20px;
		overflow:hidden;
		padding-top:20px;
		width:100%;

		@include media-breakpoint-up(md) {
			margin-top:30px;
			padding-top:30px;
		}

		@include media-breakpoint-up(lg) {
			border-width:2px;
		}
	}

	.form-group--margin-top {
		margin-top:20px;

		@include media-breakpoint-up(md) {
			margin-top:30px;
		}
	}

	.form-group--margin-bottom {
		margin-bottom:0;
	}

	.form-group--margin-bottom-xs {
		margin-bottom:30px;
	}

	.form-group--no-margin-bottom {
		margin-bottom:0;
	}

	button.ico[type="submit"] {
		@include vw-font-size(18px);
		@include vw-line-height(30px);
		border:0;
		cursor:pointer;
	}

	input[type="checkbox"], input[type="radio"], input[type="file"] {
		left:18px;
		opacity:0;
		position:absolute;
		top:2px;
	}

	.form-label--file {
		@include vw-font-size(14px);
		background:#eee;
		border-radius:8px;
		cursor:pointer;
		display:inline-block;
		margin:0 auto;
		padding:5px 7px;
		transition:$transition-base;
		width:100%;

		&.ico-check:before { display:none; }

		.uploaded { display:none; }

		&.uploaded {
			background:$green;
			color:$white;

			&.ico-check:before { display:inline-block; }

			.upload { display:none; }
			.uploaded { display:inline-block; }
		}

		&.uploaded + .filename:not(.form-control) {
			display:inline-block;
		}

		&:hover {
			background:$purple;
			color:$white;
		}

		.btn {
			@include media-breakpoint-up(md) {
				margin-bottom:0;
				padding:8px 20px 9px;
			}
		}

		&.btn-purple-light {
			background:$purple-light;
    		font-size: 16px;
    		line-height: 21px;
    		margin:0 auto;
    		padding:10px 15px;

    		&:hover {
    			background:$purple;
    		}

    		&.btn-bg-gray {
    			background:$bgInput;
    			color:$purple;

    			&:hover {
    				background:$purple;
    				color:$white;
    			}
    		}
		}
	}

	.form-label--legal { text-align:left; }
	.form-label--legal--xs { font-size:13px !important; }

	.form-label--file-inline {
		margin:0 auto;
		float:right;
		height:40px;
		overflow:hidden;
		padding:0;
		vertical-align:middle;
		width:50px;

		@include media-breakpoint-up(md) {
			height:auto;
			float:none;
			width:auto;
		}

		.upload {
			background-color:$black;
			background-image:url(#{$url-statics}/svg/clip.svg);
		    background-position:center;
		    background-repeat:no-repeat;
		    background-size:27px;
		    color:transparent;
		    width:100%;

		    @include media-breakpoint-up(md) {
				background:$white;
				color:$black;
				height:auto;
				float:none;
			}
		}
	}

	.filename:not(.form-control) {
		@include vw-font-size(12px);
		display:none;
		font-style:italic;
		margin-top:5px;
	    overflow: hidden;
	    overflow-wrap: break-word;
	    text-align:left;	
	   	width:100%;
	}

	input:checked + label .form-control--checkbox:before,
	input:checked + label .form-control--radio:before {
		opacity:1;
		transform:translate(-50%, -50%) scale(1);
	}

	input:checked + .form-label--button .form-label-text {
		border-color:$purple-light;
	}

	.form-control--checkbox {
		background:$bgInput;
		border:2px solid $purple-light;
		border-radius:4px;
		display:inline-block;
		height:20px;
		margin-right:7px;
		position:relative;
		width:20px;
		vertical-align:middle;

		&:before {
			@include vw-font-size(22px);
			color:$purple;
			left:50%;
			margin:0 auto;
			opacity:0;
			position:absolute;
			top:50%;
			transition:$transition-base;
			transform:translate(-50%, -50%) scale(.5);
			margin-top:-4px;
			margin-left:3px;
		}

		&.error {
			border-color:$bgErrorForm;
		}
	}

	.form-label--checkbox-purple {
		.form-label-text {
			@include vw-font-size(16px);
			line-height:27px;
			width:calc(100% - 40px);

			@include media-breakpoint-up(md) { 
				@include vw-font-size(21px);
			}

			&.text {
				@include vw-font-size(16px);
			}
		}
	}

	.form-control--checkbox-purple {
		border:3px solid $purple-light;
		height:22px;
		width:22px;

		&:before {
			color:$purple;
		}
	}

	.form-group--h {
		display:none !important;
	}

	.form-label--checkbox-xl {
		.form-control--checkbox {
			display:block;
			height:40px;
			margin:20px auto;
			width:40px;
		
			&:before {
				@include vw-font-size(42px);
				color:$green;
			}
		}

		.form-label-text {
			@include vw-font-size(18px);
			@include vw-line-height(27px);
			color:$color-base;
			
			span {
				display:block;
				margin:5px auto 20px;
				max-width:290px;
			}
		}
	}

	.form-control--radio {
		background:$bgInput;
		border:1px solid $borderFormColor;
		border-radius:50%;
		cursor:pointer;
		display:inline-block;
		height:20px;
		margin-right:7px;
		position:relative;
		width:20px;
		vertical-align:middle;

		&:before {
			@include vw-font-size(15px);
			background:$purple;
			border-radius:50%;
			content:'';
			display:block;
			height:10px;
			left:50%;
			margin:0 auto;
			opacity:0;
			position:absolute;
			top:50%;
			transition:$transition-base;
			transform:translate(-50%, -50%);
			width:10px;
		}

		&.error {
			border-color:$bgErrorForm;
		}
	}

	.form-label--button {
		.form-control--radio {
			display:none;
		}

		.form-label-text {
			border: 1px solid #b0b0b0;
			border-radius: 5px;
			display:block;
			padding: 10px 15px;
			text-align: center;
			font-size: 15px;
			width:100%;
			
			@include media-breakpoint-up(sm) { 
				min-width:95px;
			}
		}
	}

	.form-group-content-buttons {
		align-items:center;
		display:flex;
		flex-wrap:nowrap;
		justify-content:space-around;

		.form-group--inline {
			margin-right:5px;
		}

		.min-width {
			width:40%;

			@include media-breakpoint-up(sm) { 
				min-width:155px;
			}
		}

		.error .form-label-text {
			border-color:$bgErrorForm;
		}
	}

	.form-label-text {
		@include vw-font-size(16px);
		@include vw-line-height(24px);
		color:$color-base;
		display: inline-block;
		margin-top: 0;	    
		// text-transform:lowercase;
	    vertical-align: middle;
	    width: calc(100% - 35px);

	    &:first-letter {
	    	text-transform:capitalize;
	    }

	    a { color:$color-base; }
	}

	.form-label--checkbox, .form-label--radio {
		@include vw-font-size(16px);
		cursor:pointer;
		width:100%;

		a {
			text-decoration:underline;
			&:hover { opacity:.85; }
		}

		&.disabled {
			.form-label-text { color:#b0b0b0; }
			.form-control--radio { border-color:#b0b0b0; }
		}

		&.big {
			@include media-breakpoint-up(md) {
				.form-control--radio, .form-control--label {
					height:36px;
					width:36px;

					&:before {
						height:20px;
						width:20px;
					}
				}

				.form-label-text {
					@include vw-font-size(18px);
					font-weight:600;
					width:calc(100% - 50px);
				}
			}
		}

		&.purple {
			@include media-breakpoint-up(md) {
				/*.form-control--radio, .form-control--label {
					height:26px;
					width:26px;
				}

				.form-label-text {
					width:calc(100% - 40px);
				}*/
			}

			.form-control--radio {
				border-color:$purple-light;

				@include media-breakpoint-up(md) {
					border-width:2px;
				}
			}

			.form-label-text {
				@include vw-font-size(13px);	
			}
		}
	}

	.form-label--selection {
		.bg-selection {
			background:url(#{$url-statics}/svg/selection.svg) center no-repeat;
			background-size:contain;
			display:inline-block;
			height:30px;
			margin-left:5px;
			vertical-align:middle;
			width:30px;
		}
	}

	.input-message--error,
	.error-msg {
		@include vw-font-size(12px);
		font-weight:300;
		text-align:left;
	}

	.input-message--error {
		background:none;
		color:$bgErrorForm;
		display:none;
		margin-bottom:0;
		padding:5px 18px;

		@include media-breakpoint-up(md) { 
			bottom:-24px;
			//display:block;
			left:0;
			position:absolute;
		}
	}

	.form-group--textarea {		
		@include vw-height(100px);
		@include vw-line-height(21px);
		padding-top:15px;
		resize:none;

		.input-message--error {
		
		}		
	}

	.form-group--error .input-message--error { display: block; }

	.form-group--inline {
		display:inline-block;
		margin-right:20px;
		vertical-align:top;
		width:auto;

		@include media-breakpoint-up(md) { 
			margin-right:40px;
		}

		&:last-child { margin-right:0; }

		&.error {
	    	input[type="checkbox"] + .form-label--checkbox .form-control--checkbox,
	    	input[type="radio"] + .form-label--radio .form-control--radio {
	    		background:rgba($bgErrorForm,.15);
	    	}
	    }
	}

	.form-group--contact {
		display:inline-block;
		margin-right:-15px;
		min-width:125px;
		vertical-align:top;
		width:auto;

		&:last-child { margin-right:0; }

		.form-label--legal, .form-label-text {
			@include vw-font-size(14px);

			@include media-breakpoint-up(md) { 
				//@include vw-font-size(16px);
			}
		}
	}

	.form-label--small-margin-bottom {
		@include vw-font-size(15px);
		margin-bottom:10px;
	}

	.form-group--inline--register {
		display:inline-block;
		margin-right:0;
		vertical-align:top;
		width:49%;

		@include media-breakpoint-up(md) { 
			margin-right:0;
		}

		.form-label {
			padding-right:10px;
		}

		.form-label-text {
			@include vw-font-size(13px);
			padding-right:10px;

			/*@include media-breakpoint-up(md) { 
				@include vw-font-size(14px);
			}*/
		}
	}

	.form-group-register {
		@include vw-font-size(13px);
		@include vw-line-height(22px);
		margin-bottom:10px;

		.input-message--error { bottom:-15px; }
	}

	.form-text-small {
		@include vw-font-size(13px);
		@include vw-line-height(22px);
	}

	.btn {
		-webkit-appearance: none;
		cursor:pointer;

		&.open-mail-register {
			@media (max-width:430px) {
				font-size:12px;
			}
		}
	}

	.no-padding { padding:0; }

	.rangerslide-container {
		margin:0 auto 20px;
		position:relative;
		width:calc(100% - 20px);

		@include media-breakpoint-up(sm) {
			width:100%;
		}

		@include media-breakpoint-up(lg) {
			width:96%;
		}

		&.rangerslide--multiple {
			background:#EEE;

			.rangerslide {
				background:transparent;
			}
		}

		input[type="range"] {
			display:none !important;
		}

		.rangerslide {			
			appearance: none;
			background: #EEE;
			border-radius:2.5px;
			height: 5px;
			opacity: .95;
			outline: none;			
			transition: $transition-base;
			-webkit-appearance: none;
			width: 100%;

			&:focus, &:active {
				outline:0;
			}

			&:hover {
				opacity: 1;
			}

			@media screen and (-webkit-min-device-pixel-ratio:0) {
				//overflow:hidden;

				&::-webkit-slider-runnable-track {
					-webkit-appearance: none;
					color: #13bba4;
					margin-top: -1px;
				}

				&::-webkit-slider-thumb {
					border-radius:50%;
					-webkit-appearance: none;
					appearance: none;
					background:$white;
					border:5px solid $purple-light;
					cursor: pointer;
					height: 28px;
					position:relative;
					width: 28px;
					z-index:1;
				}
			}


			/** FF*/
			&:not(.multirange)::-moz-range-progress {
				background-color: rgba($purple-light, .4);
			}
			
			&::-moz-range-track {  
				background-color: #EEE;
			}

			&::-moz-range-thumb {
				border-radius:50%;
				background: $white;
				border:5px solid $purple-light;
				cursor: pointer;
				height: 28px;
				position:relative;
				width: 28px;
				transform: scale(.8); /* FF doesn't apply position it seems */
				z-index: 1;

				&:focus {
					outline:0;
				}
			}			
			
			/* IE*/
			&::-ms-fill-lower {
				background-color: rgba($purple-light, .4);
			}
			
			&::-ms-fill-upper {  
				background-color: rgba($purple-light, .4);
			}

			&[type=range]::-moz-focus-outer {
			    border: 0;
			}

			&.multirange {
				display: block;
				margin: 0;
				pointer-events: none;

				& + input.multirange {
					margin-top: -5px;
				}

				&::-webkit-slider-thumb {
					pointer-events: all;
					position:relative;
					z-index:1;
				}
				
				&::-moz-range-thumb {
					pointer-events: all;
				}

				&::-ms-thumb {
					pointer-events: all;
				}

				&::-moz-range-progress {
					background-color:none;
				}
			}
		}
	}

	.select2--white + .select2 {

		.select2-selection--single {
			background:transparent;
			border:1px solid $bgInput;
			border-radius:10px;
			color:$gray;
			font-family:$font-open-sans;
			padding:0 15px;

			@include media-breakpoint-up(lg) {
				border-width:2px;
			}
		}

		.select2-selection__arrow { border:0; }

		.select2-selection__rendered {
			@include vw-font-size(16px);
			margin-top:-2px;
			padding-left:0;

			@include media-breakpoint-up(xl) {
				@include vw-font-size(18px);
			}
		}

		&.select2-container--open {

			.select2-selection--single .select2-selection__arrow b {
				border-color:$black;
				border-width:2px;
			}
		}
	}

	.select2--white.bg-white + .select2 {
		.select2-selection--single {
			background:$white;
			color:$black;

			.select2-selection__placeholder {
				color:$gray;
			}
		}
	}

	.form-switch {

	}

	.form-group--avatar {
		margin:10px auto 0;
	}

	.form-file-preview, .form-file {
		display:inline-block;
		vertical-align:middle;
	}

	.form-file {
		@include vw-font-size(15px);
		@include vw-line-height(36px);
		min-height:40px;
		padding-bottom: 0;
		padding-top: 0;

		@include media-breakpoint-up(md) {
			min-width:160px;
		}
	}

	.form-file-preview {
		background:url(#{$url-statics}/svg/avatar.svg) center bottom -5px;
		background-color:rgba($bgInput,.4);
		background-repeat:no-repeat;
		background-size:contain;
		border-radius:50%;
		height:113px;
		overflow:hidden;
		margin-right:20px;
		width:113px;
	}
}

.form-table__content--no-border {
	> * {
		border:0 !important;
	}
}

.form-table {
	
	&__item {
		border:1px solid $bgInput;
		border-bottom:0;
		width:100%;


	    &:last-child {
	    	border-bottom:1px solid $bgInput;
	    }
	}

	&__content {
		width:100%;

		@include media-breakpoint-up(sm) {
			display:table;
			table-layout: fixed;
	  	}

	  	> * {
	  		display:block;
  			padding:10px 15px;
  			width:100%;

  			@include media-breakpoint-up(sm) {
  				border-right:1px solid $bgInput;
  				display:table-cell;
  				padding:10px 7px;
  				vertical-align:middle;
  				width:25%;
  			}

  			@include media-breakpoint-up(xl) {
  				padding:10px;
  			}

  			&:last-child { border-right:0; }
  		}
	}

	&__info {
		@include vw-font-size(14px);
		text-align:center;

		@include media-breakpoint-up(sm) {
			min-height:45px;
			text-align:left;
			width:40%;
		}

		@include media-breakpoint-up(md) {
			@include vw-font-size(15px);
		}

		.document-download, .red, .green, .pastel-blue, .orange, .pastel-blue-review {
			align-items: center;
			display:flex;
			flex-wrap:nowrap;
			justify-content: center;

			@include media-breakpoint-up(md) {
				justify-content:space-between;
			}

			&:after, i {
				background-position:center;
				background-repeat:no-repeat;
				background-size:contain;
				content:'';
				display:block;
				height:20px;
				margin-left:7px;
				width:25px;

				@include media-breakpoint-up(md) {
					height:25px;
					width:30px;
				}
			}
		}

		.red {
			//color:$red;

			span {
				max-width:calc(100% - 30px);
				
				@include media-breakpoint-up(md) {
					max-width:calc(100% - 35px);
				}
			}
			
			i { background-image:url(../svg/rejected.svg); }
			&:after { display:none; }
		}
		
		.green {
			//color:$green;

			span {
				max-width:calc(100% - 30px);
				
				@include media-breakpoint-up(md) {
					max-width:calc(100% - 35px);
				}
			}
			
			i { background-image:url(../svg/check.svg); }
			&:after { display:none; }
		}

		.pastel-blue {
			//color:$pastel-blue;

			span {
				max-width:calc(100% - 30px);
				
				@include media-breakpoint-up(md) {
					max-width:calc(100% - 35px);
				}
			}
			
			i { background-image:url(../svg/pending.svg); }
			&:after { display:none; }
		}

		.pastel-blue-review {
			//color:$pastel-blue;

			span {
				max-width:calc(100% - 30px);
				
				@include media-breakpoint-up(md) {
					max-width:calc(100% - 35px);
				}
			}
			
			i { background-image:url(../svg/in-review.svg); }
			&.yellow i { background-image:url(../svg/in-review-yellow.svg); }
			&:after { display:none; }
		}
		
		.orange {
			//color:$orange;
			
			span {
				max-width:calc(100% - 30px);
				
				@include media-breakpoint-up(md) {
					max-width:calc(100% - 35px);
				}
			}
			
			i { background-image:url(../svg/pending.svg); }
			&:after { display:none; }

		}

		.document-download {
			&:after { background-image:url(../svg/download.svg); }
		}

		a:hover { color:$purple; }
	}

	&__info--status {
		@include vw-font-size(13px);
		padding-bottom:10px;
	    padding-top: 0;

		@include media-breakpoint-up(sm) {
			padding-bottom:0;
			width:20%;
		}

		@include media-breakpoint-up(xl) {
			@include vw-font-size(14px);
		}
	}

	&__info--small {
		@include vw-font-size(13px);

		@include media-breakpoint-up(sm) {
			width:20%;
		}

		@include media-breakpoint-up(xl) {
			@include vw-font-size(14px);
		}
	}

	&__info--header {
		background:rgba($purple-light, .1);
		font-weight:bold;

		@include media-breakpoint-up(sm) {
			background:none;
			font-weight:normal;
		}
	}

	&__cta {
		margin:0;
		text-align:center;
		//width:100%;

		@include media-breakpoint-up(sm) {
			margin:0 auto;
			width:20%;
		//	width:200px;
		}

		

		.form-label--file {
			@include vw-font-size(13px);
			cursor:pointer;

			@include media-breakpoint-up(xl) {
				@include vw-font-size(14px);
			}	
		}

		.filters__link__option {
			position:relative;

			.btn { min-height:31px; }
		}
	}
}

.form-download-doc {
	@include media-breakpoint-up(md) {
		margin-bottom:30px;
	}
}