.box-renting {
	border:1px solid #979797;
	border-radius:8px;
	margin-bottom:15px;
	padding:15px;
	width:100%;

	@include media-breakpoint-up(xl) { 
		margin-top:0;
	}

	> div {
    	margin-left: auto;
    	margin-right: auto;
    	max-width: 440px;
	}

	&__top {
		align-items:flex-end;
		display:flex;
		flex-wrap:nowrap;

		span {
			@include vw-font-size(14px);
			margin-bottom:3px;	
		}
	}

	&__price {
		@include vw-font-size(16px);
		font-weight:bold;
		padding-right:19px;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(26px);
		}

		b {
			@include vw-font-size(40px);
			display:inline-block;
			font-weight:700;
			line-height:1;
			margin-right:-5px;
			vertical-align:text-bottom;

			@include media-breakpoint-up(md) { 
				@include vw-font-size(54px);
			}
		}
	}

	&__block {
		margin-top:20px;
	}

	&__months {

	}

	&__title {
		@include vw-font-size(16px);
		color:$purple;
		display:block;
		font-family:$font-quicksand;
		font-weight:bold;
		margin-bottom:10px;
	}

	&__list-button {
		display:inline-block;
		vertical-align:top;

		@include media-breakpoint-up(md) { 
			display:flex;
			flex-wrap:nowrap;
		}

		&__item {
			display:inline-block;
			margin-right:8px;
			position:relative;
			width:82px;

			&:last-child { margin-right:0; }
			
			input[type="radio"] {
				left:18px;
				opacity:0;
				position:absolute;
				top:2px;
			}

			input:checked + label .form-label-text {
				background:$purple-light;
				border-color:$purple-light;
				color:$white;
			}

			label {
				width:100%;

				.form-label-text {
					@include vw-font-size(13px);
					background:$white;
					border:1px solid #cacaca;
					border-radius:6px;
					cursor:pointer;
					font-weight:bold;
					padding:10px;
					text-align:center;
					transition:$transition-base;

					b {
						@include vw-font-size(18px);
						@include vw-line-height(14px);
						display:block;
						font-weight: 700;
					}
				}
			}
		}
	}

	&__km {
		.select2 {
			border:1px solid #cacaca;
			border-radius:6px;
			padding:1px;

			&.select2-container--open {
				border:2px solid #e6e6e6;
				padding:0;
			}
		}

		.select2-container .select2-selection--single .select2-selection__rendered {
			@include vw-font-size(14px);
			font-weight: bold;
			padding-left: 15px;
		    text-align: left;
		}
	}

	&__info {

	}

	&__list-avantages {
		margin:0;
		list-style-type:none;
		padding:0;

		li {
			@include vw-font-size(14px);
			display:inline-block;
			text-align:left;
			vertical-align:top;
			width:100%;

			@include media-breakpoint-up(sm) { 
				width:32%;
			}

			> * {
				display:inline-block;
				vertical-align:top;
			}

			&:before {
				color:$purple-light;
			}

			span {
				max-width:calc(100% - 20px);
				padding-left:5px;
				padding-right:10px;
				text-decoration:underline;
			}
		}
	}

	.form-group--submit {
		margin-bottom:0;
		margin-top:25px;
		margin-bottom:25px;
		text-align:center;

		> div {
			margin: 0 -5px;
		}

		.btn {
			width:100%;
			margin:0 5px;
		}
	}
}