// ==========================================================================
// Small Title
// ==========================================================================


// 1.Config


// 2.Base
.title-small {
	@include vw-font-size(23px);
	@include vw-line-height(26px);
	color:$black;
	display:block;
	font-family:$font-quicksand;
	font-weight:bold;
	margin:20px auto 10px;
	
	@include media-breakpoint-up(md) { 
		@include vw-font-size(36px);
		@include vw-line-height(38px);
	}

	&.white {
		color:$white;
	}

	&.purple-light {
		color:$purple-light;
	}

	&.no-margin-bottom {
		margin-bottom:0;
	}

	&.black {
		color:$color-base;
	}

	&.open-sans {
		font-family:$font-open-sans;
	}

	&.big-margin-bottom {
		margin-bottom:25px;
	}

	&.text-shadow {
		text-shadow: 2px 2px 6px rgba($black, 0.3);
	}
}