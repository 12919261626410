.advice {

	&__block {
	
		.btn {
			@include vw-font-size(16px);
			float:right;
			font-weight:normal;
			text-transform:none;
			min-width:49%;

			@include media-breakpoint-up(lg) { 
				min-width:0;
			}

			@include media-breakpoint-up(xl) { 
				min-width:250px;
			}
		}
	}

	&__content {
		margin-bottom:30px;

		@include media-breakpoint-up(md) { 
			margin-bottom:50px;
			padding-left:42px;
		}
	}

	&__title {
		@include vw-font-size(17px);
		@include vw-line-height(24px);
		color:$purple-light;
		display:block;
		font-family:$font-quicksand;
		font-weight:bold;
		margin-bottom:25px;
		position:relative;
		text-align:left;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(24px);
			@include vw-line-height(30px);
			margin-bottom:40px;
		}

		&:before {
			background:$white;
			border:8px solid $purple-light;
			border-radius:50%;
			content:'';
			display:inline-block;
			height:22px;
			vertical-align:top;
			width:22px;

			@include media-breakpoint-up(md) {
				height:32px;
				width:32px;
			}
		}

		> span {
			display:inline-block;
			margin-left:10px;
			max-width:calc(100% - 50px;)
		}
	}

	&__search-list {

		&__item {
			border-bottom:1px solid #e6e6e6;
			display:block;
			margin:0 auto 20px;
			overflow:hidden;
			padding-bottom:20px;
			width:100%;
		}

		&__box {
			margin-bottom:20px;
			text-align:left;

			@include media-breakpoint-up(md) { 
				display:inline-block;
				margin-bottom:0;
				vertical-align:middle;
				width:calc(100% - 210px);
			}

			@include media-breakpoint-up(lg) { 
				width:calc(100% - 150px);
			}

			@include media-breakpoint-up(xl) { 
				width:calc(100% - 270px);
			}

			&:before {
				background:$black;
				content:'';
				display:inline-block;
				height:4px;
				margin-top:10px;
				vertical-align:top;
				width:10px;
			}

			> span {
				display:inline-block;
				margin-left:10px;
				vertical-align:top;
				width:calc(100% - 25px);

				&.tagify__tag-text {
					width: auto;
					margin-left:5px;
					background: #ddd;
					color: #3a3a3a;
					padding: 0.5em 0.65em;
					font-size:0.85rem;
				}
			}
		}

		&__buttons {
			margin-top:20px;

			@include media-breakpoint-up(md) { 
				display:inline-block;
				margin-top:0;
				min-width:200px;
				overflow:hidden;
				vertical-align:middle;
			}

			@include media-breakpoint-up(lg) { 
				min-width:0;
			}

			@include media-breakpoint-up(xl) { 
				min-width:250px;
			}

			.btn {
				float:right;
				width:49%;

				@include media-breakpoint-up(md) {					
					width:100%;
				}
			}
		}
	}
}