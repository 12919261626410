.rate-slide {
	padding-bottom:30px;

	&__step {
		@include vw-font-size(32px);
		border:2px solid $purple-light;
		border-radius:50%;
		clear:both;
		color:$purple-light;
        display:block;
		font-family:$font-quicksand;
		font-weight:bold;
		height:40px;
		line-height:40px;
		margin:20px auto 10px;
		text-align:center;
		width:40px;

		@include media-breakpoint-up(md) { 
			height:50px;
			line-height:50px;
			width:50px;
		}
	}

	&__content {
		margin-bottom:20px;
	}

	&__slide {

	}

	&__rating {

	}

	.swiper-pagination-bullet {
		margin:0 5px;
	}

	.swiper-pagination-bullet-active { background:$purple; }

	.form-textarea {
		margin:0 auto 20px;
		max-width:78%;

		@include media-breakpoint-up(md) { 
			max-width:76%;
		}
	}
}