.car-detail-section {
	padding-left:0;
	padding-right:0;

	@include media-breakpoint-up(lg) { 
		padding-left:15px;
		padding-right:15px;		
	}
}

.car-detail {
	margin:0 auto;
	max-width:850px;
	text-align:left;

	@include media-breakpoint-up(lg) {
		//align-items:center;
		//align-items:flex-end;
		align-items:flex-start;
		display:flex;
		flex-wrap:wrap;
		justify-content:left;
		max-width:100%;
		padding:0;
	}

	@include media-breakpoint-up(xl) {
		//align-items:flex-end;
		padding-top:35px;
	}

	&__content-gallery {
		overflow:hidden;
		position: relative;
		text-align:center;

		@include media-breakpoint-up(lg) { 
			width:59%;
		}

		.car-list__like {
			padding-top:10px;

			@include media-breakpoint-up(lg) { 
				right:10px;
				top:0;
			}

			@include media-breakpoint-up(xl) { 
				top:10px;
			}
		}

		.car-list__share {
			@include media-breakpoint-up(lg) { 
				top:0;
				right:51px;
			}

			@include media-breakpoint-up(xl) { 
				top:10px;
			}
		}

		.swiper-button-next, .swiper-button-prev {
			background-size:contain;
			height:34px;
			width:21px;	
		}

		.swiper-button-next { right:15px; }
		.swiper-button-left { left:15px; }
	}

	&__content--reserved {
		.car-detail__agroup-gallery {
			overflow:hidden;
			position:relative;

			&:before {
				background:$green;
				bottom: 30px;
				color:$white;
				content:'Reservado';
				display:inline-block;
				font-family:$font-quicksand;
				font-size:18px;
				font-weight:bold;
				line-height:24px;
				padding: 3px 40px;
				position: absolute;
				right: -40px;
				text-align:center;
				transform:rotate(-45deg);
				z-index:3;

				@include media-breakpoint-up(md) { 
					bottom: 50px;
					font-size:24px;
					line-height:34px;
					padding: 3px 60px;
					right:-56px;
				}

				@include media-breakpoint-up(lg) { 
					font-size:27px;
					line-height:40px;
				}
			}
		}

		.car-list__selection-detail { display:none; }
	}

	&__content--sold {
		.car-detail__agroup-gallery {
			overflow:hidden;
			position:relative;

			&:before {
				background:#000;
				bottom: 30px;
				color:$white;
				content:'Vendido';
				display:inline-block;
				font-family:$font-quicksand;
				font-size:18px;
				font-weight:bold;
				line-height:24px;
				padding: 3px 60px;
				position: absolute;
				right: -50px;
				text-align:center;
				transform:rotate(-45deg);
				z-index:3;

				@include media-breakpoint-up(md) { 
					bottom: 50px;
					font-size:24px;
					line-height:34px;
					padding: 3px 80px;
					right:-56px;
				}

				@include media-breakpoint-up(lg) { 
					font-size:27px;
					line-height:40px;
				}
			}
		}
	}

	.car-list__selection-detail {
		bottom:74px;
		right:10px;
		margin:0;
		position:absolute;
		width:30px;
		z-index:1;

		@include media-breakpoint-up(md) {
			bottom:10px;
			width:60px;
		}

		@include media-breakpoint-up(xl) {
			bottom:25px;
			right:25px;
		}
	}

	&__agroup-gallery {
		background:$white;
		@include media-breakpoint-up(lg) { 
			height:100%;
		}
	}

	&__gallery {
		margin:0 auto;
		overflow:hidden;
		width: 100%;

		&.init {
			display:block;
			left:0;
			position:absolute;
			top:0;
			width:100%;
			z-index:-1;
		}
		
		@include media-breakpoint-up(lg) { 
			//height:100%;

			.swiper-container, .panzoom-parent {
			//	height:100%;				
			}
		}
	}

	&__gallery--open {
		display:none;
	}

	&__gallery--close {
		
	}

	&__image {
		background:$gray-light;
		@include media-breakpoint-up(lg) { 
		//	min-height:100%;
		}

		img {
			height:auto;
			width:100%;

			/*@include media-breakpoint-up(lg) { 
				height:100%;
				left:0;
				object-fit:cover;
				position:absolute;
				top:0;
			}*/
		}
		
	}

	&__gallery__actions {
		background:$white;
		border:1px solid #eee;
		border-radius:8px;
		bottom: 0;
		display:inline-block;
		margin:10px auto;
		height:40px;
		overflow:hidden;		
		position: absolute;
		left:10px;
		z-index:3;

		@include media-breakpoint-up(md) { 
			border:0;
			margin:0;
			left: 10px;
			top: 10px;
		}

		@include media-breakpoint-up(xl) {
			left: 30px;
			top: 30px;
		}
	}

	.swiper-pagination {
		display:none;

		@include media-breakpoint-up(xl) {
			display:block;
		}
	}

	&__gallery__actions--bottom {
		display:inline-block;
		left:auto;
		margin:10px auto;
		position:relative;
		right:auto;
		top:auto;

		@include media-breakpoint-up(md) { 
			bottom:10px;
			left:10px !important;
			margin:0;
			position:absolute;
		}

		@include media-breakpoint-up(xl) {
			bottom:30px;
			left:30px !important;
		}
	}

	&__content--delivery {
		.car-detail__gallery__actions {
			@include media-breakpoint-up(md) {
				top: unset;
				left: 150px;
				bottom: 10px;
			}

			@include media-breakpoint-up(xl) {
				left: 180px;
				bottom: 30px;
			}
		}

		&.car-detail__content--exterior,
		&.car-detail__content--interior {
			.car-detail__gallery__actions {
				@include media-breakpoint-up(md) {
					left: 85px;
				}

				@include media-breakpoint-up(xl) {
					left: 115px;
				}
			}
		}
	}

	.car-detail__content--exterior.car-detail__content--interior {
		.car-detail__gallery__actions {
			@include media-breakpoint-up(md) {
				left: 10px !important;
			}

			@include media-breakpoint-up(xl) {
				left: 30px !important;
			}
		}
	}

	&__gallery__action {
		background-position:center;
		background-repeat:no-repeat;
		background-size:20px;
		box-sizing:border-box;
		cursor:pointer;
		height:inherit;
		float:left;
		width:55px;

		&.hide { display:none; }

		@include media-breakpoint-up(md) { 
			width:65px;
		}

		&.selected {
			background-color:$purple-light;
		}
	}

	&__gallery__action--zoom-in {
		background-image:url(#{$url-statics}/svg/plus.svg);
	}

	&__gallery__action--zoom-out {
		background-image:url(#{$url-statics}/svg/less.svg);
		border-left:1px solid #eee;
	}
	&__gallery__action--expand {
		background-image:url(#{$url-statics}/svg/expand.svg);
		border-left:1px solid #eee;	
	}

	&__gallery__action--close {
		background-image:url(#{$url-statics}/svg/close-car.svg);
		background-size:30px;

		&.selected {
			background-image:url(#{$url-statics}/svg/close-car-white.svg);
		}
	}

	&__gallery__action--open {
		background-image:url(#{$url-statics}/svg/open-car.svg);
		background-size:30px;

		&.selected {
			background-image:url(#{$url-statics}/svg/open-car-white.svg);
		}
	}

	&__info {
		padding:0 15px 25px;

		@include media-breakpoint-up(md) { 
			padding:20px 15px 25px;
		}

		@include media-breakpoint-up(lg) { 
			padding:0 0 0 25px;
			width:41%;
		}

		@include media-breakpoint-up(xl) {
			padding-bottom:35px;
			//padding-top:35px;
		}

	}

	&__title {
		@include vw-font-size(22px);
		@include vw-line-height(37px);
		color:$black;
		display:block;
		font-family:$font-open-sans;
		font-weight:bold;
		margin:0 auto 10px;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(35px);
		}
		
		@include media-breakpoint-up(xl) { 
			@include vw-font-size(40px);
			@include vw-line-height(56px);
		}
	}

	&__description {
		@include vw-font-size(17px);
		@include vw-line-height(24px);
		display:block;
		font-family:$font-quicksand;
		margin:0 auto 20px;

		@include media-breakpoint-up(lg) { 
			@include vw-font-size(20px);
			@include vw-line-height(26px);
		}
		
		/*@include media-breakpoint-up(xl) { 
			@include vw-font-size(30px);
			@include vw-line-height(40px);
		}*/
	}

	&__attributes {
		margin:15px auto;
		padding:0;
		list-style: none;

		@include media-breakpoint-up(lg) { 
			margin:0 auto 7px;
		}

		@include media-breakpoint-up(xl) { 
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__attributes__item {
		display:inline-block;
		margin-bottom:15px;
		vertical-align:middle;
		width:49%;
		line-height:1;

		@include media-breakpoint-up(xl) { 
			margin-bottom:20px;
		}

		// &:last-child {
		// 	margin-bottom:0;
		// }

		// > * {
		// 	display:inline-block;
		// 	vertical-align:middle;
		// }

		img {
			height:auto;
			margin-right:15px;
			width:34px;
		}

		.text {
			display:inline-block;
			max-width:calc(100% - 60px);
		}
	}

	&__attributes--renting {
		.car-detail__attributes__item {
			width:49%;

			@include media-breakpoint-up(md) { 
				width:32%;
			}
		}
	}

	&__attributes__block {
		.car-detail__attributes {
			// code...
		}

		.car-detail__attributes__item {
			width: auto !important;
			position: relative;

			picture img {
				margin-right: 20px;
			}

			picture.bw img {
				-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
				filter: grayscale(100%);
			}
		}

		[data-tooltip] {
			&:before, &:after {
				margin-left: -10px;
			}
		}
	}

	&__price {

	}

	&__price__info {
		clear:both;
		display:block;
		margin-bottom:15px;

		// @include media-breakpoint-up(lg) { 
		// 	text-align:right;
		// }

		@include media-breakpoint-up(xl) { 
			margin-bottom:30px;
		}

		> .car-detail__price__block {
			vertical-align:bottom;
		}
	}

	&__fee__block {
		margin-top: 20px;

		span {
			font-weight: bold;
			color: $purple-light;
		}
	}

	&__discount__block {
		margin-top: 20px;

		img {
			width: 50px;
			margin-right: 10px;
		}
	}

	&__price__block {
		color:$black;
		display:inline-block;
		font-family:$font-open-sans;
		vertical-align:top;
		width:auto;

		&:first-child {
			border-right:1px solid #eee;
			margin-right:15px;
			padding-right:20px;
		}

		span {
			@include vw-font-size(14px);
			display:block;			
			font-weight:300;

			@include media-breakpoint-up(md) { 
				@include vw-font-size(16px);
			}

			img {
				width: 20px;
				margin-left: 5px;
				position: relative;
				bottom: 2px;
			}
		}

		div {
			@include vw-font-size(18px);
			display:block;
			font-weight:bold;

			@include media-breakpoint-up(md) { 
				@include vw-font-size(21px);
			}

			b {
				@include vw-font-size(25px);
				@include vw-line-height(24px);
				font-weight:600;

				@include media-breakpoint-up(md) { 
					@include vw-font-size(32px);
					@include vw-line-height(40px);
				}
			}
		}
	}

	&__promo {
		position: relative;
		padding: 35px 5px 25px;
		margin: 25px 0 20px;
		background-color: $gray-100;

		&__badge {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;

			span {
				display: inline-block;
				padding: 2px 12px;
				background-color: $black;
				color: $white;
				text-transform: uppercase;
				border-radius: 6px;
			}
		}

		&__list {
			// padding: 0;
			margin: 0;
			max-width: 360px;
			padding-right: 40px;
			list-style-image: url("#{$url-statics}/svg/check-new.svg");
		}

		&__item {
			// font-weight: 600;
			padding-left: 5px;

			+li {
				margin-top: 10px;
			}
		}
	}

	&__offer {
		align-items:center;
		background:#f8e71c;
		display:flex;
		flex-wrap:nowrap;
		float:left;
		margin-bottom:8px;
		padding:7px 12px;

		// @include media-breakpoint-up(lg) { 
		// 	float:right;
		// }

		&__name {
			@include vw-font-size(15px);
			color:$black;
			font-weight:bold;
			letter-spacing:-0.2px;
			overflow: hidden;

			@include media-breakpoint-up(md) {
				@include vw-font-size(16px);
			}

			@include media-breakpoint-up(xl) {
				@include vw-font-size(18px);
			}
		}
	}

	&__delivery {
		width: 250px;
		height: 100px;
		padding: 10px 15px;

		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background-color: transparent;
		background-image: url("#{$url-statics}/svg/badge.svg");
		background-repeat: no-repeat;
		background-size: contain;
		background-position: top left;
		z-index:2;

		&__label {
			font-size: 18px;
			color: $white;
			font-weight: bold;
			overflow: hidden;
			white-space: nowrap;
			position: relative;
			text-align: left;
			text-transform: uppercase;

			@media #{$wide-screen} {
				min-width: 150px;
			}
		}
	}

	&__price__block--discount {
		.price {
			display:inline-block;
			font-weight:normal;
			position:relative;

			&:before {
				background: #f23a49;
			    content: '';
			    display: block;
			    left: 0;
			    height: 2px;
			    position: absolute;
			    top:16px;
			    transform: rotate(-11deg);
			    width: 100%;
			    z-index: 1;
			}

			b {
				@include vw-font-size(18px);
				@include vw-line-height(21px);
				font-weight:normal;

				@include media-breakpoint-up(md) { 
					@include vw-font-size(24px);
					@include vw-line-height(33px);
				}
			}
		}

		.new-price { text-align:right; }

		.discount {
			@include vw-font-size(12px);
			@include vw-line-height(14px);
			border:1px solid $purple-light;
			border-radius:4px;
			color:$purple-light;
			display:inline-block;
			font-family:$font-open-sans;
			margin-right: 5px;
    		margin-top: 3px;
			padding:2px 8px;			
			text-align:center;
			vertical-align: top;
			width:auto;

			@include media-breakpoint-up(md) {
				@include vw-font-size(13px);
				@include vw-line-height(15px);
				margin-top: 6px;
			}
		}
	}

	&__actions {
		clear:both;
		display:block;
		margin-bottom:15px;
		text-align:left;

		// @include media-breakpoint-up(sm) { 
		// 	text-align:center;
		// }
		
		// @include media-breakpoint-up(lg) { 
		// 	text-align:right;
		// }

		&--left {
			text-align:left;
		}

		.btn {
			text-align:center;
			margin-bottom:5px;
			margin-top:5px;
			min-width:0;
			width:100%;

			margin-left: 0;
			margin-right: 5px;

			@include media-breakpoint-up(sm) { 
				width:48%;
			}

			@include media-breakpoint-up(xl) { 
				// margin-bottom:0;
				min-width:220px;
				width:auto;

				// &:last-child { margin-left:5px; }
			}
		}

		.text-sold {
			@include vw-font-size(15px);
			@include vw-line-height(22px);
			color:#000;
			display:block;
			font-family:$font-quicksand;
			font-weight:100;
			margin-bottom:10px;
			
			@include media-breakpoint-up(md) { 
				@include vw-font-size(16px);
				@include vw-line-height(24px);
			}

			@include media-breakpoint-up(lg) { 
				@include vw-font-size(20px);
				@include vw-line-height(26px);
			}
		}
	}

	&__small {
		@include vw-font-size(12px);
		@include vw-line-height(18px);
		color:#7e7d7d;
		display:block;
		margin-bottom:5px;

		@include media-breakpoint-up(xl) { 
			margin-top:30px;
		}
	}
}

body.desktop {
	.car-detail {
		.car-detail__gallery__action:hover {
			background-color:$purple-light;
		}

		.car-detail__gallery__action--zoom-in:hover {
			background-image:url(#{$url-statics}/svg/plus-white.svg);
		}

		.car-detail__gallery__action--zoom-out:hover {
			background-image:url(#{$url-statics}/svg/less-white.svg);
		}
		
		.car-detail__gallery__action--expand:hover {
			background-image:url(#{$url-statics}/svg/expand-white.svg);
		}

		&__gallery__action--close:hover {
			background-image:url(#{$url-statics}/svg/close-car-white.svg);
		}

		&__gallery__action--open:hover {
			background-image:url(#{$url-statics}/svg/open-car-white.svg);
		}
	}
}

.car-detail-section {
	@include media-breakpoint-up(lg) {
		max-width:1440px !important;
		width:100%;
	}
}

.back-to-search {
	@include vw-font-size(14px);
	display:inline-block;
	font-weight:600;
	margin-bottom:20px;
	text-align:left;
	transition:$transition-base;
	position:relative;

	span {
		display:inline-block;
		padding-left:8px;
		vertical-align:middle;
	}

	&:hover {
		color:$purple;
		text-decoration:none;

		&:before { color:$purple; }
	}

	&:after {
		background:#f7f7f7;
		border-radius:50%;
		content:'';
		display:inline-block;
		height:32px;
		left:0;
		position:absolute;
		top:0;
		width:32px;

		@include media-breakpoint-up(md) {
			height:42px;
			width:42px;
		}
	}

	&:before {
		line-height:32px;
		margin: 0 auto;
		position:relative;
		transform: scale(-1,-1);    	
		width:32px;
		z-index:1;

		@include media-breakpoint-up(md) {
			line-height:42px;
			width:42px;
		}
	}
}