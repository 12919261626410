// ==========================================================================
// Sharers
// ==========================================================================


// 1.Config
$colorLink: #ffffff;


// 2.Base

.share, .share-list {
	margin:0;
	padding:0;
	text-align:left;

	&--center {
		margin-bottom:20px;
		text-align:center;

		@include media-breakpoint-up(sm) { 
			margin-bottom:35px;
		}

		.ico-facebook, .ico-twitter, .ico-whatsapp {
			@include vw-font-size(25px);

			@include media-breakpoint-up(sm) { 
				@include vw-font-size(33px);
			}

			@include media-breakpoint-up(md) { 
				@include vw-font-size(30px);
			}				
		}
	}

	&--right-sm {
		display:block;
		margin:20px auto 0;
		text-align:right;

		@include media-breakpoint-up(sm) { 
			margin-top:40px;
		}
	}	

	&__item {
		@include vw-font-size(20px);
		color:$colorLink;
		display:inline-block;
		margin:0 5px;
		transition:$transition-base;
		vertical-align:middle;

		&:first-child { margin-left:0; }
		&:last-child { margin-right:0; }
	}

	&__item--big {
		@include media-breakpoint-up(sm) { 
			@include vw-font-size(27px);
			margin:0 10px;
		}

		@include media-breakpoint-up(md) { 
			@include vw-font-size(24px);
		}

		.ico-mail {
			@include vw-font-size(32px);

			@include media-breakpoint-up(md) { 
				@include vw-font-size(29px);
			}			
		}

		.ico-linkedin {
			@include vw-font-size(15px);

			@include media-breakpoint-up(sm) { 
				@include vw-font-size(19px);
			}

			position: relative;
			top: -2px;
		}
	}

	&__item--init {
		@include vw-font-size(14px);
		margin-left:0;
	}


	&__link {
		color:$white;

		&:before {
			margin:0;
			width:100%;
		}

		&:hover {
			color:$purple-light;
			text-decoration:none;

			@include media-breakpoint-up(md) { 
				color:$purple;
			}
		}
	}

	&__link--gray {
		color:#ccc;
	}
}