// ==========================================================================
// Horizontal Highlight Block
// ==========================================================================

// 1.Config


// 2.Base
.horizontal-block-list {
	overflow:hidden;
	margin:20px auto;

    &__item {
    	display:block;
    	margin:20px auto 35px;
    	max-width:500px;
    	text-align:left;
    	width:100%;

    	@include media-breakpoint-up(md) { 
    		float:left;
    		margin:30px auto;
    		max-width:100%;
    		min-height:395px;
    	}

    	&:first-child {
    		margin-top:0;

    		@include media-breakpoint-up(md) { 
    			margin-top:30px;
    		}
    	}

    	&:last-child {
    		margin-bottom:0;
    	}

    	&--n-m-t { margin-top:0; }
    }

    &__item--min {
    	@include media-breakpoint-up(md) { 
    		min-height:295px;
    	}
    }

    &:not(.horizontal-block-list--inverse) {
    	.horizontal-block-list__item {
    		&:nth-child(even) {
	    		.horizontal-block-list__figure {
	    			@include media-breakpoint-up(md) { 
	    				float:right;
	    				padding-left:20px;
	    				padding-right:0;

	    				.horizontal-block-list__image-content {
	    					float:left;
	    				}
					}

	    			@include media-breakpoint-up(lg) { 
						padding-left:50px;
					}

					&.horizontal-block-list__figure--no-abs {
						padding-left:0;
					}

	    		}

	    		.horizontal-block-list__content {
	    			@include media-breakpoint-up(md) { 
	    				float:left;
	    				padding-left:0;
	    				padding-right:25px;
					}

	    			@include media-breakpoint-up(lg) { 
						padding-right:55px;
					}

					.horizontal-block-list__caption {
						left:auto;

						@include media-breakpoint-up(md) { 
							right:20px;
						}

						@include media-breakpoint-up(md) { 
							//right:45px;
						}
					}
	    		}
	    	}
    	}
    }

    &--inverse {
		.horizontal-block-list__item {
			&:nth-child(odd) {
	    		.horizontal-block-list__figure {
	    			@include media-breakpoint-up(md) {
	    				float:right;
	    				padding-left:20px;
	    				padding-right:0;

	    				.horizontal-block-list__image-content {
	    					float:left;
	    				}
					}

	    			@include media-breakpoint-up(lg) { 
						//padding-left:50px;
					}

	    		}

	    		.horizontal-block-list__content {

	    			@include media-breakpoint-up(md) { 
	    				padding-left:0;
	    				padding-right:25px;
					}

	    			@include media-breakpoint-up(lg) { 
						//padding-right:55px;
					}

					.horizontal-block-list__caption {
						left:auto;

						@include media-breakpoint-up(md) { 
							right:20px;
						}

						@include media-breakpoint-up(lg) { 
							//right:45px;
						}
					}
	    		}
	    	}
		}
	}

	&__figure {
		margin-bottom:10px;

		@include media-breakpoint-up(md) { 
			display:inline-block;
			min-height:inherit;
			padding-right:20px;
			vertical-align:middle;
			width: 49%;

			.f-right { float:right; }
		}

		@include media-breakpoint-up(lg) { 
			//padding-right:45px;
		}

		.horizontal-block-list__image-content {
			max-width:500px;
			min-height:inherit;
			overflow:hidden;
			position:relative;
			width:100%;

			@include media-breakpoint-up(md) { 
				float:right;
			}
		}

		img {
			display:block;
			height:auto;
			transition:$transition-base;
			width:100%;

			@include media-breakpoint-up(md) { 
				height:100%;
				left:0;
				object-fit:cover;
				position:absolute;
				top:0;
				width:100%; 
			}
	    }
	}

	&__figure--auto {
		min-height:0;

		img {
			display:block;
			height:auto;
			margin:0 auto;
			max-width:190px;
			transition:$transition-base;
			width:100%;

			@include media-breakpoint-up(md) { 
				height:auto;
				left:auto;
				max-width:378px;
				object-fit:contain;
				position:relative;
				top:auto;
				width:100%; 
			}
	    }
	}

	&__figure--no-abs {
		img {
			display:block;
			height:auto;
			max-width:506px;
			transition:$transition-base;
			width:100%;

			@include media-breakpoint-up(md) { 
				height:auto;
				left:auto;
				//object-fit:none;
				object-fit:contain;
				position:relative;
				top:auto;
				width:100%; 
			}
	    }
	}

	&__figure--v-top {
		@include media-breakpoint-up(md) { 
			vertical-align:top;
		}
	}

	&__content {
		text-align:left;

		&.p-r {
			@include media-breakpoint-up(md) { 
				padding-right:40px !important;
			}
		}

		@include media-breakpoint-up(md) { 
			display:inline-block;
			min-height:inherit;
			padding-left:20px;
			position:relative;
			vertical-align:middle;
			width: 49%;
		}

		@include media-breakpoint-up(lg) { 
			//padding-left:45px;
		}

		.list {
			margin-bottom:30px;

			&--half {
				@include media-breakpoint-up(md) { 
					display:flex;
					flex-wrap:wrap;
					margin-bottom:15px;

					li {
						padding-right:25px;
						width:50%;
					}
				}
			}
		}
	}

	&__caption {
		clear:both;
		max-width:500px;

		@include media-breakpoint-up(md) { 
			left:30px;
			position:absolute;
			top:50%;
			transform:translate(0, -50%);
		}

		@include media-breakpoint-up(lg) { 
			//left:70px;
		}
	}

	&__caption--no-abs {
		clear:both;
		max-width:500px;

		@include media-breakpoint-up(md) { 
			left:auto;
			position:relative;
			top:auto;
			transform:translate(0, 0);
		}

		@include media-breakpoint-up(lg) { 
			//left:70px;
		}
	}

	&__caption--align-right {
		@include media-breakpoint-up(md) { 
			float:right;
			right:0 !important;
			text-align:right;
		}
	}

	&__step {
		@include vw-font-size(24px);
		border:2px solid $purple-light;
		border-radius:50%;
		clear:both;
		color:$purple-light;
        display:inline-block;
        font-family:$font-quicksand;
		font-weight:bold;
		height:40px;
		line-height:36px;
		margin-bottom:10px;
		text-align:center;
		vertical-align:top;
		width:40px;

        @include media-breakpoint-up(md) { 
        	@include vw-font-size(43px);
        	display:block;
        	float:left;
			height:60px;
			line-height:56px;
			margin-bottom:20px;
			width:60px;
		}
	}

	&__title {
		@include vw-font-size(19px);
		@include vw-line-height(27px);
		display:inline-block;
		color:$black;
		font-family:$font-quicksand;
		font-weight:bold;
		margin:10px auto 10px;
		max-width:calc(100% - 60px);
		padding-left:6px;

		&.purple { color:$purple; }

		@include media-breakpoint-up(md) { 
			@include vw-font-size(21px);
			@include vw-line-height(27px);
			clear:both;
			display:block;
			padding-left:0;
			max-width:100%;
		}

		@include media-breakpoint-up(xl) { 
			@include vw-font-size(27px);
			@include vw-line-height(32px);
		}
	}

	&__title--big {
		@include vw-font-size(23px);
		@include vw-line-height(26px);

		&.purple { color:$purple; }

		@include media-breakpoint-up(md) { 
			@include vw-font-size(36px);
			@include vw-line-height(38px);
		}
	}

	&__title--m-t {
		margin-top:20px;

		@include media-breakpoint-up(md) { 
			margin-top:30px;
		}
	}

	&__title--no-step {
		padding-left:0;
		max-width:100%;
	}

	&__description {
		padding-left:50px;

		@include media-breakpoint-up(md) { 
			padding-left:0;
		}
	}

	&__description--no-step {
		padding-left:0;
	}
}