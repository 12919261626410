// ==========================================================================
// MediumHighlight
// ==========================================================================


// 1.Config


// 2.Base
.highlight-medium {
	@include vw-font-size(17px);
	@include vw-line-height(24px);
	color:$purple;
	display:block;
	font-family:$font-quicksand;
	margin:0 auto 20px;
	
	@include media-breakpoint-up(md) { 
		@include vw-font-size(30px);
		@include vw-line-height(40px);
	}

	&.white {
		color:$white;
	}

	&.purple-light {
		color:$purple-light;
	}

	&.black {
		color:$black;
	}

	&.bold {
		font-weight:bold;

		b { font-weight:bold; }
	}

	&.lighter {
		font-weight:lighter;
	}

	&.extrabold {
		font-weight:700;
	}

	&.open-sans {
		font-family:$font-open-sans;
	}

	&.text-shadow {
		text-shadow: 2px 2px 6px rgba($black, 0.3);
	}
}