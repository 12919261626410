.result {
	text-align:center;

	&__box {
		background:#f7f7f7;
		border-radius:8px;
		box-sizing:border-box;
		font-family:$font-open-sans;
		margin-bottom:15px;
		padding:25px;
	}

	&__name {
		@include vw-font-size(17px);
		@include vw-line-height(27px);
		display:block;
		font-weight:bold;
		margin-bottom:0;
		margin-top:10px;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(18px);
			@include vw-line-height(27px);
		}
	}

	&__price {
		margin-bottom:20px;
		margin-top:20px;
	}

	&__actions {
		display:block;
		margin-bottom:15px;
		text-align:right;

		a {
			display:inline-block;
			margin-right:15px;

			&:last-child { margin-right:0; }
		}
	}

	&__tin-tae {
		justify-content:center;
		display:flex;
		flex-wrap:nowrap;

		&__option {
			@include vw-font-size(13px);
			@include vw-line-height(19px);
			&:first-child { margin-right:7px; }

			@include media-breakpoint-up(md) { 
				@include vw-font-size(14px);
			}
		}
	}

	&__action {
		
	}

	small {
		@include vw-font-size(13px);
		display:block;
		margin-bottom:20px;
		text-align:left;

		@include media-breakpoint-up(md) { 
		 	margin-bottom:40px;
		}
	}

	&__inline {
		> * {
			display:inline-block;
			vertical-align:middle;
		}

		.result__price {
			margin:0 auto;
		}
	}
}