.landing-header {
	margin-top:-20px;
	padding-top:120px;
	position:relative;

	@include media-breakpoint-up(md) { 
		padding-top: 178px;
	}

	@include media-breakpoint-up(lg) { 
		margin-left:151px;
		margin-right: 44px;
	}

	.container-fluid {
		max-width:1230px;
	}

	&__bg {
		max-width:1335px;
		z-index:-1;
		
        &:before {
           	background:rgba($black, .4);
            content:'';
            display:block;
            height:calc(100% - 50px);
            left:0;
            position:absolute;
            top:0;
            width:100%;
            z-index:1;
        }

        img {
            position:absolute;
            height:calc(100% - 50px);
            object-fit: cover;
            top: 0;     
            right: 0;
            width:100%;
        }
	}

	&__caption {
		position: relative;
    	z-index: 1;

		.title {
			@include vw-font-size(27px);
			@include vw-line-height(37px);
			color:$white;
			display:block;
			font-family:$font-quicksand;
			font-weight:bold;
			margin:0 0 20px;
			//max-width:470px;
			padding-left:20px;
			
			@include media-breakpoint-up(md) { 
				@include vw-font-size(56px);
				@include vw-line-height(64px);
				margin-left:35px;
				padding-left:35px;
			}
		}

		.subtitle {
			@include vw-font-size(16px);
			@include vw-line-height(27px);
			display:block;
			color:$white;
			margin-top:20px;
			padding:20px 0 0 20px;
			position:relative;

			@include media-breakpoint-up(md) { 
				margin-left:35px;
				padding-left:35px;
			}

			&:before {
				background:$white;
				content:'';
				display:block;
				height:1px;
				left:0;
				position:absolute;
				top:0;
				width:97px;
			}
		}
	}

	&__form {
		position:relative;
		z-index:1;
		text-align:right;

		.form-group--half-left {
			@include media-breakpoint-up(md) {	
				clear:none;
				float:left;		
				padding-left:0 !important;
				padding-right:10px !important;
				width:50%;
			}
		}

		.form-group--half-right {
			@include media-breakpoint-up(md) {			
				clear:none;
				float:left;
				padding-left:10px !important;
				padding-right:0 !important;
				width:50%;
			}
		}

		.form-label--legal {
			@include vw-font-size(13px);

			strong {
				display:block;
				font-family:$font-open-sans;
				margin-bottom:10px;
			}
		}

		.form-label-text {
			@include vw-font-size(14px);	
		}

		.form-control, .form-textarea {
			@include vw-font-size(15px);
		}

		.btn { margin-top:5px; }

		.desktop {
			@include vw-font-size(15px);
			display:none;
			margin-top:10px;

			@include media-breakpoint-up(md) {
				display:block;
			}
		}

		.mobile {
			display:block;

			@include media-breakpoint-up(md) {
				display:none;
			}
		}
	}

	&__box {
		background:$white;
		border-radius: 6px;
  		box-shadow: 1px 2px 4px 0 rgba($black, .19);
  		display:inline-block;
  		max-width:714px;
  		padding:20px;
  		text-align:left;

  		.text-medium {
  			@include vw-font-size(17px);
			@include vw-line-height(24px);
			font-family:$font-quicksand;
			margin:0 auto 15px;
			
			@include media-breakpoint-up(lg) { 
				@include vw-font-size(20px);
				@include vw-line-height(27px);
			}
  		}
	}

	.go-to-anchor {
		@include vw-font-size(15px);
		@include vw-line-height(27px);
		color:$white;
		display:none;
		font-family:$font-quicksand;
		font-weight:bold;
		transform:rotate(-90deg) translate(80%, 100%);
		transition:transform .2s ease-in-out;

		@include media-breakpoint-up(md) {
			display:inline-block;
		}

		span { padding-left:10px; }

		&:hover {
			text-decoration:none;
			transform:rotate(-90deg) translate(75%, 100%);
		}

		&:before {
			background:url(#{$url-statics}/svg/arrow-down.svg) center no-repeat;
			background-size:contain;
			content:'';
			display:inline-block;
			height:48px;
			transform:rotate(90deg);
			vertical-align:middle;
			width:48px;
		}
	}
}

.landing-content {
	margin:0 auto 30px;
	padding-top:30px;

	@include media-breakpoint-up(md) { 
		margin:0 auto 50px;
		padding-top:100px;
	}

	&__agroup {
		@include vw-font-size(15px);
		@include vw-line-height(22px);
		color:$color-base;
		display:block;
		font-family:$font-open-sans;
		margin:0 auto;
		max-width:800px;
		
		@include media-breakpoint-up(md) { 
			@include vw-font-size(16px);
			@include vw-line-height(24px);
		}

		> * {
			margin:0 auto;
			max-width:640px;
			text-align:left;
		}

		img, iframe {
			display:block;
			height:auto;
			margin:20px auto;
			max-width:100%;
			width:auto;

			@include media-breakpoint-up(md) { 
				margin:40px auto;
			}
		}

		h2, h3, h4, h5 {
			color:#000;
			display:block;
			font-family:$font-quicksand;
			font-weight:bold;
			margin:0 auto 20px;
		}

		h2 {
			@include vw-font-size(24px);
			@include vw-line-height(34px);
			
			@include media-breakpoint-up(md) { 
				@include vw-font-size(36px);
				@include vw-line-height(46px);
			}
		}

		h3 {
			@include vw-font-size(21px);
			@include vw-line-height(31px);
			
			@include media-breakpoint-up(md) { 
				@include vw-font-size(33px);
				@include vw-line-height(43px);
			}
		}

		h3 {
			@include vw-font-size(18px);
			@include vw-line-height(28px);
			
			@include media-breakpoint-up(md) { 
				@include vw-font-size(30px);
				@include vw-line-height(40px);
			}
		}

		h4, h5 {
			@include vw-font-size(17px);
			@include vw-line-height(27px);
			
			@include media-breakpoint-up(md) { 
				@include vw-font-size(26px);
				@include vw-line-height(36px);
			}
		}

		p {
			margin:15px auto;
		}

		a {
			color:$purple-light;
			text-decoration:underline;

			&:hover {
				color:$purple;
			}
		}
	}
}

body.desktop.safari {
	.landing-header .go-to-anchor {
		transform:rotate(-90deg) translate(10%, 100%);
		
		&:hover {
			transform:rotate(-90deg) translate(5%, 100%);
		}
	}
}

.main--header-discount-bar .landing-header {
	padding-top:200px;

	@include media-breakpoint-up(md) { 
		padding-top: 210px;
	}
}