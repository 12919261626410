/* 
  You want a simple and fancy tooltip?
  Just copy all [data-tooltip] blocks:
*/
$tooltipBG:$white;
$tooltipBoxBG:$white;

[data-tooltip]:not(.not-format-styles) {
    @include vw-font-size(13px);
    border:2px solid $purple-light;
    border-radius:50%;
    color:$black;
    cursor:pointer;
    display:inline-block;
    font-family:$font-open-sans;
    font-weight:bold;
    line-height:18px;
    margin-left:5px;
    position: relative;
    text-align:center;
    width:22px;
    z-index: 10;

    &.v-top { vertical-align:top; }
}

.not-format-styles {
    &[data-tooltip]:before {
        @include vw-font-size(10px);
        border:2px solid $purple-light;
        background-color:$white;
        border-radius:3px;
        letter-spacing:-.2px;
        min-width:160px;
        max-width:170px;
        padding:4px 15px;
        text-align:center;

        @include media-breakpoint-up(md) {
            @include vw-font-size(14px);
        }
    }

    &[data-tooltip]:after {
        border-color: $purple-light transparent transparent transparent;
    }
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
    bottom: calc(100% + 5px);
    cursor:default;
    left: 50%;
    opacity: 0;
    //pointer-events: none;
    position: absolute;
    //transition: 0.2s;
    //transition-delay: .5s;
    visibility: hidden;
    will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
    @include vw-font-size(13px);
    background-color: $tooltipBoxBG;
    border:2px solid $indigo;
    //box-shadow:1px 1px 6px rgba($black,.4);
    color: $color-base;
    border-radius: 9px;
    content: attr(data-tooltip);
    font-family:$font-open-sans;
    font-weight:normal;
    max-width:150px;
    min-width: 50px;
    padding: 10px 15px;
    text-align: left;
    transform: translate(-50%, -5px) scale(0.5);
    width: max-content;
    width: -moz-max-content;
    white-space: pre-wrap;

    @include media-breakpoint-up(sm) {
        max-width: 250px;
    }

    @include media-breakpoint-up(md) {
        max-width:300px;
    }
}

/* Tooltip arrow */
[data-tooltip]:after {
    border-color: $purple-light transparent transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0px 5px;
    content: '';
    transform: translateX(-50%);
    transform-origin: top;   /* Orientation setting for the slide-down effect */
    transition-duration: 0s; /* If the mouse leaves the element, the transition effects for the tooltip arrow are "turned off" */
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    opacity: 1;
    visibility: visible;
}

/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
    transition-delay: 0.3s;
    transform: translate(-50%, -5px) scale(1);
}

/* Slide down effect only on mouseenter (NOT on mouseleave) */
[data-tooltip]:hover:after {
    transform: translateX(-50%) ;
    transition-delay: 0.3s; /* Starting after the grow effect */
}

/*
  If you want some adjustability
  here are some orientation settings you can use:
*/

/* LEFT */
/* Tooltip + arrow */
    [data-tooltip-location="left"]:before,
    [data-tooltip-location="left"]:after {
        bottom: 50%;
        left: auto;
        right: calc(100% + 5px);
    }

    /* Tooltip */
    [data-tooltip-location="left"]:before {
        transform: translate(-5px, 50%) scale(0.5);
    }

    [data-tooltip-location="left"]:hover:before {
        transform: translate(-5px, 50%) scale(1);
    }

    /* Arrow */
    [data-tooltip-location="left"]:after {
        border-width: 5px 0px 5px 5px;
        border-color: transparent transparent transparent $tooltipBG;
        transform: translateY(50%) scaleX(0);
        transform-origin: left;
    }

    [data-tooltip-location="left"]:hover:after {
        transform: translateY(50%) scaleX(1);
    }


/* RIGHT */
    [data-tooltip-location="right"]:before,
    [data-tooltip-location="right"]:after {
        bottom: 50%;
        left: calc(100% + 5px);
    }

    [data-tooltip-location="right"]:before {
        transform: translate(5px, 50%) scale(0.5);
    }

    [data-tooltip-location="right"]:hover:before {
        transform: translate(5px, 50%) scale(1);
    }

    [data-tooltip-location="right"]:after {
        border-color: transparent $tooltipBG transparent transparent;
        border-width: 5px 5px 5px 0px;
        transform: translateY(50%) scaleX(0);
        transform-origin: right;
    }

    [data-tooltip-location="right"]:hover:after {
        transform: translateY(50%) scaleX(1);
    }



/* BOTTOM */
    [data-tooltip-location="bottom"]:before,
    [data-tooltip-location="bottom"]:after {
        bottom: auto;
        top: calc(100% + 5px);
    }

    [data-tooltip-location="bottom"]:before {
        transform: translate(-50%, 5px) scale(0.5);
    }

    [data-tooltip-location="bottom"]:hover:before {
        transform: translate(-50%, 5px) scale(1);
    }

    [data-tooltip-location="bottom"]:after {
        border-color: transparent transparent $tooltipBG transparent;
        border-width: 0px 5px 5px 5px;
        transform-origin: bottom;
    }


.tooltip-agroup {
    position:relative;
    z-index:2;
}