// ==========================================================================
// Rating
// ==========================================================================

// 1.Config


// 2.Base

.rating {
	display:inline-block;
	margin:0 auto;
	text-align:center;

	.star {
		//cursor:pointer;
		margin:0 auto;
	}

	> .rating,
	> .ratingStar { display: none; } 
	
	> .star:before { 
		content: "\e801";
		display: inline-block;
		font-family: 'carandu';
		margin:0 3px;
	}

	> .half:before { 
		content: "\e808";
		font-size:21px;
		left:-2px;
		position:absolute;
		top:-2.5px;
	}

	> .half {
		pointer-events: none;
	}

	> .star { 
		color:$gray-300; 
		float: right;
		position:relative;
	}


	> .rating:checked ~ .star,
	> .ratingStar:checked ~ .star { color: $purple-light; }

	> .star:hover ~ .rating:checked ~ .star,
	> .star:hover ~ .ratingStar:checked ~ .star { color: $purple-light; }
}

.rating--big {

	> .star:before {
		font-size:30px;

		@include media-breakpoint-up(md) { 
			font-size:40px;
		}
	}

	> .half:before {
		font-size:30px;
		left:0;
		top:0;

		@include media-breakpoint-up(md) { 
			font-size:40px;
		}
	}
}


.rating--hover {

	.star {
		cursor:pointer;
	}

	.star:hover {
		color:$purple;
	}
	
	.star:hover ~ .star {
		color: $purple;		
	}

	> .rating:checked ~ .star,
	> .ratingStar:checked ~ .star { color: $purple; }

	> .star:hover ~ .rating:checked ~ .star,
	> .star:hover ~ .ratingStar:checked ~ .star { color: $purple; }

}