// ==========================================================================
// Text
// ==========================================================================


// 1.Config


// 2.Base
.text {
	@include vw-font-size(15px);
	@include vw-line-height(22px);
	color:$color-base;
	display:block;
	font-family:$font-open-sans;
	
	@include media-breakpoint-up(md) { 
		@include vw-font-size(16px);
		@include vw-line-height(24px);
	}

	&.white {
		color:$white;
	}

	&.bold {
		font-weight:bold;
	}

	&.quicksand { font-family:$font-quicksand; }

	&.text--margin {
		margin:30px auto 20px;
	}
}

.text-align-left {
	text-align:left;
}