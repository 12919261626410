// ==========================================================================
// Section
// ==========================================================================


// 1.Config


// 2.Base
.section {
	background:$white;
	padding:20px 0;
	text-align:center;

	@include media-breakpoint-up(lg) { 
		padding:40px 0;
	}

	&__sidebar {
		width:100%;

		@include media-breakpoint-up(md) {
			float:left;
			width:350px;
		}
	}

	&__content {
		width:100%;

		@include media-breakpoint-up(md) {
			float:left;
			padding:0 15px;
			width:calc(100% - 350px);
		}	
	}

	&.search {
		.container {
			@include media-breakpoint-up(md) {
				max-width:100%;
				padding:0;
				width:100%;
			}
		}
	}

	&.section--top {
		padding-top:$headerHeight;

		/*@include media-breakpoint-up(md) { 
			padding-top:$headerHeightSm;
		}*/
	}

	&.section--sidebar {
		overflow:hidden;

		@include media-breakpoint-up(lg) {
		    display: flex;
	    	flex-wrap: wrap;
	    	justify-content: center;
	    }

	    .full-width {
	    	display: block;
	    	margin-bottom:15px;
    		width: 100%;

    		@include media-breakpoint-up(md) {
    			margin-bottom:10px;
    		}

    		.title {
    			@include vw-font-size(24px);
    			display:inline-block;
    			margin-bottom:0;
			}

    		.text-medium {
    			display:inline-block;
    			margin-bottom:0;
    			margin-left:5px;
    		}
	    }
	}

	&.section--margin-bottom {
		margin-bottom:25px;
	
		@include media-breakpoint-up(md) { 
			margin-bottom:40px;
		}
	}

	&.section--no-padding-top {
		padding-top:0;
	}

	&.section--no-padding-bottom {
		padding-bottom:0;
	}

	&.section--no-padding {
		padding-bottom:0;
		padding-top:0;
	}

	&.section--left {
		text-align:left;
	}

	&.section--gray {
		background:#f7f7f7;
	}

	&.section--border-top {
		border-top:1px solid #eee;
	}

	&.section--view, &.section--summary-content {
		padding-top:0;
		
		@include media-breakpoint-up(lg) { 
			display:inline-block;
			padding-left:20px;
			padding-right:20px;
			vertical-align:top;
			width:calc(100% - 355px);
		}

		@include media-breakpoint-up(xl) {
			width:calc(100% - 490px);
		}

		.form {
			margin:20px auto 10px;

			@include media-breakpoint-up(lg) { 
				margin:30px auto 10px;
			}
		}

		.border-box {
			border:1px solid #eee;
			margin-top:20px;
			overflow:hidden;
			padding:20px 0 30px;
			text-align:center;

			&.border-box--no-margin-top {
				padding-bottom:0;
				margin-top:0;
				
				& + .border-box {
					border-top:0;
				}	
			}

			

			.border-box__content {
				border-top:1px solid #eee;
				padding-bottom:30px;
				padding-top:20px;
			}

			&.collapsed {
				.border-box__content { display:none; }
			}

			&.border-box__step-1 {
				.btn {
					@include media-breakpoint-up(lg) { 
						margin-bottom:15px;
					}
				}
			}
			
			&.border-box__step-2,
			&.border-box__step-3 {
				border-top:0;
				margin-top:0;
				padding-bottom:20px;

				.border-box__content {
					display:none;
					padding-top:20px;
				}
			}

			&.border-box__step-1 {
				margin-top:0;
				padding-bottom:20px;
			}

			&.border-box__step-2 {
				.border-box__title {
					margin-bottom:0;
				}
			}

			&.border-box__step-3 {
				margin-bottom:20px;
			}

			&.disabled {
				pointer-events:none;
				
				.border-box__title {
					border-bottom:0;
					display:block;
					margin-bottom:0;
					opacity:.25;
					padding-bottom:0;
				}
			}

			> * {
				padding:0 15px;
				text-align:left;

				@include media-breakpoint-up(lg) { 
					padding:0 25px;
				}
			}

			&__title {
				border-bottom:1px solid #eee;
				margin-bottom:20px;
				padding-bottom:20px;
			}

			&__title--documentation {
				border-bottom:0;
				cursor:pointer;
				margin-bottom:0;
				padding-bottom:20px;
			}

			&__title--no-border {
				border-bottom:0;
				margin-bottom:0;
				padding-bottom:0;

				> * {
					display:inline-block;
					margin:0 auto;
					vertical-align:middle;
				}

				strong {
					@include media-breakpoint-up(sm) { 
						width:calc(100% - 105px);
					}
				}
			}

			p {
				margin:0;
				max-width:825px;
			}

			.form-group--submit {
				clear:both;
				float:left;
				margin-bottom:0;
				width:100%;
			}

			&__cta {
				margin:25px auto;
				text-align:center;
			}
		}
	}

	&.section--view-lateral {
		margin-bottom:40px;

		@include media-breakpoint-up(lg) { 
			display:inline-block;
			margin-bottom:115px;
			max-width:350px;
			padding-left:0;
			vertical-align:top;
		}

		@include media-breakpoint-up(xl) {
			max-width:480px;
		}
	}

	&.section--summary-content {
		width:100%;
		
		@include media-breakpoint-up(lg) { 
			display:inline-block;
			vertical-align:top;
			max-width:calc(100% - 450px);
		}

		&.full-width {
			max-width:100%;
			padding:0;
		}
	
		.car-eval, .warranty-extra {
			display:none;
		}

		small {
			@include vw-font-size(13px);
			color: #7e7d7d;
			display: block;
		    text-align: left;
		}

		.form { margin-top:0; }
	}

	&.section--summary {
		margin-bottom:20px;
		margin-top:15px;
		width:100%;

		@include media-breakpoint-up(lg) { 
			display:inline-block;
			margin-top:0;
			vertical-align:top;
			max-width:434px;
		}
	}

	&.rate {
		margin-top:15px;
	}

	&.section--container {
		.small-container {
			max-width:100%;

			@include media-breakpoint-up(sm) { 
				max-width:540px;
			}

			@include media-breakpoint-up(md) { 
				max-width:820px;
			}

			@include media-breakpoint-up(lg) { 
				max-width:850px;
			}
		}		
	}
}

.booking-section {
	@include media-breakpoint-up(md) { 
		//overflow:hidden;
	}

	.xs-container {
		padding:0;

		@include media-breakpoint-up(lg) { 
			padding:0 15px;
		}
	}
}