.renting-help {
	text-align:center;
	
	.numeric-book-steps-renting {
		margin:0 auto 15px;
		width:270px;

	}

	.error-msg {
		color:$red;
		@include vw-font-size(14px);
	}

	&__content {

	}

	&__step {
		
		.ranger {

		}

		.rangerslide-container.rangerslide--multiple { border-radius:18px; }

		.img-ranger {
			display:block;
			height:auto;
			margin:0 auto 25px;
			width:160px;
		}

		.noUi-horizontal {
			height:10px;

			@include media-breakpoint-up(md) { 
				height:35px;
			}
		}

		.noUi-connects {
			border-radius:18px;
		}
		
		.noUi-target { border-radius:18px; }


		.noUi-horizontal .noUi-handle {
			height:28px;
			width:28px;

			@include media-breakpoint-up(md) { 
				height:48px;
				top:-7px;
				width:48px;
			}	
		}

		.noUi-horizontal .noUi-handle {
			right: 0 !important;
			left:0 !important;

			@media (min-width:720px) {
    			left: 27px !important;
			}

			@include media-breakpoint-up(md) { 
				left:0 !important;
			}

			@include media-breakpoint-up(lg) { 
				left: 48px !important;
			}
		}

		.noUi-touch-area {
			@include media-breakpoint-up(md) { 
				background: #fff;
			    border-radius: 50%;
			    display: inline-block;
			    height: 31px;
			    margin: 3px auto 0;
			    width: 31px;
			}
		}

		.noUi-connect { background:$purple-light; }

		.rangerslide-container {
			margin-bottom:5px;
			max-width:calc(100% - 80px);

			@include media-breakpoint-up(md) { 
				max-width:calc(100% - 100px);
			}

			[aria-valuenow="100.0"] {
				margin-left:11px;
			}
		}
	}

	&__simulate-range-label {
		align-items:flex-start;
		display:none;
		flex-wrap:nowrap;
		margin:0 auto;
		list-style-type:none;
		padding:0;

		@include media-breakpoint-up(md) { 
			display:flex;
			left:50%;
			position:absolute;
			transform:translate(-50%,0);
			width:109%;
		}

		li {
			@include vw-font-size(15px);
			text-align:center;
			width:20%;

			&:before {
				background:$black;
				content:'';
				display:block;
				height:10px;
				margin:0 auto 8px;
				width:2px;
			}

			&:nth-child(1) {
				/*&:before { margin-left: 62px; }
				span { margin-left:-35px; }*/
			}

			&:nth-child(2) {
				&:before { margin-left:85px; }
				span { margin-left:-9px; }
			}

			&:nth-child(3) {
				&:before { margin-left:89px; }
				span { margin-left:-4px; }
			}

			&:nth-child(4) {
				&:before { margin-left:92px; }
			}

			&:nth-child(5) {
				/*&:before { margin-right:44px; }
				span { margin-right:-85px; }

				@include media-breakpoint-up(lg) { 
					&:before { margin-right:62px; }
					span { margin-right:-50px; }
				}*/
			}
		}
	}

	&__price {
		@include vw-font-size(22px);
		font-weight:bold;
		margin:25px 0 20px;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(27px);
			padding-top:40px;
		}

		b {
			font-weight:700;
		}
	}


	&__form-select { text-align:center; }
	
	.hide { display:none; }

	&__model-list {
		display:flex;
		flex-wrap:wrap;
		list-style-type:none;
		margin:30px auto 20px;
		padding:0;

		li {
			margin-bottom:15px;
			width:100%;

			@include media-breakpoint-up(sm) { 
				margin-bottom:20px;
				padding-right:20px;
				width:50%;
			}

			@include media-breakpoint-up(md) { 
				padding-right:25px;
				width:33.3%;
			}

			&:last-child {
				margin-bottom:0;
			}
		}

		&__model {
			align-items:center;
			display:flex;
			flex-wrap:nowrap;

			&.selected {
				.renting-help__model-list__image:after { background:rgba($purple-light, .2); }
			}

			&.error {
				.renting-help__model-list__image:after { background:rgba($red, .2); }	
			}

			&:hover { text-decoration:none; }
		}

		&__image {
			display:table;
			height:100px;
			position:relative;
			width:100px;

			picture {
				display:table-cell;
				vertical-align:middle;
			}

			img {
				display:inline-block;
				height:auto;
				margin:0 auto;
				position:relative;
				width:83px;
				z-index:1;
			}

			&:after {
				background:#dbdbdb;
				border-radius:50%;
				content:'';
				display:block;
				height:100%;
				left:0;
				overflow:hidden;
				position:absolute;
				top:0;
				transition:$transition-base;
				width:100%;
			}
		}

		&__info {
			padding-left:12px;
			text-align:left;

			strong {
				@include vw-font-size(15px);
				display:block;
				font-weight:bold;
			}

			span {
				@include vw-font-size(13px);
				display:block;
			}
		}
	}

	&__model-list--highlight {
		li {
			margin-bottom:15px;

			@include media-breakpoint-up(sm) { 
				margin-bottom:20px;
				padding-right:20px;
				width:50%;
			}

			&:last-child { padding-right:0; }
		}
	}

	
	.btn-back {
		background:#f4f4f4;
		border-radius:50%;
		display:inline-block;
		margin-right:10px;

		&:hover {
			background:$purple;
			color:$white;
			text-decoration:none;
		}

		&:before {
			line-height:40px;
			margin: 0 auto;
			position:relative;
			text-align:center;
			transform: scale(-1,-1);    	
			width:40px;
			z-index:1;

			@include media-breakpoint-up(md) {
				line-height:50px;
				width:51px;
			}
		}
	}
}