.dropzone {
  background:#f7f7f7;
  border-radius:8px;
  border:0;
  margin-bottom:20px;
  padding:5px;

  .add-list-files__content {
    border:1px dashed #cacaca;
    border-radius:8px;
    display:block;
    height:auto;
    text-align:center;
    width:100%;
  }

  .dz-message {
    color: #3a3a3a;
    font-size: 12px;
    margin: 0 auto;
    opacity: .7;
    padding:20px 15px;
    position:relative;

    &:before {
    	background:url(#{$url-statics}/svg/download-list.svg);
    	content:'';
    	display:inline-block;
    	height:79px;
    	margin:0 auto 10px;
    	width:63px;
    }

    &:after {
    	border:1px dashed #cacaca;
    	border-radius:8px;
    	bottom:5px;
    	content:'';
    	display:block;
    	left:5px;
    	position:absolute;
    	right:5px;
    	top:5px;
    }

    .dz-button {
    	display:block;
    	margin:0 auto;
    }
  }

  .dz-message i {
    display:block;
  }
}

