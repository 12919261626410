.book-steps {
	display:inline-block;
	margin:20px auto;
	overflow:hidden;

	@include media-breakpoint-up(md) { 
		margin:40px auto;
	}

	&__step {
		@include vw-font-size(11px);
		@include vw-line-height(14px);
		color:#777;
		float:left;
		font-family:$font-open-sans;
		position:relative;
		text-align:center;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(14px);
			@include vw-line-height(17px);
		}

		&:after {
			background:$gray-light;
			content:'';
			display:inline-block;
			height:6px;
			margin-top:5px;
			vertical-align:bottom;
			width:calc(100% - 2px);
		}

		&:before {
			background:$purple-light;
			bottom:0;
			content:'';
			display:inline-block;
			height:6px;
			left:1px;
			position:absolute;
			vertical-align:top;
			width:0;
		}

		&.anim {
			&:before {
				transition:$transition-base;
			}
		}

		span {
			padding:0 15px;
		}
	}

	&__step--selected {
		&:before {
			width:calc(100% - 2px);
		}
	}
}