

// Read the article: http://sassbreak.com/viewport-relative-headings-with-sass

// Max breakpoint
$max-breakpoint: 375;

// This could be one of your media query breakpoint variables
$wide-screen: "(min-width: #{$max-breakpoint}px)"; 

// Function
@function get-vw($target) {
  // 1 vw is equal to 1% of the viewport width
  $vw-context: ($max-breakpoint * .01) * 1px; // 1% viewport width
  @return ($target/$vw-context) * 1vw;
}

// Mixin
@mixin vw($size) {
  font-size: get-vw($size);
  // Prevent font-size from getting too big
  @media #{$wide-screen} {
    font-size: $size;
  }
}

@mixin vw-font-size($size) {
  font-size: get-vw($size);

  @media #{$wide-screen} {
    font-size: $size;
  }
}


@mixin vw-line-height($size) {
  line-height: get-vw($size);

  @media #{$wide-screen} {
    line-height: $size;
  }
}

@mixin vw-margin-top($size) {
  margin-top: get-vw($size);

  @media #{$wide-screen} {
    margin-top: $size;
  }
}

@mixin vw-margin-left($size) {
  margin-left: get-vw($size);

  @media #{$wide-screen} {
    margin-left: $size;
  }
}

@mixin vw-margin-bottom($size) {
  margin-bottom: get-vw($size);

  @media #{$wide-screen} {
    margin-bottom: $size;
  }
}

@mixin vw-margin-right($size) {
  margin-right: get-vw($size);

  @media #{$wide-screen} {
    margin-right: $size;
  }
}

@mixin vw-width($size) {
  width: get-vw($size);

  @media #{$wide-screen} {
    width: $size;
  }
}

@mixin vw-height($size) {
  height: get-vw($size);

  @media #{$wide-screen} {
    height: $size;
  }
}

@mixin vw-margin($s1, $s2, $s3, $s4 ) {
  margin: get-vw($s1) get-vw($s2) get-vw($s3) get-vw($s4);

  @media #{$wide-screen} {
    margin: $s1 $s2 $s3 $s4;
  }
}

@mixin vw-left($size) {
  left: get-vw($size);

  @media #{$wide-screen} {
    left: $size;
  }
}

@mixin vw-bottom($size) {
  bottom: get-vw($size);

  @media #{$wide-screen} {
    bottom: $size;
  }
}

@mixin vw-padding-top($size) {
  padding-top: get-vw($size);

  @media #{$wide-screen} {
    padding-top: $size;
  }
}

@mixin vw-padding($s1, $s2, $s3, $s4 ) {
  padding: get-vw($s1) get-vw($s2) get-vw($s3) get-vw($s4);

  @media #{$wide-screen} {
    padding: $s1 $s2 $s3 $s4;
  }
}

@mixin vw-background($size) {
  background-size:  auto get-vw($size);

  @media #{$wide-screen} {
    background-size:  auto $size;
  }
}

@mixin vw-background-position($s1,$s2) {
  background-position: get-vw($s1) get-vw($s2) ;

  @media #{$wide-screen} {
    background-position: $s1 $s2;
  }
}




