// ==========================================================================
// Medium Text
// ==========================================================================


// 1.Config


// 2.Base
.text-medium {
	@include vw-font-size(17px);
	@include vw-line-height(24px);
	color:$color-base;
	display:block;
	font-family:$font-open-sans;
	margin:0 auto 20px;
	
	@include media-breakpoint-up(lg) { 
		@include vw-font-size(20px);
		@include vw-line-height(30px);
	}

	&.bold { font-weight:bold; }
	&.light { font-weight:300; }

	&.white {
		color:$white;
	}

	&.purple {
		color:$purple;
	}

	&.quicksand {
		font-family:$font-quicksand;
	}

	&.no-margin-bottom {
		margin-bottom:0;
	}

	&.half-margin-bottom {
		margin-bottom:10px;
	}

	&.text-medium-max-width { max-width:520px; }
}