// ==========================================================================
// Submenu
// ==========================================================================


// 1.Config



// 2.Base
.submenu {  
    display:none;
    padding:0 0 5px 0;

    @include media-breakpoint-up(md) { 
        display:block;
        left:0;
        opacity:0;
        padding:15px 15px 20px;
        position:absolute;
        right:0;
        //top:$headerHeightSm;
        top:53px;
        transition: opacity .3s linear 0s;
        visibility:hidden;          
        //min-width:250px;
    }

    &__item {
        display:block;

        &:first-child {
            .submenu__link { padding-top:0; }
        }

        @include media-breakpoint-up(md) { 
            &:last-child {
                padding-bottom:0;

                .submenu__link { padding-bottom:0; }
            }
        }
    }

    &__link {
        @include vw-font-size(16px);
        @include vw-line-height(24px);
        color:$white;
        display:inline-block;
        font-family:$font-quicksand;
        font-weight:bold;
        padding:10px;
        position:relative;
        text-align:center;

        @include media-breakpoint-up(md) { 
            @include vw-font-size(14px);
            display:block;
            opacity:.85;
            padding:0 0 15px 0; 
            text-align:left;
        }

        &.menu__link--notification {
            margin-right:-10px;
            padding-right:10px;
        }


        &.ico-arrow-right:before {
            display:none;
            margin-right:10px;
            opacity:0;
            transition:$transition-base;

            @include media-breakpoint-up(md) { 
                display:inline-block;
                margin-right:20px;
            }
        }

        &:hover {
            color:$white;
            text-decoration:none;

            &:before {
                margin-right:10px;
                opacity:1;
            }
        }
    }

    &__link--active {
        &.ico-arrow-right:before {
            display:inline-block;
            margin-right:10px;
            opacity:1;

        }
    }
}

.menu__link--avatar + .submenu {
    @include media-breakpoint-up(md) {
        padding-right:5px;
    }
}