.bar-menu {
	background:#f7f7f7;
	border-radius:23px;
	display:inline-block;
	margin:20px auto;
	overflow:hidden;
	padding:0 !important;
	position:relative;

	&__item {
		@include vw-font-size(16px);
		@include vw-line-height(45px);
		border-radius:23px;
		color:$black;
		display:inline-block;
		font-family:$font-open-sans;
		font-weight:600;
		padding:0 25px;
		position:relative;
		z-index:1;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(18px);
		}

		@include media-breakpoint-up(lg) { 
			padding:0 45px;
		}

		&:hover { text-decoration:none; }

		&.selected {
			color:$white;
			transition-delay:.1s;
		}
	}

	&__item--init {
		background: $purple-light;
		color:$white;
	}

	.magic-line {
		border-radius: 23px;
		background: $purple-light;
		margin:0;
		left: 0;
		list-style-type:none;
		padding:0;
		position: absolute;
	    top: 0;		    
	    transition: all .4s ease-in-out;
	    width: 100px;
	}
}