//
// Buttons
// --------------------------------------------------

// 1.Config


// 2.Base styles
// --------------------------------------------------

.btn {
    @include vw-font-size(17px);
    @include vw-line-height(40px);
    background:$indigo;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border-radius:10px;
    color:$white;
    cursor: pointer;
    display: inline-block;
    //font-family:$font-open-sans;
    font-family:$font-quicksand;
    font-weight:bold;
    margin-bottom: 0; // For input.btn
    padding: 0 10px;
    //padding:7px 20px;
    position:relative;
    text-align: center;
    touch-action: manipulation;
    transition:$transition-base;
    vertical-align: middle;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
        min-height: 50px;
        padding: 5px 10px;
    }

    &.btn--small {
        @include vw-font-size(14px);
        line-height: 20px;
        white-space: normal;
    }

    &.btn-purple-light {
        //@include vw-line-height(36px);
        background:$purple-light;
        border:2px solid $purple-light;
        color:$white;
        padding-bottom:3px;
        padding-top:3px;

        &:hover {
            background:$indigo;
            border-color:$indigo;
            color:$white;

            &:before { color:$white; }
        }
    }    

    &.btn-purple {
        //@include vw-line-height(36px);
        background:$indigo;
        border:2px solid $indigo;
        color:$white;
        padding-bottom:3px;
        padding-top:3px;

        &:hover {
            background:$purple-light;
            border-color:$purple-light;
            color:$white;

            &:before { color:$white; }
        }
    }     

    &.btn-no-arrow {
        padding-right:20px;

        &:after { display:none; }
    }

    &.btn-large {
        @include vw-font-size(17px);
        //@include vw-line-height(25px);
        font-weight:500;
       //padding:7px 20px;

        @include media-breakpoint-up(sm) { 
            min-width:220px;
        }

        @include media-breakpoint-up(md) { 
            @include vw-font-size(21px);
            //@include vw-line-height(29px);
            //padding:12px 25px;
        }
    }

    &.btn-facebook {
        background:#4568b2;
        border:2px solid #4568b2;
        color:$white;
        font-family:$font-quicksand;
        margin-bottom:20px;
        //padding:7px 15px;
       
        &:before {
            @include vw-font-size(30px);
            display:inline-block;
            transition:0s;
            vertical-align:middle;
        }
        
        &:after { display:none; }

        @include media-breakpoint-up(md) { 
            @include vw-font-size(18px);
            //padding:8px 25px;
        }
        
        &:hover {
            background:$white;
            border-color:#4568b2;
            color:#4568b2;

            &:before { color:#4568b2; }
        }
    }

    &.btn-weight { font-weight:500; }

    &.btn-border {
        //@include vw-line-height(36px);
        background:$white;
        border:2px solid $black;
        color:$black;
        padding-bottom:3px;
        padding-top:3px;

        &:before {
            color:$indigo;
        }

        &:hover {
            background:$indigo;
            border-color:$indigo;
            color:$white;

            &:before {
                color:$white;
            }
        }
    }

    &.btn-google {
        background:$white;
        border:2px solid $black;
        color:$black;
        font-family:$font-quicksand;
        //padding:7px 15px;
       
        &:before {
            @include vw-font-size(22px);
            display:inline-block;
            margin-right:7px;
            transition:0s;
            vertical-align:middle;
            width:auto;
        }
        
        &:after { display:none; }

        @include media-breakpoint-up(md) { 
            @include vw-font-size(18px);
            //padding:9px 25px;
        }

        &:hover {
            background:$black;
            border-color:$black;
            color:$white;

            &:before { color:$white; }
        }
    }

    &.btn-transparent {
        background:none;
        color:$black;

        &:hover {
            background:none;
            color:$black;
        }
    }

    &.btn-white-transparent {
        background:none;
        border-color:$white;
        color:$white;

        &:hover {
            background:$white;
            color:$black;
        }
    }

    &.ico {
        padding-right:30px;

        @include media-breakpoint-up(md) {
            padding-right:40px;
        }
        
        &:before {
            @include vw-font-size(14px);
            display:inline-block;
            position:absolute;
            right:7px;
            top:50%;
            transform:translate(0, -50%);
            transition:0s;

            @include media-breakpoint-up(md) {
                right:17px;
            }
        }
    }

    &.btn-help {
        background:url(#{$url-statics}/svg/chat.svg) left 11px center no-repeat $purple-light;
        background-size:25px;
        border:2px solid $white;
        box-shadow:1px 0px 10px rgba($black,.2);
        font-family:$font-quicksand;
        font-weight:normal;
        padding-left:45px;

        &:hover {
            background:url(#{$url-statics}/svg/chat.svg) left 11px center no-repeat $purple-light;
            background-size:25px;
            color:$white;
            opacity:.9;

            @include media-breakpoint-up(md) { 
                 background-size:35px;
            }
        }

        @include media-breakpoint-up(md) { 
            @include vw-font-size(18px);
             background-size:35px;
            //padding:15px 15px 15px 55px;
            padding-left:55px;
        }
    }

    &.btn-margin-bottom {
        margin-bottom:15px;
    }

    &:hover {
        background:$white;
        color:$indigo;

        &:before {
            color:$indigo;
        }
    }

    &:focus {
        box-shadow:none !important;
    }

    &.btn-inverse {
        background:$white;
        color:$indigo;

        &:before {
            color:$indigo;
        }

        &:hover {
            background:$indigo;
            color:$white;

            &:before {
                color:$white;
            }
        }
    }

    &.btn-white {
        background:$white;
        border:2px solid $black;
        color:$black;
        //padding:5px 15px;

        &:hover {
            background:$indigo;
            border-color:$indigo;
            color:$white;
        }
    }

    &.btn-ok {
        background:$white;
        border-color:$green;
        color:$green;

        &.ico-check:before {
            @include vw-font-size(20px);
            color:$green;
        }

        span {
            display:inline-block;
            width:calc(100% - 40px);
        }

        &:hover {
            background:$green;
            border-color:$green;
            color:$white;

            &.ico-check:before {
                color:$white;
            }
        }
    }

    &.btn-disabled {
        background:transparent;
        border-color:#e6e6e6;
        color:#e6e6e6;
        cursor:default;
        pointer-events:none;

        &:hover {
            background:transparent;
            border-color:#e6e6e6;
            color:#e6e6e6;            
        }
    }

    &.quicksand { font-family:$font-quicksand; }

    
    &:hover,
    &:focus,
    &.focus {
        @include box-shadow(none);
        text-decoration: none;
    }

    &:active,
    &.active {
        outline: 0;
        background-image: none;
        //@include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor:disabled;
        pointer-events: none; // Future-proof disabling of clicks
        opacity:0.65;
        @include box-shadow(none);
    }
}

    .btn + .btn {
        vertical-align:top;

        @include media-breakpoint-up(md) { 
            margin-left:5px;
        }
    }

    // Block button
    // --------------------------------------------------

    .btn-block {
        display: block;
        margin-left:auto !important;
        margin-right:auto  !important;
        width: 100%;
    }

    // Vertically space out multiple block buttons
    .btn-block + .btn-block {
        margin-top: 5px;
    }

    // Specificity overrides
    input[type="submit"],
    input[type="reset"],
    input[type="button"] {
        &.btn-block {
        width: 100%;
        }
    }

.agroup-buttons {
    .btn {
    /*
        @include vw-font-size(17px);
        @include vw-line-height(25px);*/
        font-weight:500;
        margin:5px 3px 5px;
        min-width:220px;
       // padding:7px 20px;

        @include media-breakpoint-up(sm) { 
            min-width:0;
        }

        /*@include media-breakpoint-up(md) { 
            @include vw-font-size(16px);
            @include vw-line-height(29px);
        }
*/
        @include media-breakpoint-up(xl) { 
           /* @include vw-font-size(21px);
            @include vw-line-height(29px);*/
            min-width:220px;
            //padding:12px 25px;
        }
    }
}

.btn-help-agroup {
    margin:20px auto 0;
    text-align:center;

    @include media-breakpoint-up(md) {
        margin:35px auto 0;

        &.btn-help-agroup--extra-m-top { margin-top:60px; }
    }

    &.btn-help-agroup--small-margin-top {
        margin:15px auto;
    }

    &.btn-help-agroup--no-margin { margin:0 auto; }
}