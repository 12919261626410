// ==========================================================================
// Main Slide
// ==========================================================================

// 1.Config


// 2.Base
.slide {
	position:relative;

	&:after, &:before {
		content:'';
		display:block;
		height:100%;
		position:absolute;
		top:0;
		bottom:0;
		width:50px;
		z-index:2;

		@include media-breakpoint-up(md) { 
			width:70px;
		}
	}

	&:after {
		background: rgba(247,247,247,1);
		background: -moz-linear-gradient(left, rgba($white,0) 0%, rgba(247,247,247,1) 100%);
		background: -webkit-gradient(left top, right top, color-stop(0%, rgba(247,247,247,1)), color-stop(100%, rgba($white,0)));
		background: -webkit-linear-gradient(left, rgba($white,0) 0%, rgba(247,247,247,1) 100%);
		background: -o-linear-gradient(left, rgba($white,0) 0%, rgba(247,247,247,1) 100%);
		background: -ms-linear-gradient(left, rgba($white,0) 0%, rgba(247,247,247,1) 100%);
		background: linear-gradient(to right, rgba($white,0) 0%, rgba(247,247,247,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#f7f7f7', GradientType=1 );
		right:0;
	}

	&:before {
		background: rgba(247,247,247,1);
		background: -moz-linear-gradient(left, rgba(247,247,247,1) 0%, rgba($white,0) 100%);
		background: -webkit-gradient(left top, right top, color-stop(0%, rgba(247,247,247,1)), color-stop(100%, rgba($white,0)));
		background: -webkit-linear-gradient(left, rgba(247,247,247,1) 0%, rgba($white,0) 100%);
		background: -o-linear-gradient(left, rgba(247,247,247,1) 0%, rgba($white,0) 100%);
		background: -ms-linear-gradient(left, rgba(247,247,247,1) 0%, rgba($white,0) 100%);
		background: linear-gradient(to right, rgba(247,247,247,1) 0%, rgba($white,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#fefefe', GradientType=1 );
		left:0;
	}

	.arrow {
		background-size:contain;
		display:block;
		height:25px;
		margin-top:-25px;
		top:auto;
		width:26px;

		&.arrow-left {
			background-image:url(#{$url-statics}/svg/arrow-left.svg);
		}

		&.arrow-right {
			background-image:url(#{$url-statics}/svg/arrow-right.svg);	
		}
	}


	.btn {
		@include vw-line-height(30px);
		font-weight:400;
		line-height: 30px;
		min-height: 40px;
	    padding-left: 15px;
	    padding-right: 15px;

	    @include media-breakpoint-up(md) { 
	    	padding-left: 25px;
	    	padding-right: 25px;
	    }
	}
}

.slide--white {
	
	&:after {
		background: rgba($white,1);
		background: -moz-linear-gradient(left, rgba($white,0) 0%, rgba($white,1) 50%);
		background: -webkit-gradient(left top, right top, color-stop(0%, rgba($white,1)), color-stop(50%, rgba($white,0)));
		background: -webkit-linear-gradient(left, rgba($white,0) 0%, rgba($white,1) 100%);
		background: -o-linear-gradient(left, rgba($white,0) 0%, rgba($white,1) 50%);
		background: -ms-linear-gradient(left, rgba($white,0) 0%, rgba($white,1) 50%);
		background: linear-gradient(to right, rgba($white,0) 0%, rgba($white,1) 50%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
		right:0;
	}

	&:before {
		background: rgba($white,1);
		background: -moz-linear-gradient(left, rgba($white,1) 50%, rgba($white,0) 100%);
		background: -webkit-gradient(left top, right top, color-stop(50%, rgba($white,1)), color-stop(100%, rgba($white,0)));
		background: -webkit-linear-gradient(left, rgba($white,1) 50%, rgba($white,0) 100%);
		background: -o-linear-gradient(left, rgba($white,1) 50%, rgba($white,0) 100%);
		background: -ms-linear-gradient(left, rgba($white,1) 50%, rgba($white,0) 100%);
		background: linear-gradient(to right, rgba($white,1) 50%, rgba($white,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
		left:0;
	}
}

.slide-cars {
	margin:20px auto;

	@include media-breakpoint-up(md) { 
		margin:50px auto 20px;
	}

	.swiper-container {
		width:95%;

		@include media-breakpoint-up(md) { 
			width:97%;
		}
	}

	.swiper-slide {
		box-sizing:border-box;
		height:auto;
		padding:0 10px;

		@include media-breakpoint-up(md) { 
			padding:0 15px;
		}
	}

	.car-list__item {
		background:$white;
		min-height:100%;
		width:100%;
	}

	.swiper-pagination {
		margin-top:35px;
		position:relative;
	}
}

.slide-fade {

	&:after, &:before {
		display:none;
	}
}

.slide--info { 
	overflow:hidden;

	.slide__content {
		position:relative;

		&:after, &:before {
			content:'';
			display:block;
			height:100%;
			position:absolute;
			top:0;
			bottom:0;
			width:50px;
			z-index:2;

			@include media-breakpoint-up(md) { 
				width:70px;
			}
		}

		&:after {
			background: rgba($white,1);
			background: -moz-linear-gradient(left, rgba($white,0) 0%, rgba($white,1) 100%);
			background: -webkit-gradient(left top, right top, color-stop(0%, rgba($white,1)), color-stop(100%, rgba($white,0)));
			background: -webkit-linear-gradient(left, rgba($white,0) 0%, rgba($white,1) 100%);
			background: -o-linear-gradient(left, rgba($white,0) 0%, rgba($white,1) 100%);
			background: -ms-linear-gradient(left, rgba($white,0) 0%, rgba($white,1) 100%);
			background: linear-gradient(to right, rgba($white,0) 0%, rgba($white,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
			right:-2px;
		}

		&:before {
			background: rgba($white,1);
			background: -moz-linear-gradient(left, rgba($white,1) 0%, rgba($white,0) 100%);
			background: -webkit-gradient(left top, right top, color-stop(0%, rgba($white,1)), color-stop(100%, rgba($white,0)));
			background: -webkit-linear-gradient(left, rgba($white,1) 0%, rgba($white,0) 100%);
			background: -o-linear-gradient(left, rgba($white,1) 0%, rgba($white,0) 100%);
			background: -ms-linear-gradient(left, rgba($white,1) 0%, rgba($white,0) 100%);
			background: linear-gradient(to right, rgba($white,1) 0%, rgba($white,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
			left:-2px;
		}
	}

	&.slide-no-shadow {
		.slide__content {
			&:after, &:before { display:none; }
		}
	}
}

.rate-slide {
	&:after, &:before {
		display:none;
	}

	.slide__content {
		position:relative;

		&:after, &:before {
			content:'';
			display:block;
			height:100%;
			position:absolute;
			top:0;
			bottom:0;
			width:50px;
			z-index:2;

			@include media-breakpoint-up(md) { 
				width:70px;
			}
		}

		&:after {
			background: rgba($white,1);
			background: -moz-linear-gradient(left, rgba($white,0) 0%, rgba($white,1) 100%);
			background: -webkit-gradient(left top, right top, color-stop(0%, rgba($white,1)), color-stop(100%, rgba($white,0)));
			background: -webkit-linear-gradient(left, rgba($white,0) 0%, rgba($white,1) 100%);
			background: -o-linear-gradient(left, rgba($white,0) 0%, rgba($white,1) 100%);
			background: -ms-linear-gradient(left, rgba($white,0) 0%, rgba($white,1) 100%);
			background: linear-gradient(to right, rgba($white,0) 0%, rgba($white,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
			right:-2px;
		}

		&:before {
			background: rgba($white,1);
			background: -moz-linear-gradient(left, rgba($white,1) 0%, rgba($white,0) 100%);
			background: -webkit-gradient(left top, right top, color-stop(0%, rgba($white,1)), color-stop(100%, rgba($white,0)));
			background: -webkit-linear-gradient(left, rgba($white,1) 0%, rgba($white,0) 100%);
			background: -o-linear-gradient(left, rgba($white,1) 0%, rgba($white,0) 100%);
			background: -ms-linear-gradient(left, rgba($white,1) 0%, rgba($white,0) 100%);
			background: linear-gradient(to right, rgba($white,1) 0%, rgba($white,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
			left:-2px;
		}
	}
}