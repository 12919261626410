// ==========================================================================
// Pagination
// ==========================================================================


// 1.Config



// 2.Base
.pagination {
	display:block;
	margin:20px auto 30px;
	padding:0 15px;
	text-align:center;

	&__item {
		@include vw-font-size(14px);
		background:$purple;
		border-radius:8px;
		color:$white;
		display:inline-block;
		font-family:$font-open-sans;
		font-weight:bold;
		line-height:36px;
		margin:0 2px;
		overflow:hidden;
		text-align:center;
		width:36px;

		@include media-breakpoint-up(md) {
			@include vw-font-size(16px);
			line-height:43px;
			width:43px;			
		}

		&.disabled {
			background:#f7f7f7;
			color:$purple-light;
		}
	}

	&__link {
		background:#f7f7f7;
		color:$purple-light !important;		
		cursor:pointer;
		display:block;
		line-height:36px;
		transition:$transition-base;

		&:hover {
			background:$black;
			color:$white !important;
			text-decoration:none;
		}

		@include media-breakpoint-up(md) {
			line-height:43px;
			width:43px;			
		}
	}

	.ico-left, .ico-right {
        &:hover {
        	&:after {
        		border-top-color:$white;
        		border-right-color:$white;
        	}
        }
	}

	.ico-right {
		&:after { transform:rotate(45deg); }
	}
}