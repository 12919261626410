// Fix IE issues

body.IE {
	overflow-x:hidden;

	.compat-object-fit {
		background-position:center;
		background-size:cover;
		height:100%;
		left:0;
		position:absolute;
		top:0;
		width:100%;
		z-index:0;

		img {
			position:relative;
			visibility:hidden;
		}
	}

	.number-spin-btn-container { display:none !important; }

	.custom-fancy-content .custom-scrollbar {
		max-height:80vh;
		overflow-y:auto;
		padding:0 10px;
	}

	.renting-help__simulate-range-label { width:91%; }

	.landing-header .go-to-anchor { display:none; }
}