// ==========================================================================
// Title
// ==========================================================================


// 1.Config


// 2.Base
.title {
	@include vw-font-size(27px);
	@include vw-line-height(37px);
	color:$black;
	display:block;
	font-family:$font-quicksand;
	font-weight:bold;
	margin:0 auto 20px;
	
	@include media-breakpoint-up(md) { 
		@include vw-font-size(46px);
		@include vw-line-height(56px);
	}

	&.white {
		color:$white;
	}


	&.black {
		color:$black;
	}

	&.text-shadow {
		text-shadow: 2px 2px 6px rgba($black, 0.3);
	}

	&.no-margin-bottom {
		margin-bottom:0;
	}

	&.small-margin-bottom {
		margin-bottom:10px;
	}

	&.open-sans {
		font-family:$font-open-sans;
		letter-spacing:-.5px;
	}
}


.title-mid {
	@include vw-font-size(27px);
	@include vw-line-height(37px);
	color:$purple;
	display:block;
	font-family:$font-quicksand;
	font-weight:bold;
	margin:0 auto 20px;
	
	@include media-breakpoint-up(md) { 
		@include vw-font-size(40px);
		@include vw-line-height(50px);
	}

	&.white {
		color:$white;
	}


	&.black {
		color:$black;
	}

	&.text-shadow {
		text-shadow: 2px 2px 6px rgba($black, 0.3);
	}

	&.no-margin-bottom {
		margin-bottom:0;
	}

	&.small-margin-bottom {
		margin-bottom:10px;
	}

	&.open-sans {
		font-family:$font-open-sans;
		letter-spacing:-.5px;
	}
}