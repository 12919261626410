// ==========================================================================
// Image square list
// ==========================================================================


// 1.Config


// 2.Base

.img-square-list {
	display: flex;
	flex-wrap:wrap;
  	justify-content:space-between;	
  	margin:20px auto 0;
  	max-width:1440px;
	padding:0 20px;
	text-align:center;

	@include media-breakpoint-up(md) { 
		margin-top:30px;
	}

	@include media-breakpoint-up(lg) { 
		margin-bottom:20px;
		margin-top:60px;
	}

	&__item {
		display:block;
		margin-bottom:20px;
		text-align:center;
		vertical-align:top;
		width:100%;

		@include media-breakpoint-up(sm) { 
			margin-bottom:2%;
			width:49%;
		}

		@include media-breakpoint-up(md) { 
			margin-bottom:1.5%;
			width:32%;
		}

		&:last-child { margin-bottom:0; }
	}

	&__link {
		display:block;
		overflow:hidden;
		position:relative;

		/*&:before {
			background:linear-gradient(to bottom, rgba(72,76,97,0) 0%, rgba(#000,.3) 75%);
			content:'';
			display:block;
			height:100%;
			left:0;
			position:absolute;
			top:0;
			transform: translate3d(0,50%,0);
			transition: opacity 0.35s, transform 0.35s;
			width:100%;	
		}*/

		&:hover {
			&:before {
				transform: translate3d(0,0,0);
			}

			.image-cover {

				&:before, &:after {
					opacity: 1;
					transform: scale(1);
				}

				img {
					opacity:.75;
					transform: scale(1);
				}
			}

			.img-square-list__info {
				transform: translate(-50%, -50%) translate3d(0, -10px, 0);
			}
		}
	}

	&__info {
		@extend %centered;
		padding:0 20px;
		text-shadow:2px 2px 6px rgba($black, 0.3);
		transition:transform .35s;
		transform: translate(-50%, -50%) translate3d(0,0,0);
		width:100%;
	}

	&__title {
		@include vw-font-size(24px);
		@include vw-line-height(22px);
		color:$white;
		display:block;
		font-family:$font-quicksand;
		font-weight:bold;
		margin:0 auto 10px;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(30px);
			@include vw-line-height(28px);
		}

		@include media-breakpoint-up(xl) { 
			@include vw-font-size(40px);
			@include vw-line-height(38px);
		}
	}

	&__text {
		@include vw-font-size(17px);
		@include vw-line-height(24px);
		color:$white;
		display:block;
		font-family:$font-open-sans;
		margin:0 auto;
		
		@include media-breakpoint-up(lg) { 
			@include vw-font-size(18px);
			@include vw-line-height(21px);
		}

		@include media-breakpoint-up(xl) { 
			@include vw-font-size(20px);
			@include vw-line-height(22px);
		}
	}

	&__image {
		display:block;
		height:auto;
		transition:$transition-base;
		width:100%;
	}

	.image-cover {
		background:#000;

		&:before {
			border:2px solid $white;
			position: absolute;
			top: 15px;
			right: 15px;
			bottom: 15px;
			left: 15px;
			content: '';
			opacity: 0;
			transform:scale(1.1);
			transition: opacity 0.35s, transform 0.35s;
			z-index:1;

			@include media-breakpoint-up(xl) { 
				top: 30px;
				right: 30px;
				bottom: 30px;
				left: 30px;
			}
		}

		img {
			opacity:.8;
			transform:scale(1.12);
			//transition: opacity 0.35s, -webkit-transform 0.35s;
		}
	}
}

body:not(.desktop) {
	.img-square-list__link {
		&:before {
			transform: translate3d(0,0,0);
		}

		.image-cover {
			&:before, &:after {
				opacity: 1;
				transform: scale(1);
			}
		}
	}
}