.renting-banner {
	min-height:220px;
	overflow:hidden;
	padding:0;
	position:relative;

	&__figure {
		img {
			display:block;
			height:100%;
			left:0;
			object-fit:cover;
			position:absolute;
			top:0;
			width:100%; 
		}
	}

	&__content {
		align-items:center;
		display:flex;
		margin:0 auto;
		max-width:1326px;
		min-height:inherit;
		padding:0 15px;

		&:before {
			background:rgba(0, 0, 0, .5);
			bottom:0;
			content:'';
			display:block;
			left:0;
			position:absolute;
			top:0;
			width:100%;

			@include media-breakpoint-up(sm) { 
				width:calc(80% - 70px);
			}

			@include media-breakpoint-up(md) { 
				width:calc(50% - 70px);
			}
		}

		&:after {
			border-top: 0 solid transparent;
		 	border-bottom: 245px solid transparent;
		  	border-left: 80px solid rgba(0, 0, 0, .5);
			bottom:0;
			display:block;
			left:calc(50% - 70px);
			position:absolute;

			@include media-breakpoint-up(sm) { 
				content:'';
				left:calc(80% - 70px);
			}

			@include media-breakpoint-up(md) { 
				left:calc(50% - 70px);
			}
		}
	}

	&__info {
		max-width:510px;
		padding:20px 0;
		position:relative;
		text-align:left;
		width:100%;

		@include media-breakpoint-up(sm) { 
			width:calc(80% - 70px);
		}

		@include media-breakpoint-up(md) { 
			width:calc(50% - 70px);
		}
	}

	&__title {
		margin-bottom:0 !important;
	}

	&__description {

	}
}