// ==========================================================================
// Breadcrumb
// ==========================================================================


// 1.Config


// 2.Base
.breadcrumb {
	border-bottom:1px solid #e6e6e6;
	display:inline-block;
	margin:0 auto 15px;
	//padding:$headerHeight 0 0;
	padding:90px 0 0;
	text-align:left;

	&__item {
		@include vw-font-size(12px);
		@include vw-line-height(16px);
		display:inline-block;
		font-family:$font-open-sans;
		margin-right:3px;
		font-weight:600;
		padding-right:15px;
		position:relative;

		&:before {
			color:#b0b0b0;
			content:'>';
			display:block;
			position:absolute;
			right:0;
			top:50%;
			transform:translate(0,-50%);
		}

		&:last-child {
			padding-right:0;

			&:before { display:none; }
		}

		span {
			color:$black;
		}

		a, a span {
			color:#b0b0b0;

			&:hover {
				color:$purple-light;
				text-decoration:none;
			}
		}
	}
}

.main--header-discount-bar {
	.breadcrumb {
		padding:120px 0 0;
	}
}