// ==========================================================================
// Global Config
// ==========================================================================


$version-statics: "1.0";
$url-statics: "../";
$image-path: "images";

/************************************/

$white:    #fff !default;
$gray:     #b0b0b0 !default;
$gray-light: #f7f7f7 !default;
$gray-100: #f7f7f7 !default;
$gray-200: #EEEBE6 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #4A4A4A !default;
$gray-800: #343a40 !default;
$gray-900: #000 !default;
$black:    #3a3a3a !default;
$color-base : #3a3a3a !default;

$blue:    #001489 !default;
$indigo:  #8F61FF !default;
$purple:  #71A4FF !default;
$purple-light:  #8f61ff !default;
$pink:    #e83e8c !default;
$red:     #b0061b !default;
$orange:  #fd7e14 !default;
$yellow:  #bcbf6b !default;
$green:   #bcc070 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$pastel-blue: #ababf5 !default;
$green: #bcbf6b !default;
$pastel-red: #f23a49 !default;


/************************************/

$primary:       $black !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

/************************************/


$font-regular:        'Quicksand', sans-serif!default;
$font-title:          'Quicksand', sans-serif!default;

$font-open-sans:        'Quicksand', sans-serif!default;
$font-quicksand:        'Quicksand', sans-serif!default;


$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-base:            $font-regular;



/************************************/

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;

/************************************/

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 992px,
  lg: 1280px,
  xl: 1520px,
  xxl: 1750px,
) !default;


$container-max-widths: (
  sm: 540px,
  md: 820px,
  lg: 1014px,
  xl: 1440px
) !default;

/************************************/

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;

/************************************/

$z-indexes: (
  'header'  : 102,
  'navbar'  : 101,
  'footer'  : 100,
  'lightbox': 102
);

/************************************/

$headerHeight: 60px;
$headerHeightSm: 80px;



