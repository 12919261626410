.m-lightbox {
	background:$white;
	border-radius:8px;
	left:50%;
	max-width:550px;
	padding:50px 20px 35px;
	position:absolute;
	text-align:center;
	top:50%;
	transform:translate(-50%,-50%);
	width:95%;

	@include media-breakpoint-up(md) {
		padding:70px 45px;
	}

	&__close, .modal-close-ico {
		background:url(#{$url-statics}/svg/icon-close.svg) center no-repeat;
		background-size:contain;
		border:0;
		cursor:pointer;
		display:block;
		height:40px;
		opacity:1;
		position:absolute;
		right:5px;
		top:5px;
		width:46px;

		&:focus { outline:none; }

		@include media-breakpoint-up(md) {
			right:15px;
			top:15px;
		}

		&:hover {
			opacity:.75;
		}

	}

	&__title {

	}

	&__content {

	}

	&__text {
		@include vw-font-size(15px);
		@include vw-line-height(22px);
		
		@include media-breakpoint-up(md) { 
			@include vw-font-size(18px);
			@include vw-line-height(24px);
		}
	}

	&__buttons {
		display:block;
		margin-top:20px;
		text-align:center;

		@include media-breakpoint-up(md) {
			margin-top:40px;
		}

		.btn {
			@include vw-line-height(30px);
			display:inline-block;
			font-weight:normal;
			vertical-align:middle;
			margin-right:1%;
			width:48%;

			@include media-breakpoint-up(md) { 
				@include vw-font-size(18px);
				@include vw-line-height(36px);
			}

			&:last-child { margin-right:0%; }

			&:focus {
				box-shadow:none;
				outline:none;
			}
		}
	}
}

.m-lightbox-overlay {
	background:rgba(0,0,0,.9);
	bottom:0;
	left:0;
	position:fixed;
	right:0;
	top:0;
	z-index: z('lightbox');
}