.testimonials-list {
	margin:20px auto 0;
	max-width:910px;
	position:relative;
	text-align:center;

	&__item {
		background:#f7f7f7;
		border-bottom:15px solid $white;
		display:inline-block;
		margin:0 auto;
		padding:25px 20px;
		vertical-align:top;
		width:100%;

		@include media-breakpoint-up(md) { 
			border:7px solid $white;			
			padding:36px;
			width:49%;
		}

		@include media-breakpoint-up(xl) { 
			border-width:15px;
		}
	}

	.rating {
		display:inline-block;
		margin:0 auto 10px;
		text-align:center;

		@include media-breakpoint-up(md) { 
			margin-bottom:15px;
		}
	}

	.rating > .rating:checked ~ .star, .rating > .ratingStar:checked ~ .star {
		color:$yellow;
	}

	&__comment {
		@include vw-font-size(15px);
		display:block;
		margin:0 auto;
		text-align:left;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(16px);
		}

		&:before {
			content:"“";
			display:inline-block;
		}

		&:after {
			content:"”";
			display:inline-block;
		}
	}

	&__who {
		@include vw-font-size(15px);
		border-top:1px solid $gray-light;
		color:$yellow;
		display:block;
		font-weight:bold;
		margin-bottom:0;
		margin-top:20px;
		padding-top:20px;
		text-align:left;
		text-transform:uppercase;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(16px);
		}
	}

	&__name {
		@include vw-font-size(14px);
		@include vw-line-height(24px);
		letter-spacing:.38px;
		text-align:left;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(15px);
		}
	}
}