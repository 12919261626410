.section.error {
	align-items: center;
	display: flex;
	height:auto;
	justify-content: center;
	min-height:65vh;

	img {
		display:inline-block;
		height:auto;
		width:200px;
	}

	.btn {
		margin-top:20px;
	}
}