// ==========================================================================
// Car list
// ==========================================================================


// 1.Config


// 2.Base
.car-list--horizontal {
	margin-top:0;
		
	.car-list__item {
		border:0;
		border-bottom:1px solid #e6e6e6;
		display:block;
		margin:0 auto 20px;
		overflow:hidden;
		padding-bottom:20px;
		width:100%;

		&:last-child {
			margin-bottom:0;
		}
	}

	.car-list__figure {
		display:block;
		margin-bottom:20px;
		overflow:hidden;

		@include media-breakpoint-up(md) {
			display:inline-block;
			margin-bottom:0;
			vertical-align:top;
			width:220px;
		}
	}

	.car-list__image {
		border-radius:6px;
		overflow:hidden;
	}

	.car-list__info {
		padding:0;
		width:100%;

		@include media-breakpoint-up(md) {
			float:right;
			padding-left:20px;
			width:calc(100% - 230px)
		}

		@include media-breakpoint-up(lg) {
			display:inline-block;
			float:none;
			padding:0 20px;
			vertical-align:middle;
			width:calc(50% - 115px);
		}

		&:last-child {
			margin-top:20px;
			position:relative;	

			@include media-breakpoint-up(md) {
				margin-top:0;
			}	

			@include media-breakpoint-up(lg) {
				padding:0 0 0 20px;
			}
		}
	}

	.car-list__info--bottom {
		text-align:right;
		vertical-align:bottom;

		.car-list__buttons, .car-list__buttons a { float:none; }
	}

	.car-list__prices {
		bottom:auto;
		left:auto;
		padding:0;
		position:relative;

		.car-list__block {
			> div {
				@include media-breakpoint-up(lg) {
					@include vw-font-size(23px);
					@include vw-line-height(32px);

					b {
						@include vw-font-size(30px);
						@include vw-line-height(41px);
					}
				}
			}
		}
	}

	.car-list__buttons {
		clear:both;
		float:left;
		margin-top:20px;
		text-align:right;
		width:100%;

		@include media-breakpoint-up(lg) {
			margin-top:40px;
			padding-left:30px;
		}

		@include media-breakpoint-up(xl) {
			padding-right:15px;
		}

		> a {
			//float:left;
//			width:48%;
		}

		a + a {
			float:right;
		}
	}

	.car-list__title {
		color:$black;

		@include media-breakpoint-up(lg) {
			@include vw-font-size(24px);
			@include vw-line-height(33px);
			margin-bottom:0;
		}

		&:hover {
			color:$purple;
		}
	}

	.car-list__call {
		display:block;
		padding-left:30px;
		position:relative;

		&:before {
			border-radius:50%;
			background:$yellow;
			content:'';
			display:inline-block;
			height:18px;
			left:0;
			position:absolute;
			top:2px;
			vertical-align:top;
			width:18px;
		}

		b {
			display:block;
		}

	}
}
