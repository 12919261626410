// ==========================================================================
// Small Text
// ==========================================================================


// 1.Config


// 2.Base
.text-small {
	@include vw-font-size(15px);
	@include vw-line-height(22px);
	color:$color-base;
	display:block;
	font-family:$font-open-sans;
	
	&.white {
		color:$white;
	}

	&.yellow {
		color:$yellow;
	}

	&.purple {
		color:$purple;
	}
}