.dni-scan {
	margin:0 auto;
	max-width:600px;
	padding:0 15px;

	&__image {
		display:block;
		height:auto;
		margin:15px auto;
		width:56px;
	}

	.form {
		margin:20px auto;
		max-width:280px;

		.form-group--submit { margin:0 auto; }
	}

	.form-table__info--status {
		@include vw-font-size(14px);
		@include vw-line-height(20px);
		display:block;
		font-family:$font-quicksand;
		font-weight:bold;
		margin-bottom:10px;
		text-align:right;
		width:100%;

		.purple { color:$purple; }
		.green { color:$green; }
		.red { color:$red; }
		.orange { color:$orange; }
	}

	.dni-ok {
		margin:5px auto;
		max-width:280px;
		overflow:hidden;

		.green-btn {
			@include vw-font-size(14px);
			@include vw-line-height(20px);
			background:$green;
			border-radius:4px;
			color:$white;
			display:block;
			float:right;
			font-family:$font-quicksand;
			padding:2px 15px;
			margin-bottom:10px;
		}

		img {
			border:1px solid $gray-light;
			border-radius:6px;
			display:block;
			height:auto;
			margin:5px auto;
			padding:10px;
			width:100%;
		}
	}

	.dni-ok + .form { margin-top:10px; }

	.margin-top {
		margin-top:20px; 

		@include media-breakpoint-up(md) { 
			margin-top:40px;
		}
	}
}
