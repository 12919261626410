// ==========================================================================
// Ico list
// ==========================================================================


// 1.Config


// 2.Base
.ico-list {
	margin:20px auto 0;
	padding:0;

	@include media-breakpoint-up(md) { 
		margin-top:30px;
	}

	@include media-breakpoint-up(lg) { 
		margin-bottom:20px;
		margin-top:60px;
	}

	&__item {
		display:inline-block;
		margin-bottom:20px;
		vertical-align:top;

		@include media-breakpoint-up(sm) { 
			padding:0 15px;
			width:49%;
		}

		@include media-breakpoint-up(lg) { 
			margin-bottom:0;
			width:24%;
		}

		//&:last-child { margin-bottom:0; }

		figure {
			margin-bottom:0;
		}
	}


	&__img {
		display:block;
		height:42px;
		margin:0 auto 5px;
		width:auto;

		@include media-breakpoint-up(md) { 
			height:50px;
		}

		@include media-breakpoint-up(lg) { 
			height:74px;
			margin-bottom:20px;
		}
	}

	&__title {
		@include vw-font-size(17px);
		@include vw-line-height(27px);
		color:$purple;
		font-family:$font-quicksand;

		@include media-breakpoint-up(lg) { 
			@include vw-font-size(20px);
			@include vw-line-height(30px);
		}
	}

	&__text {
		@include vw-font-size(14px);
		@include vw-line-height(22px);
		color:$color-base;
		margin-bottom:0;
		max-width:340px;
		padding:0 5px;

		@include media-breakpoint-up(md) { 
			@include vw-font-size(15px);
		}
		
		@include media-breakpoint-up(lg) { 
			@include vw-font-size(16px);
			@include vw-line-height(25px);
			margin-top:5px;
			max-width:100%;
		}
	}
}