.renting-booking {
	margin:0 auto;
	max-width:1170px;

	@include media-breakpoint-up(md) { 
		align-items:flex-start;
		display:flex;
		flex-wrap:nowrap;			
	}

	&__column {
		@include media-breakpoint-up(md) { 
			padding-right:15px;
			width:50%;

			&:last-child {
				padding-left:15px;
				padding-right:0;
			}
		}

		@include media-breakpoint-up(lg) { 
			padding-right:30px;
		}

		&:last-child {
			margin-top:20px;

			@include media-breakpoint-up(lg) { 
				margin-top:0;
				padding-left:30px;
				padding-right:0;
			}
		}
	}

	&__title {
		@include vw-font-size(22px);
		@include vw-line-height(30px);
		color:$black;
		display:block;
		font-family:$font-quicksand;
		font-weight:bold;
		margin:0 auto 15px;
		
		@include media-breakpoint-up(md) { 
			@include vw-font-size(27px);
			@include vw-line-height(34px);
		}
	}

	&__highlight {
		@include vw-font-size(16px);
		@include vw-line-height(24px);
		color:$color-base;
		display:block;
		font-family:$font-open-sans;
		
		@include media-breakpoint-up(md) { 
			@include vw-font-size(18px);
			@include vw-line-height(27px);
		}
	}

	&__step {
		//display:none;

		//&.step-1 { display:block; }

		.step-3 {
			input[type="checkbox"] {
				left:auto;
				position:relative;
				top:auto;
			}
		}
	}

	&__content-form {
		margin-top:25px;

		/*@include media-breakpoint-up(md) { 
			margin-top:45px;
		}*/

		.form-control {
			@include vw-font-size(15px);
		}

		.form-label {
			@include vw-font-size(16px);
			font-weight:bold;
		}		
	}

	.select2 {
		border-radius:6px;
		padding:1px;

		&.select2-container--open {
			
		}

		&.select2-container--disabled {
			background:#eee;

			.select2-selection--single { cursor:auto; }
		}
	}

	.select2-container .select2-selection--single .select2-selection__rendered {
		@include vw-font-size(14px);
		padding-left: 15px;
	    text-align: left;
	}

	.bar-menu {
		@include media-breakpoint-up(md) { 
			width:100%;
		}
	}

	&__info-box {
		background:#fef5d9;
		clear:both;
		margin:20px auto;
		padding:15px;

		&__title {
			@include vw-font-size(16px);
			@include vw-line-height(22px);
			color:#000;
			font-family:$font-quicksand;
		}

		&__details {
			@include vw-font-size(13px);
			@include vw-line-height(21px);

			&:first-child { display:block; }

			ul {
				margin-top:10px;
				li {
					margin-bottom:10px;

					&:last-child { margin-bottom:0; }
				}
			}
		}
	}

	.hide { display:none; }
}