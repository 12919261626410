// ==========================================================================
// Header
// ==========================================================================

// 1.Config

// 2.Base

.header {
    left:0;
    position:fixed;
    top:0;
    width:100%;
    z-index: z('header');

    @include media-breakpoint-up(md) { 
        //width:auto;
        z-index:102;
    }

    &__logo {
        @extend %logo;
        @extend %v-align;
        display:block;
        float:left;
        height:35px;
        position:relative;
        width:100px;
        z-index:102;
    }
  
    > .container-fluid {
        height:$headerHeight;
        position:relative;
        text-align:left;
        z-index:109;
     
        @include media-breakpoint-up(md) { 
            background:none;
            float:left;
            height:$headerHeightSm;
            transition: all 0.5s;
            z-index:102;
            width:100px;
        }

        @include media-breakpoint-up(lg) { 
            width:150px;
        }

        @include media-breakpoint-up(xl) { 
            width:185px;
        }
    }

    .hamburger {
        display:block;
        float:right;
        height: 55px;
        position: relative;
        width: 28px;
        @extend %v-align;

        @include media-breakpoint-up(md) { 
            display:none;
        }

        &:focus {
            outline:none;
        }
    }
}

.header--scroll {
    background:rgba($white, .95);

    @include media-breakpoint-up(md) { 
        background:none;

        .menu__item--submenu-avatar {
            .icon-white { display:none; }
            .icon-default { display:block; }
        }

        //.submenu { margin-top:27px; }
    }

    .header__logo {
        @extend %logo-color;
    }

    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        background-color:$black;
    }
}

.header--opened {
    /*background:$white;

    @include media-breakpoint-up(md) {
        background:none;
    }*/

    .header__logo {
        @extend %logo-color;
    }

    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        background-color:$black;
    }

    > .container-fluid {
        background:$white;

        @include media-breakpoint-up(md) {
            background:none;
        }
    }
}

.header--landing {
    > .container-fluid {
        background:$purple;

        @include media-breakpoint-up(md) {
            height: 80px !important;
            width: 284px;
        }
    }

    .header__logo {
        @extend %logo-white;
        left: 50%;
        transform: translate(-50%,-50%);
        
        @include media-breakpoint-up(md) {
            height: 33px !important;
            width: 184px;        
        }
    }
}